const AllCommunitiesData = [
	{
		id: 1,
		name: "Apatam",
		overview:
			"Nestled within the Offinso North District in the Ashanti Region, the Apatam community emerges as a close-knit settlement, home to an estimated population of approximately 200 inhabitants. Notably, the majority of Apatam’s residents are of northern descent.\nApatam, like many other underserved communities, faces a significant challenge when it comes to healthcare access. This close-knit community lacks any local health facility, and community members are confronted with a daunting 40-minute motorcycle journey to reach the nearest healthcare provider. The primary obstacle to their access is the poor state of the road network, which underscores the critical need for improved healthcare infrastructure in this area.\nMalaria is a pressing concern in Apatam, and the lack of immediate healthcare services makes addressing this and other health issues a formidable challenge. Despite these challenges, the indomitable spirit of the community persists.\nThe livelihood of Apatam's residents revolves around agriculture, with farming as their primary occupation. The community's fields yield various essential crops, including cassava, yam, plantain, cashew, rice, tomatoes, and pepper. These hardworking individuals play a vital role in contributing to the region's agricultural output.",
		lng: -2.23202,
		lat: 4.91899,
		population: 200,
		water: "Standing water",
		electricity: "No",
		education: "None",
		screened: 185,
		occupation: "",
		trained: "",
		facility: "",
		visible: true,
	},
	{
		id: 2,
		name: "Tokwai",
		overview: "",
		lng: -0.84227,
		lat: 5.47514,
		population: 1480,
		water: "",
		electricity: "",
		education: "",
		screened: "",
		occupation: "Farming",
		trained: 4,
		facility: "45 minute drive",
		visible: false,
	},
	{
		id: 3,
		name: "Ahenbronum",
		overview: "",
		lng: -1.616538185014343,
		lat: 6.732616299697398,
		population: 2556,
		water: "",
		electricity: "",
		education: "",
		screened: "",
		occupation: "",
		trained: 4,
		facility: "",
		visible: false,
	},
	{
		id: 4,
		name: "Yawberima",
		overview: "",
		lng: -2.0761690591843447,
		lat: 6.606973355303299,
		population: 755,
		water: "",
		electricity: "",
		education: "",
		screened: "",
		occupation: "",
		trained: 4,
		facility: "30 minute drive",
		visible: false,
	},
	{
		id: 5,
		name: "Kwabeng",
		overview: "",
		lng: -0.590444989796424,
		lat: 6.315800970357893,
		population: 956,
		water: "",
		electricity: "",
		education: "",
		screened: "",
		occupation: "",
		trained: "",
		facility: "",
		visible: false,
	},
	{
		id: 6,
		name: "Akutuase",
		overview: "",
		lng: -1.4491009138550377,
		lat: 5.232952005799421,
		population: 2691,
		water: "",
		electricity: "",
		education: "",
		screened: "",
		occupation: "",
		trained: "",
		facility: "",
		visible: false,
	},
	{
		id: 7,
		name: "Ananekrom",
		overview: "",
		lng: -1.0142465177660083,
		lat: 6.915423835590385,
		population: 785,
		water: "",
		electricity: "",
		education: "",
		screened: "",
		occupation: "",
		trained: "",
		facility: "CHPS",
		visible: false,
	},
	{
		id: 8,
		name: "Nyinapomase",
		overview: "",
		lng: -0.22086778795389167,
		lat: 5.587783152757901,
		population: "",
		water: "",
		electricity: "",
		education: "",
		screened: "",
		occupation: "",
		trained: "",
		facility: "",
		visible: false,
	},
	{
		id: 9,
		name: "Dome Tweneboase",
		overview: "",
		lng: -0.2598145426042117,
		lat: 5.576667206477104,
		population: "",
		water: "",
		electricity: "",
		education: "",
		screened: "",
		occupation: "",
		trained: "",
		facility: "",
		visible: false,
	},
	{
		id: 10,
		name: "Hwidiem",
		overview: "",
		lng: -2.358756090485741,
		lat: 6.937647965531249,
		population: 4806,
		water: "",
		electricity: "",
		education: "",
		screened: "",
		occupation: "",
		trained: "",
		facility: "",
		visible: false,
	},
	{
		id: 11,
		name: "Nkwadum",
		overview: "",
		lng: -0.22740014755387128,
		lat: 5.572550478946731,
		population: "",
		water: "",
		electricity: "",
		education: "",
		screened: "",
		occupation: "",
		trained: "",
		facility: "",
		visible: false,
	},
	{
		id: 12,
		name: "Woraponso",
		overview: "",
		lng: -1.2764042523702979,
		lat: 6.880184459329442,
		population: "",
		water: "",
		electricity: "Yes",
		education: "",
		screened: "",
		occupation: "",
		trained: "",
		facility: "CHPS",
		visible: false,
	},
	{
		id: 13,
		name: "Mobia",
		overview: "",
		lng: -1.2555433201229158,
		lat: 6.893873663144239,
		population: 887,
		water: "Borehole",
		electricity: "No",
		education: "",
		screened: "",
		occupation: "",
		trained: "",
		facility: "No",
		visible: false,
	},
	{
		id: 14,
		name: "Bodomase",
		overview: "",
		lng: -1.2555433201229158,
		lat: 6.893873663144239,
		population: 1860,
		water: "",
		electricity: "",
		education: "",
		screened: "",
		occupation: "",
		trained: "",
		facility: "",
		visible: false,
	},
	{
		id: 15,
		name: "Worawoso",
		overview: "",
		lng: -1.2555433201229158,
		lat: 6.893873663144239,
		population: 683,
		water: "",
		electricity: "",
		education: "",
		screened: "",
		occupation: "",
		trained: "",
		facility: "No",
		visible: false,
	},
	{
		id: 16,
		name: "Ejura",
		overview: "",
		lng: -1.3585939216649694,
		lat: 7.38459641691365,
		population: 136672,
		water: "",
		electricity: "",
		education: "",
		screened: "",
		occupation: "",
		trained: "",
		facility: "",
		visible: false,
	},
	{
		id: 17,
		name: "Besoro",
		overview: "",
		lng: -1.241444,
		lat: 6.900917,
		population: "",
		water: "",
		electricity: "",
		education: "",
		screened: "",
		occupation: "",
		trained: "",
		facility: "",
		visible: false,
	},
	{
		id: 18,
		name: "Srentiatia",
		overview: "",
		lng: -2.060083,
		lat: 7.429806,
		population: "",
		water: "",
		electricity: "",
		education: "",
		screened: "",
		occupation: "",
		trained: "",
		facility: "",
		visible: false,
	},
	{
		id: 19,
		name: "Agbogbloshie",
		overview: "",
		lng: -0.215556,
		lat: 5.550556,
		population: "",
		water: "",
		electricity: "",
		education: "",
		screened: "",
		occupation: "",
		trained: "",
		facility: "",
		visible: false,
	},
	{
		id: 20,
		name: "Akuse",
		overview: "",
		lng: 0.122778,
		lat: 6.096944,
		population: "",
		water: "",
		electricity: "",
		education: "",
		screened: "",
		occupation: "",
		trained: "",
		facility: "",
		visible: false,
	},
	{
		id: 21,
		name: "Manya Krobo (Odumase Krobo)",
		overview: "",
		lng: 0.013389,
		lat: 6.148361,
		population: "",
		water: "",
		electricity: "",
		education: "",
		screened: "",
		occupation: "",
		trained: "",
		facility: "",
		visible: false,
	},
	{
		id: 22,
		name: "Buoyem",
		overview: "",
		lng: -1.961111,
		lat: 7.666111,
		population: "",
		water: "",
		electricity: "",
		education: "",
		screened: "",
		occupation: "",
		trained: "",
		facility: "",
		visible: false,
	},
	{
		id: 23,
		name: "Oyoko",
		overview: "",
		lng: -1.571722,
		lat: 6.551889,
		population: "",
		water: "",
		electricity: "",
		education: "",
		screened: "",
		occupation: "",
		trained: "",
		facility: "",
		visible: false,
	},
	{
		id: 24,
		name: "Bodwease",
		overview: "",
		lng: -1.3375,
		lat: 6.920111,
		population: "",
		water: "",
		electricity: "",
		education: "",
		screened: "",
		occupation: "",
		trained: "",
		facility: "",
		visible: false,
	},
	{
		id: 25,
		name: "Akrochyire",
		overview: "",
		lng: -1.338944,
		lat: 6.931722,
		population: "",
		water: "",
		electricity: "",
		education: "",
		screened: "",
		occupation: "",
		trained: "",
		facility: "",
		visible: false,
	},
	{
		id: 26,
		name: "Agona",
		overview: "",
		lng: -1.486972,
		lat: 6.934611,
		population: "",
		water: "",
		electricity: "",
		education: "",
		screened: "",
		occupation: "",
		trained: "",
		facility: "",
		visible: false,
	},
	{
		id: 27,
		name: "Sekyere Afram Plains",
		overview: "",
		lng: -0.950306,
		lat: 7.126528,
		population: "",
		water: "",
		electricity: "",
		education: "",
		screened: "",
		occupation: "",
		trained: "",
		facility: "",
		visible: false,
	},
	{
		id: 28,
		name: "Wenamda",
		overview: "",
		lng: -1.022222,
		lat: 7.00275,
		population: "",
		water: "",
		electricity: "",
		education: "",
		screened: "",
		occupation: "",
		trained: "",
		facility: "",
		visible: false,
	},
	{
		id: 29,
		name: "Menam",
		overview: "",
		lng: -1.23925,
		lat: 6.77525,
		population: "",
		water: "",
		electricity: "",
		education: "",
		screened: "",
		occupation: "",
		trained: "",
		facility: "",
		visible: false,
	},
	{
		id: 30,
		name: "Domeabra",
		overview: "",
		lng: -1.137556,
		lat: 6.724056,
		population: "",
		water: "",
		electricity: "",
		education: "",
		screened: "",
		occupation: "",
		trained: "",
		facility: "",
		visible: false,
	},
	{
		id: 31,
		name: "Kyebi",
		overview: "",
		lng: -1.784306,
		lat: 7.106583,
		population: "",
		water: "",
		electricity: "",
		education: "",
		screened: "",
		occupation: "",
		trained: "",
		facility: "",
		visible: false,
	},
	{
		id: 32,
		name: "Drobonso",
		overview: "",
		lng: -1.124139,
		lat: 7.074889,
		population: "",
		water: "",
		electricity: "",
		education: "",
		screened: "",
		occupation: "",
		trained: "",
		facility: "",
		visible: false,
	},
	{
		id: 33,
		name: "Ningo Prapram",
		overview: "",
		lng: 0.179417,
		lat: 5.754972,
		population: "",
		water: "",
		electricity: "",
		education: "",
		screened: "",
		occupation: "",
		trained: "",
		facility: "",
		visible: false,
	},
	{
		id: 34,
		name: "Brenu - Ampenyi",
		overview: "",
		lng: -1.426667,
		lat: 5.068306,
		population: "",
		water: "",
		electricity: "",
		education: "",
		screened: "",
		occupation: "",
		trained: "",
		facility: "",
		visible: false,
	},
	{
		id: 35,
		name: "Ayigya Market",
		overview: "",
		lng: -1.572583,
		lat: 6.689722,
		population: "",
		water: "",
		electricity: "",
		education: "",
		screened: "",
		occupation: "",
		trained: "",
		facility: "",
		visible: false,
	},
	{
		id: 36,
		name: "Abinchi",
		overview: "",
		lng: -1.615222,
		lat: 6.680639,
		population: "",
		water: "",
		electricity: "",
		education: "",
		screened: "",
		occupation: "",
		trained: "",
		facility: "",
		visible: false,
	},
	{
		id: 37,
		name: "Ejisu",
		overview: "",
		lng: -1.470667,
		lat: 6.722583,
		population: "",
		water: "",
		electricity: "",
		education: "",
		screened: "",
		occupation: "",
		trained: "",
		facility: "",
		visible: false,
	},
	{
		id: 38,
		name: "Asawasi",
		overview: "",
		lng: -1.610222,
		lat: 6.699222,
		population: "",
		water: "",
		electricity: "",
		education: "",
		screened: "",
		occupation: "",
		trained: "",
		facility: "",
		visible: false,
	},
	{
		id: 39,
		name: "Makola community",
		overview: "",
		lng: -0.206944,
		lat: 5.54775,
		population: "",
		water: "",
		electricity: "",
		education: "",
		screened: "",
		occupation: "",
		trained: "",
		facility: "",
		visible: false,
	},
	{
		id: 40,
		name: "Amatena",
		overview: "",
		lng: -1.1385,
		lat: 6.779028,
		population: "",
		water: "",
		electricity: "",
		education: "",
		screened: "",
		occupation: "",
		trained: "",
		facility: "",
		visible: false,
	},
	{
		id: 41,
		name: "Susankyi",
		overview: "",
		lng: -1.584944,
		lat: 6.682944,
		population: "",
		water: "",
		electricity: "",
		education: "",
		screened: "",
		occupation: "",
		trained: "",
		facility: "",
		visible: false,
	},
	{
		id: 42,
		name: "Barekumaa",
		overview: "",
		lng: -1.724028,
		lat: 6.851917,
		population: "",
		water: "",
		electricity: "",
		education: "",
		screened: "",
		occupation: "",
		trained: "",
		facility: "",
		visible: false,
	},
	{
		id: 43,
		name: "Ablekuma (Kehillah Center and Public Library)",
		overview: "",
		lng: -0.294556,
		lat: 5.625639,
		population: "",
		water: "",
		electricity: "",
		education: "",
		screened: "",
		occupation: "",
		trained: "",
		facility: "",
		visible: false,
	},
	{
		id: 44,
		name: "Top-High (Joy Fm, Kumasi)",
		overview: "",
		lng: -1.578722,
		lat: 6.687694,
		population: "",
		water: "",
		electricity: "",
		education: "",
		screened: "",
		occupation: "",
		trained: "",
		facility: "",
		visible: false,
	},
	{
		id: 45,
		name: "Adum (RMSC of Forestry Commission)",
		overview: "",
		lng: -1.624361,
		lat: 6.688972,
		population: "",
		water: "",
		electricity: "",
		education: "",
		screened: "",
		occupation: "",
		trained: "",
		facility: "",
		visible: false,
	},
	{
		id: 46,
		name: "Adum (Opoku Trading)",
		overview: "",
		lng: -1.624194,
		lat: 6.690139,
		population: "",
		water: "",
		electricity: "",
		education: "",
		screened: "",
		occupation: "",
		trained: "",
		facility: "",
		visible: false,
	},
	{
		id: 47,
		name: "PIWC Suame",
		overview: "",
		lng: -1.635,
		lat: 6.720972,
		population: "",
		water: "",
		electricity: "",
		education: "",
		screened: "",
		occupation: "",
		trained: "",
		facility: "",
		visible: false,
	},
	{
		id: 48,
		name: "Edwenase Kwadaso (Charismatic Miracle Chapel)",
		overview: "",
		lng: -1.654167,
		lat: 6.677361,
		population: "",
		water: "",
		electricity: "",
		education: "",
		screened: "",
		occupation: "",
		trained: "",
		facility: "",
		visible: false,
	},
	{
		id: 49,
		name: "Kumasi Maakro School",
		overview: "",
		lng: -1.63525,
		lat: 6.73025,
		population: "",
		water: "",
		electricity: "",
		education: "",
		screened: "",
		occupation: "",
		trained: "",
		facility: "",
		visible: false,
	},
	{
		id: 50,
		name: "Sunyani (Emmanuel Assemblies of God Church)",
		overview: "",
		lng: -2.319194,
		lat: 7.35125,
		population: "",
		water: "",
		electricity: "",
		education: "",
		screened: "",
		occupation: "",
		trained: "",
		facility: "",
		visible: false,
	},
	{
		id: 51,
		name: "Ejisu Market",
		overview: "",
		lng: -1.471278,
		lat: 6.7215,
		population: "",
		water: "",
		electricity: "",
		education: "",
		screened: "",
		occupation: "",
		trained: "",
		facility: "",
		visible: false,
	},
	{
		id: 52,
		name: "Ayigya (Bethel Assemblies of God)",
		overview: "",
		lng: -1.571722,
		lat: 6.688444,
		population: "",
		water: "",
		electricity: "",
		education: "",
		screened: "",
		occupation: "",
		trained: "",
		facility: "",
		visible: false,
	},
	{
		id: 53,
		name: "St. Hubert Seminary SHS",
		overview: "",
		lng: -1.649694,
		lat: 6.660694,
		population: "",
		water: "",
		electricity: "",
		education: "",
		screened: "",
		occupation: "",
		trained: "",
		facility: "",
		visible: false,
	},
	{
		id: 54,
		name: "Kumasi Anglican SHS",
		overview: "",
		lng: -1.608722,
		lat: 6.692833,
		population: "",
		water: "",
		electricity: "",
		education: "",
		screened: "",
		occupation: "",
		trained: "",
		facility: "",
		visible: false,
	},
	{
		id: 55,
		name: "KumasI Wesley Girls High School",
		overview: "",
		lng: -1.619667,
		lat: 6.717444,
		population: "",
		water: "",
		electricity: "",
		education: "",
		screened: "",
		occupation: "",
		trained: "",
		facility: "",
		visible: false,
	},
	{
		id: 56,
		name: "Asanteman Senior High School",
		overview: "",
		lng: -1.628333,
		lat: 6.709167,
		population: "",
		water: "",
		electricity: "",
		education: "",
		screened: "",
		occupation: "",
		trained: "",
		facility: "",
		visible: false,
	},
	{
		id: 57,
		name: "Anhwerekrom",
		overview: "",
		lng: -1.726361,
		lat: 7.012806,
		population: "",
		water: "",
		electricity: "",
		education: "",
		screened: "",
		occupation: "",
		trained: "",
		facility: "",
		visible: false,
	},
	{
		id: 58,
		name: "Denkyemuoso",
		overview: "",
		lng: -1.685806,
		lat: 6.692472,
		population: "",
		water: "",
		electricity: "",
		education: "",
		screened: "",
		occupation: "",
		trained: "",
		facility: "",
		visible: false,
	},
	{
		id: 59,
		name: "Camp",
		overview: "Camp community is located in Offinso District.",
		lng: -1.7846,
		lat: 7.068,
		population: "",
		water: "",
		electricity: "",
		education: "",
		screened: "",
		occupation: "",
		trained: "",
		facility: "",
		visible: false,
	},
	{
		id: 60,
		name: "Atia",
		overview: "Atia community is located in Juaben District.",
		lng: -1.3983,
		lat: 6.7114,
		population: "",
		water: "",
		electricity: "",
		education: "",
		screened: "",
		occupation: "",
		trained: "",
		facility: "",
		visible: false,
	},
	{
		id: 61,
		name: "Mpaepaemu",
		overview: "Mpaepeamu community is located in Afrancho District.",
		lng: -2.0155,
		lat: 7.4207,
		population: "",
		water: "",
		electricity: "",
		education: "",
		screened: "",
		occupation: "",
		trained: "",
		facility: "",
		visible: false,
	},
	{
		id: 62,
		name: "Krufrom",
		overview: "Krufrom community is located in Juaben District.",
		lng: -1.3543,
		lat: 6.7433,
		population: "",
		water: "",
		electricity: "",
		education: "",
		screened: "",
		occupation: "",
		trained: "",
		facility: "",
		visible: false,
	},
	{
		id: 63,
		name: "Apemso",
		overview: "Apemso community is located in Juaben District.",
		lng: -1.4455,
		lat: 6.7787,
		population: "",
		water: "",
		electricity: "",
		education: "",
		screened: "",
		occupation: "",
		trained: "",
		facility: "",
		visible: false,
	},
	{
		id: 64,
		name: "Jato",
		overview: "Jato community is located near Jato CHIPS in the Western North Region.",
		lng: -3.0892,
		lat: 6.2978,
		population: "",
		water: "",
		electricity: "",
		education: "",
		screened: "",
		occupation: "",
		trained: "",
		facility: "",
		visible: false,
	},
	{
		id: 65,
		name: "Antobia (Juaboso)",
		overview: "Antobia or Juaboso community is located in the Western North Region.",
		lng: -2.9241,
		lat: 6.2615,
		population: "",
		water: "",
		electricity: "",
		education: "",
		screened: "",
		occupation: "",
		trained: "",
		facility: "",
		visible: false,
	},
	{
		id: 66,
		name: "Asempanaye",
		overview: "Wesley Methodist Church Sefwi in Asempanaye Western North District.",
		lng: -2.9070,
		lat: 6.5036,
		population: "",
		water: "",
		electricity: "",
		education: "",
		screened: "",
		occupation: "",
		trained: "",
		facility: "",
		visible: false,
	},
	{
		id: 67,
		name: "Bantama Market (Kumasi)",
		overview: "",
		lng: -1.6349,
		lat: 6.7035,
		population: "",
		water: "",
		electricity: "",
		education: "",
		screened: "",
		occupation: "",
		trained: "",
		facility: "",
		visible: false,
	},
	{
		id: 68,
		name: "Buranamoa (Mfantseman Municipality)",
		overview: "",
		lng: -1.144056,
		lat: 5.205194,
		population: "",
		water: "",
		electricity: "",
		education: "",
		screened: "",
		occupation: "",
		trained: "",
		facility: "",
		visible: false,
	},
	{
		id: 69,
		name: "Akraman (Mfantseman Municipality)",
		overview: "",
		lng: -1.143417,
		lat: 5.20725,
		population: "",
		water: "",
		electricity: "",
		education: "",
		screened: "",
		occupation: "",
		trained: "",
		facility: "",
		visible: false,
	},
];

function formatCommunitiesData(data) {
	const formattedCommunitiesData = [];

	for (let community of data) {
		if (community.visible === false) {
			continue;
		}
		formattedCommunitiesData.push({
			id: community.id,
			name: community.name,
			overview: community.overview ? community.overview : "N/A",
			lng: community.lng ? community.lng : "N/A",
			lat: community.lat ? community.lat : "N/A",
			population: community.population ? community.population : "N/A",
			water: community.water ? community.water : "N/A",
			electricity: community.electricity ? community.electricity : "N/A",
			education: community.education ? community.education : "N/A",
			screened: community.screened ? community.screened : "N/A",
			occupation: community.occupation ? community.occupation : "N/A",
			trained: community.trained ? community.trained : "N/A",
			facility: community.facility ? community.facility : "N/A",
		});
	}

	formattedCommunitiesData.sort((a, b) => {
		if (a.name < b.name) {
			return -1;
		}
		if (a.name > b.name) {
			return 1;
		}
		return 0;
	});

	return formattedCommunitiesData;
}

export const CommunitiesData = formatCommunitiesData(AllCommunitiesData);

function getCommunitiesList(data) {

	data.sort((a, b) => {
		if (a.name < b.name) {
			return -1;
		}
		if (a.name > b.name) {
			return 1;
		}
		return 0;
	});

	return data;
}

export const CommunitiesListData = getCommunitiesList(AllCommunitiesData);
export { AllCommunitiesData };
