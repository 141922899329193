// events page main

// imports
import ReactGA from "react-ga";
import CaseCompetitions from "./CaseCompetitions";
import EventsCalendar from "./EventsCalendar";
import "./EventsPage.css";

//main
export default function EventsPage() {
  // Track page view when the component mounts
  ReactGA.pageview(window.location.pathname + window.location.search);

  //return
  return (
    <div className="large-screen">
      <div className="home-header-outer">
        <div className="case-competitions-header">
          <div className="impact-title shrink-long-title">
            Events
          </div>
        </div>
      </div>

      <EventsCalendar />
    </div>
  );
}
