// imports
import { NavLink, useParams, useLocation } from "react-router-dom";
import { useEffect, useRef } from "react";
import arrow from "../../../Assets/Icons/arrow-1.png";
import { NewsData } from "../../../Data/NewsData/newsData";
import linkedinIcon from "../../../Assets/Icons/external-link-1.png";
import "./NewsStory.css";

// main
export default function NewsStory() {
  // find story
  const params = useParams();
  const id = +params.id;
  let newsStory = NewsData.find((el) => el.id === id);
  const tempImage =
    "https://media.istockphoto.com/id/1357365823/vector/default-image-icon-vector-missing-picture-page-for-website-design-or-mobile-app-no-photo.jpg?s=612x612&w=0&k=20&c=PM_optEhHBTZkuJQLlCjLz-v3zzxp-1mpNQZsdjrbns=";

  // point to id
  const location = useLocation();
  const elementRef = useRef(null);
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    if (elementRef.current && id) {
      elementRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [location]);

  // format date
  const newDate = new Date(newsStory.date);
  const month = newDate.toLocaleDateString("en-US", { month: "long" });
  const year = newDate.toLocaleDateString("en-US", { year: "numeric" });

  return (
    <>
      <div className="team-header" ref={elementRef} id="m1"></div>

      <NavLink className="what-learn-more cancel-appt-button" to="/news">
        <img src={arrow} alt="go to previous page" className="arrow-icon" />
      </NavLink>

      <h2 className="news-story-title">{newsStory.title}</h2>

      <div className="booking-body-section-three member-info-mobile">
        <div className="donate-person-container">
          <img
            className="news-image"
            src={newsStory.image ? newsStory.image : tempImage}
            alt="OKB News Story"
          />

          {newsStory.link && (
            <div className="news-story-link">
              <div>{`${month} ${year}`}</div>
              <NavLink className="mleft15" to={newsStory.link} target="_blank">
                <img
                  className="external-link-icon"
                  src={linkedinIcon}
                  alt="linkedin"
                />
              </NavLink>
            </div>
          )}
        </div>

        <div className="member-desc-container">
          {newsStory.details.map((el, i) => (
            <div className="member-desc" key={i}>
              {el}
            </div>
          ))}

          <div className="learn-more-container">
            <a
              className="learn-more-button"
              href={newsStory.link}
              target="_blank"
              rel="noreferrer"
            >
              READ MORE
            </a>
          </div>
        </div>
      </div>
    </>
  );
}
