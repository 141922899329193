import { useParams, Navigate } from 'react-router-dom';
import { CommunitiesData } from "../../../Data/OurImpactData/CommunitiesData";
import Map from "../../Map/map";
import impactHeader from "../../../Assets/OurImpact/impact-1.png";
import "./community.css";

const Community = () => {
    const { communityId } = useParams();
    const community = CommunitiesData.find(comm => comm.id === Number(communityId));

    if (!community) {
      // return <div className="community-not-found" >Community not found!</div>;

      // navigate to 404 page > to="/not-found"
      return <Navigate to="/not-found" replace />;
    }
    // console.log("community: ", community);
    const center = community ? { lat: parseFloat(community.lat), lng: parseFloat(community.lng) } : undefined;

    const imageStyle = {
        width: '100%',
        height: '400px',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        // backgroundImage: community.image
        backgroundImage: `url(${impactHeader})`
    }

    return (
        <div className='large-screen'>
            <div className='community-image' style={imageStyle}>
                <div className='community-name'>
                    {community.name}
                </div>
            </div>
            <div id="not-full" className='community-overview'>
                <div className='overview-header'>
                    Overview
                </div>
                <div className='overview-content'>
                    {community.overview}
                </div>
            </div>
            <div className='community-details-wrapper'>
                <div className='community-details'>
                    <div className='detail'>
                        <div className='pop-title'>
                            Population:
                        </div>
                        <div className='pop'>
                            {community.population}
                        </div>
                    </div>
                    <div className='detail'>
                        <div className='water-title'>
                            Water:
                        </div>
                        <div className='water'>
                            {community.water}
                        </div>
                    </div>
                    <div className='detail'>
                        <div className='electricity-title'>
                            Electricity:
                        </div>
                        <div className='electricity'>
                            {community.electricity}
                        </div>
                    </div>
                    <div className='detail'>
                        <div className='education-title'>
                            Education:
                        </div>
                        <div className='education'>
                            {community.education}
                        </div>
                    </div>
                    <div className='detail'>
                        <div className='occupation-title'>
                            Occupation:
                        </div>
                        <div className='occupation'>
                            {community.occupation}
                        </div>
                    </div>
                    <div className='detail'>
                        <div className='facility-title'>
                            Nearest Health Facility:
                        </div>
                        <div className='facility'>
                            {community.facility}
                        </div>
                    </div>
                    <div className='detail'>
                        <div className='trained-title'>
                            Members Trained:
                        </div>
                        <div className='facility'>
                            {community.trained}
                        </div>
                    </div>
                    <div className='detail'>
                        <div className='patient-title'>
                            Patients Screened:
                        </div>
                        <div className='patient'>
                            {community.screened}
                        </div>
                    </div>
                </div>
            </div>
            <div className='community-map'>
                <Map center={center} zoom={13} spot={community} />
            </div>
        </div>
    )
}

export default Community;
