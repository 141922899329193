/**
 * @typedef VolunteersData
 * @type {Array<Object>}
 * @memberOf WorkWithUsData
 * @namespace VolunteersData
 * @description Data for Volunteer Programs
 * @property id
 * @property title
 * @property text
 * @property location1
 * @property location2
 * @property applyLink - app.js link
 * @property src - flipcause
 * @property roleOverview - Array<string>
 * @property keyResponsibilities - Object with key = int or string, value = Array<string>
 * @property qualifications - Array<string>
 * @property finalNote - Array<string>
 */
export const VolunteersData = [
  {
    id: 1,
    title: "Social Media Volunteer",
    text: "Assist with the design and execution of social media campaigns.",
    location1: "Somerset, NJ",
    location2: "Remote only",
    applyLink: "/apply-volunteer",
    src: "MTE3Njc5",
    roleOverview: [
      "We are seeking a motivated and creative Social Media Volunteer to assist with our organization's social media presence. This role will play a key part in helping to promote our mission, engage our audience, and increase our online visibility across various social media platforms.",
    ],
    keyResponsibilities: {
      1: [
        "Develop and implement social media strategies to increase brand awareness, engagement, and follower growth.",
      ],
      2: [
        "Create and curate engaging content for our organization's social media channels, including Facebook, Twitter, Instagram, LinkedIn, and others.",
      ],
      3: [
        "Monitor and respond to comments, messages, and mentions on social media platforms in a timely and professional manner.",
      ],
      4: [
        "Schedule and publish social media posts using scheduling tools such as Hootsuite or Buffer.",
      ],
      5: [
        "Collaborate with the marketing team to coordinate social media campaigns and promotions.",
      ],
      6: [
        "Track and analyze social media performance metrics, including reach, engagement, and conversion rates, and provide regular reports to the team.",
      ],
      7: [
        "Stay up-to-date with social media trends, best practices, and platform updates to ensure our organization remains current and relevant.",
      ],
      8: [
        "Assist in the creation of social media graphics, videos, and other visual content as needed.",
      ],
      9: [
        "Engage with influencers, partners, and other relevant stakeholders to expand our organization's reach and impact.",
      ],
      10: [
        "Support other digital marketing initiatives as needed, such as email marketing or content creation for the organization's website.",
      ],
    },
    qualifications: [
      "Previous experience in social media management or marketing is preferred but not required.",
      "Strong understanding of major social media platforms and their best practices.",
      "Excellent written and verbal communication skills.",
      "Creative thinking and a passion for storytelling.",
      "Ability to work independently and as part of a team.",
      "Strong organizational skills and attention to detail.",
      "Familiarity with social media analytics tools such as Facebook Insights, Twitter Analytics, or LinkedIn Analytics is a plus.",
    ],
    finalNote: [
      "This is a volunteer position with flexible hours and the opportunity to gain valuable experience in social media marketing while contributing to our organization's mission.",
    ],
  },
  {
    id: 2,
    title: "Marketing Volunteer",
    text: "Support marketing campaign planning and execution.",
    location1: "Somerset, NJ",
    location2: "Remote only",
    applyLink: "/apply-volunteer",
    src: "MTE3Njc5",
    roleOverview: [
      "We are seeking a dedicated and enthusiastic Marketing Volunteer to support our organization's marketing efforts. This role will play a crucial part in helping to promote our mission, increase brand awareness, and engage our target audience through various marketing channels.",
    ],
    keyResponsibilities: {
      1: [
        "Assist in developing and implementing marketing strategies to promote our organization's mission, programs, and events.",
      ],
      2: [
        "Create and curate content for marketing materials, including brochures, flyers, posters, newsletters, and digital assets.",
      ],
      3: [
        "Help manage and update content on our organization's website and social media channels.",
      ],
      4: [
        "Support the planning and execution of marketing campaigns and initiatives, including email campaigns, social media promotions, and advertising efforts.",
      ],
      5: [
        "Conduct market research and analysis to identify target audiences, industry trends, and competitive insights.",
      ],
      6: [
        "Collaborate with the marketing team to brainstorm and generate creative ideas for marketing campaigns and projects.",
      ],
      7: [
        "Assist in organizing and coordinating events, workshops, and other promotional activities.",
      ],
      8: [
        "Monitor and track marketing performance metrics, including website traffic, social media engagement, and campaign effectiveness.",
      ],
      9: [
        "Provide administrative support to the marketing team, such as organizing files, maintaining databases, and managing correspondence.",
      ],
      10: [
        "Stay up-to-date with marketing trends, best practices, and industry developments to ensure our organization remains competitive and relevant.",
      ],
    },
    qualifications: [
      "Previous experience in marketing, communications, or related fields is preferred but not required.",
      "Strong written and verbal communication skills.",
      "Creative thinking and a passion for marketing and storytelling.",
      "Proficiency in Microsoft Office Suite (Word, Excel, PowerPoint) and Google Suite.",
      "Familiarity with social media platforms and digital marketing tools is a plus.",
      "Ability to work independently and collaboratively in a fast-paced environment.",
      "Strong organizational skills and attention to detail.",
      "Willingness to learn and adapt to new technologies and strategies.",
    ],
    finalNote: [
      "This is a volunteer position with flexible hours and the opportunity to gain valuable experience in marketing while making a positive impact on our organization's mission.",
    ],
  },
  {
    id: 3,
    title: "Grant Manager Volunteer",
    text: "Support our organization's fundraising efforts by managing the grant application process.",
    location1: "Somerset, NJ",
    location2: "Remote only",
    src: "MTE3Njc5",
    roleOverview: [
      "As a Grant Manager Volunteer, you will play a crucial role in supporting our organization's fundraising efforts by managing the grant application process. Your responsibilities will involve researching, applying for, and managing grants to secure funding for our programs and initiatives.",
    ],
    keyResponsibilities: {
      "Grant Research and Identification": [
        "Conduct thorough research to identify potential grant opportunities that align with the organization's mission and objectives.",
        "Keep abreast of grant trends, guidelines, and deadlines.",
      ],
      "Grant Proposal Development": [
        "Collaborate with program managers and other stakeholders to gather information for grant proposals.",
        "Draft clear, compelling, and well-organized grant proposals tailored to the requirements of each funding opportunity.",
      ],
      "Grant Application Submission": [
        "Ensure timely submission of grant applications, adhering to all specified guidelines and deadlines.",
        "Maintain accurate records of all submitted applications and correspondence.",
      ],
      "Grant Reporting and Compliance": [
        "Monitor and ensure compliance with grant agreements, reporting requirements, and funding restrictions.",
        "Prepare and submit timely and accurate grant reports, tracking outcomes and impact.",
      ],
      "Communication and Relationship Building": [
        "Establish and maintain positive relationships with funding organizations and partners.",
        "Respond promptly to any communication from funders and provide requested information.",
      ],
      "Collaboration and Coordination": [
        "Collaborate with other team members to integrate grant activities with overall fundraising strategies.",
        "Work closely with program managers to align grant proposals with organizational objectives.",
      ],
      "Documentation and Record Keeping": [
        "Maintain an organized and up-to-date database of grant opportunities, submissions, and outcomes.",
        "Ensure all documentation related to grants is accurately filed and easily accessible.",
      ],
      "Continuous Learning": [
        "Stay informed about best practices in grant management and fundraising.",
        "Seek professional development opportunities to enhance grant writing and management skills.",
      ],
    },
    qualifications: [
      "Strong written and verbal communication skills.",
      "Detail-oriented with excellent organizational and time management abilities.",
      "Ability to work independently and collaboratively within a team.",
      "Proficient in research, analysis, and interpretation of grant requirements.",
      "Familiarity with grant management software/tools is a plus.",
    ],
    finalNote: [
      "This role is vital to the success of our organization, and we appreciate your commitment to making a positive impact through effective grant management. Thank you for joining our team!",
    ],
  },
  {
    id: 4,
    title: "Partnership Manager Volunteer",
    text: "Fostering collaborations and managing relationships with external stakeholders",
    location1: "Somerset, NJ",
    location2: "Remote only",
    src: "MTE3Njc5",
    roleOverview: [
      "As a Partnership Manager Volunteer, your role is pivotal in fostering collaborations and managing relationships with external stakeholders. Your primary focus will be to identify, cultivate, and maintain partnerships that align with our organization's mission and enhance our impact.",
    ],
    keyResponsibilities: {
      "Partnership Development": [
        "Identify potential partners that align with the organization's goals and values.",
        "Develop and implement strategies to cultivate and nurture relationships with key stakeholders.",
      ],
      "Negotiation and Agreement": [
        "Collaborate with potential partners to negotiate terms, agreements, and collaborations.",
        "Ensure all partnerships are mutually beneficial and aligned with organizational objectives.",
      ],
      "Relationship Management": [
        "Serve as the primary point of contact for existing partners.",
        "Regularly communicate with partners to assess satisfaction, address concerns, and explore opportunities for collaboration.",
      ],
      Networking: [
        "Attend relevant events, conferences, and meetings to expand the organization's network.",
        "Represent the organization professionally and build rapport with potential partners.",
      ],
      "Collaborative Initiatives": [
        "Work closely with internal teams to identify opportunities for collaborative projects with partners.",
        "Facilitate the planning and execution of joint initiatives.",
      ],
      "Communication and Promotion": [
        "Promote the organization's mission and activities to potential partners.",
        "Develop and maintain marketing materials and presentations for partnership outreach.",
      ],
      "Data Management": [
        "Maintain an organized database of partner contacts, agreements, and communication records.",
        "Provide regular reports on partnership activities and outcomes.",
      ],
      "Problem Resolution:": [
        "Address and resolve any issues or conflicts that may arise in partnerships.",
        "Ensure that partners are well-informed about organizational developments.",
      ],
    },
    qualifications: [
      "Excellent interpersonal and relationship-building skills.",
      "Strong communication and negotiation abilities.",
      "Ability to work independently and collaboratively within a team.",
      "Experience in partnership development or sales is beneficial.",
      "Understanding of the organization's mission and values.",
    ],
    finalNote: [
      "Your dedication to cultivating meaningful partnerships is instrumental in advancing our organization's impact. We appreciate your commitment to fostering connections that contribute to our shared goals. Thank you for joining our team!",
    ],
  },
  {
    id: 5,
    title: "Digital Marketing Volunteer",
    text: "This position will play a crucial role in supporting our organization's digital marketing efforts",
    location1: "Somerset, NJ",
    location2: "Remote only",
    src: "MTE3Njc5",
    roleOverview: [
      "We are seeking a dedicated and skilled Digital Marketing Volunteer to join our team. This position will play a crucial role in supporting our organization's digital marketing efforts, with a focus on utilizing tools such as Google Analytics, Google Ads, and other digital marketing materials to enhance our online presence and reach our target audience effectively.",
    ],
    keyResponsibilities: {
      1: [
        "Develop and implement digital marketing strategies to increase brand awareness, engagement, and conversions.",
      ],
      2: [
        "Utilize Google Analytics to analyze website traffic, user behavior, and other key metrics to optimize digital marketing campaigns.",
      ],
      3: [
        "Ensure all partnerships are mutually beneficial and aligned with organizational objectives.",
      ],
      4: [
        "Create and manage Google Ads campaigns, including keyword research, ad copywriting, bid management, and performance monitoring.",
      ],
      5: [
        "Collaborate with the marketing team to develop and execute integrated marketing campaigns across various digital channels.",
      ],
      6: [
        "Monitor and analyze campaign performance, providing insights and recommendations for improvement.",
      ],
      7: [
        "Create and optimize digital marketing materials, including email campaigns, social media ads, and display banners.",
      ],
      8: [
        "Stay up-to-date with industry trends and best practices in digital marketing, particularly in areas related to Google Analytics and Google Ads.",
      ],
      9: [
        "Assist in the creation of reports and presentations to communicate campaign results and recommendations to stakeholders.",
      ],
    },
    finalNote: [
      "Your dedication to cultivating meaningful partnerships is instrumental in advancing our organization's impact. We appreciate your commitment to fostering connections that contribute to our shared goals. Thank you for joining our team!",
    ],
  },
];
