//imports
import { useParams } from "react-router-dom";
import EventsData from "../../../Data/EventsData/EventsData";
import EventImages from "../../../common/EventImages";
import "../EventsPage.css";
import { event } from "react-ga";

//main
export default function HealthOutreachEvent() {
	// find event data
	const { eventId } = useParams();
	let eventData;
	if (EventsData.upcoming) {
		eventData = EventsData.upcoming.find((event) => event.id === eventId);
	}
	if (!eventData && EventsData.past) {
		eventData = EventsData.past.find((event) => event.id === eventId);
	}

	// destructure event data
	const {
		id,
		title,
		details,
		description,
		location,
		dates,
		applyLink,
		componentLink,
		videoLink,
		eventStartDate,
		eventEndDate,
		eventStartTime,
		eventEndTime,
		images,
	} = eventData;

	const fullTitle = title + " " + details;

	// format date
	const formatDate = (eventStartDate, eventStartTime) => {
		let date = new Date(eventStartDate).toString().split(" ");
		let weekday = date[0];
		let month = date[1];
		let day = date[2];
		let year = date[3];
		let time = eventStartTime.split(":").splice(0, 2).join(":");
		let ampm = time.slice(time.length - 2);
		time = time.slice(0, time.length - 2);
		// format time
		if (time.length < 3) {
			if (ampm === "pm") {
				time += ":00 PM GMT";
			} else {
				time += ":00 AM GMT";
			}
		} else if (ampm === "pm") {
			time += " PM GMT";
		} else {
			time += " AM GMT";
		}

		return { weekday, month, day, year, time };
	};
	const currentDate = new Date();
	const eventStartFormatted = formatDate(eventStartDate, eventStartTime);
	const eventEndFormatted = formatDate(eventEndDate, eventEndTime);

	//return
	return (
		<div className="large-screen">
			<div className="home-header-outer">
				<div className="case-competitions-header">
					<div className="impact-title shrink-long-title">
						{title}
					</div>
				</div>
			</div>

			<div className="our-impact-body">
				<div className="what-body-section" id="case-comp-intro">
					<div
						className={`what-section-left margins-auto`}
						id={`${!videoLink && "event-section-full"}`}
					>
						<h2 className="what-section-left-header">
							{fullTitle}
						</h2>

						<div
							className={`what-section-text`}
							id={`${!videoLink && "event-section-full"}`}
						>
							{description &&
								description.length > 0 &&
								description.map((message, index) => (
									<div
										className="see-how-we-do event-text"
										key={index}
									>
										{message}
									</div>
								))}
						</div>
					</div>

					{videoLink && (
						<div
							className="what-section-right"
							id="case-comp-section-right"
						>
							<iframe
								className="event-video"
								id="case-comp-vid"
								src={videoLink}
								title="YouTube video player"
								allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
								allowFullScreen
							></iframe>
						</div>
					)}
				</div>

				{new Date(eventStartDate) > currentDate && (
					<>
						<div className="event-info-header-container">
							<div className="health-outreach-images-header">
								General Info
							</div>
						</div>

						<div className="margin-bottom-event-info">
							<div className="event-info-text">
								<div className="event-text-left">
									Event Start
								</div>
								<div className="event-text-right">
									<div>{`${eventStartFormatted.weekday}, ${eventStartFormatted.month} ${eventStartFormatted.day}, ${eventStartFormatted.year}`}</div>
									<div>{`${eventStartFormatted.time}`}</div>
								</div>
							</div>
							<div className="event-info-text">
								<div className="event-text-left">Event End</div>
								<div className="event-text-right">
									<div>{`${eventEndFormatted.weekday}, ${eventEndFormatted.month} ${eventEndFormatted.day}, ${eventEndFormatted.year}`}</div>
									<div>{`${eventEndFormatted.time}`}</div>
								</div>
							</div>
						</div>
					</>
				)}

				{images && images.length > 0 && (
					<>
						<div className="event-info-header-container">
							<div className="health-outreach-images-header">{`Gallery (${
								images && images.length > 0 ? images.length : 0
							})`}</div>
						</div>

						<div className="event-image-carousel">
							<EventImages images={images} title={title} />
						</div>
					</>
				)}

				<div className="break" />
			</div>
		</div>
	);
}
