// imports
import "./Booking.css";
import { useState } from "react";
import LabServices from "./LabServices/LabServices";
import { NavLink } from "react-router-dom";
import ReactGA from 'react-ga';

// main
export default function Booking() {
	 // Track page view when the component mounts
	 ReactGA.pageview(window.location.pathname + window.location.search);

	// states
	const [showLabServices, setShowLabServices] = useState(false);
	const [open1, setOpen1] = useState(false);
	const [open2, setOpen2] = useState(false);
	const [open3, setOpen3] = useState(false);
	const [open4, setOpen4] = useState(false);
	const [open5, setOpen5] = useState(false);
	const [open6, setOpen6] = useState(false);
	const [open7, setOpen7] = useState(false);

	// clear state
	function clearAll() {
		if (open1) {
			setOpen1(false);
		}
		if (open2) {
			setOpen2(false);
		}
		if (open3) {
			setOpen3(false);
		}
		if (open4) {
			setOpen4(false);
		}
		if (open5) {
			setOpen5(false);
		}
		if (open6) {
			setOpen6(false);
		}
		if (open7) {
			setOpen7(false);
		}
	}

	// return
	return (
		<div className="large-screen">
			<div className="home-header-outer">
				<div className="booking-header">
					<div className="who-title">Book a visit</div>
				</div>
			</div>

			{showLabServices && <LabServices />}

			{!showLabServices && (
				<>
					<div className="booking-body-section section-one mobile-bottom-padding">
						<div className="booking-section-left">
							<h2 className="booking-patients-header">
								Lab Services
							</h2>
							<div className="booking-section-text">
								Every day, we're helping healthcare providers
								treat millions of patients as individuals. Our
								comprehensive test menu provides routine and
								specialty diagnostic testing and screening for
								multiple diseases and conditions. Whether you're
								looking for tests to detect and monitor chronic
								conditions like diabetes or chronic kidney
								disease, or you are seeking screening options
								for disease like liver disease, we can help you
								and your patients get answers.
							</div>
							<NavLink
								className="white-button"
								to="/lab-services"
							>
								<div className="button what-learn-more">
									BOOK LAB SERVICES
								</div>
							</NavLink>
						</div>

						<div className="booking-section-right">
							<h2 className="booking-patients-header">
								Telehealth
							</h2>
							<div className="booking-section-text">
								Our virtual care platform is a convenient,
								secure way to chat with our healthcare providers
								using your computer, laptop, tablet, or
								smartphone. No more worrying about how you will
								get to the hospital when you're sick or when you
								can't leave work. Just connect with your
								desktop, laptop, tablet, smartphone, and our
								certified healthcare professionals will be
								available to provide you with quality care. Let
								us help you navigate the new normal of virtual
								healthcare. We provide general consultation,
								chronic disease management, and second opinion.
							</div>
							<NavLink className="white-button" to="/telehealth">
								<div className="button what-learn-more">
									BOOK TELEHEALTH
								</div>
							</NavLink>
						</div>
					</div>

					<div className="booking-body-section section-one">
						<div className="booking-section-left">
							<h2 className="booking-patients-header">
								Mobile Van
							</h2>
							<div className="booking-section-text">
								Every day, we are dedicated to supporting
								healthcare providers in treating millions of
								patients with personalized care. Our extensive
								range of diagnostic testing and screening
								options offers a comprehensive test menu,
								covering both routine and specialty tests for
								various diseases and conditions. Whether it's
								monitoring chronic illnesses such as diabetes or
								chronic kidney disease or conducting screenings
								for conditions like liver disease, we provide
								the necessary solutions to help you and your
								patients obtain accurate and timely answers.
							</div>
							<NavLink className="white-button" to="/mobile-van">
								<div className="button what-learn-more">
									BOOK A MOBILE VAN
								</div>
							</NavLink>
						</div>

						<div className="booking-section-right">
							<h2 className="booking-patients-header">
								Health Check for Groups
							</h2>
							<div className="booking-section-text">
								Whether you are a corporate body, faith-based
								organization, or a group of people seeking
								healthcare, our Hope Health Van is here to
								provide you with quality and affordable
								healthcare right at your doorsteps. Our Health
								Vans are equipped with multiple point-of-care
								lab diagnostics, healthcare providers, and a
								vast array of medicines for patient care. We
								provide routine checkups and comprehensive lab
								services.
							</div>
							<NavLink
								className="white-button"
								to="/group-health-check"
							>
								<div className="button what-learn-more">
									BOOK FOR GROUPS
								</div>
							</NavLink>
						</div>
					</div>
				</>
			)}

			<div className="volunteer-faqs">
				<h2 className="work-volunteer-title">Booking FAQs</h2>

				<div
					className="openingfaqs mbottom10 pointer opening-container"
					onClick={() => {
						clearAll();
						setOpen1(!open1);
					}}
				>
					<div>Can I walk in to receive services?</div>
					{open1 ? (
						<div className="minus-icon">-</div>
					) : (
						<div className="plus-icon">+</div>
					)}
				</div>

				{open1 && (
					<div
						className={`openingfaqs mbottom10 expanded2 ${
							open1 ? "open" : "closed"
						}`}
					>
						Yes. However, we encourage you to either book an
						appointment online or call us to book for you. We don’t
						want you to wait.
					</div>
				)}

				<div
					onClick={() => {
						clearAll();
						setOpen2(!open2);
					}}
					className="openingfaqs mbottom10 pointer opening-container"
				>
					<div>Where is your office located?</div>
					{open2 ? (
						<div className="minus-icon">-</div>
					) : (
						<div className="plus-icon">+</div>
					)}
				</div>

				{open2 && (
					<div
						className={`openingfaqs mbottom10 expanded2 ${
							open2 ? "open" : "closed"
						}`}
					>
						Our office is located at Tech Junction. Behind Juliponia
						Pharmacy.
					</div>
				)}

				<div
					onClick={() => {
						clearAll();
						setOpen3(!open3);
					}}
					className="openingfaqs mbottom10 pointer opening-container"
				>
					<div>Do you accept health insurance?</div>
					{open3 ? (
						<div className="minus-icon">-</div>
					) : (
						<div className="plus-icon">+</div>
					)}
				</div>

				{open3 && (
					<div
						className={`openingfaqs mbottom10 expanded2 ${
							open3 ? "open" : "closed"
						}`}
					>
						Currently, we only accept cash and mobile money.
						However, we are working tirelessly to be able to accept
						other forms of insurance. We will let you know when are
						able to accept insurance.
					</div>
				)}

				<div
					onClick={() => {
						clearAll();
						setOpen4(!open4);
					}}
					className="openingfaqs mbottom10 pointer opening-container"
				>
					<div>Do you provide emergency services?</div>
					{open4 ? (
						<div className="minus-icon">-</div>
					) : (
						<div className="plus-icon">+</div>
					)}
				</div>

				{open4 && (
					<div
						className={`openingfaqs mbottom10 expanded2 ${
							open4 ? "open" : "closed"
						}`}
					>
						No, we only provide primary care and diagnostics –
						that’s what we do best!
					</div>
				)}

				<div
					onClick={() => {
						clearAll();
						setOpen5(!open5);
					}}
					className="openingfaqs mbottom10 pointer opening-container"
				>
					<div>
						What should I expect from my primary care team at OKB
						Hope Health?
					</div>
					{open5 ? (
						<div className="minus-icon">-</div>
					) : (
						<div className="plus-icon">+</div>
					)}
				</div>

				{open5 && (
					<div
						className={`openingfaqs mbottom10 expanded2 ${
							open5 ? "open" : "closed"
						}`}
					>
						We believe primary care should be the first stop in your
						healthcare journey; it’s where you turn for guidance and
						support before you get sick, before you see a
						specialist, and before you need to seek hospital care.
						Our providers combine excellent customer service with an
						efficient use of technology to deliver high-quality,
						affordable service that’s designed to fit your unique
						needs. At OKB Hope Health we take your well-being
						seriously.
					</div>
				)}

				<div
					onClick={() => {
						clearAll();
						setOpen6(!open6);
					}}
					className="openingfaqs mbottom10 pointer opening-container"
				>
					<div>
						How is OKB Hope Health different from other practices?
					</div>
					{open6 ? (
						<div className="minus-icon">-</div>
					) : (
						<div className="plus-icon">+</div>
					)}
				</div>

				{open6 && (
					<div
						className={`openingfaqs mbottom10 expanded2 ${
							open6 ? "open" : "closed"
						}`}
					>
						Our philosophy is rooted in quality care,
						patient-centered design, and a smart application of
						technology. In order to deliver the best preventive
						medicine, acute care, and chronic condition management,
						we provide our certified medical professionals the time
						and tools to make the best decisions for your health.
						Each of our healthcare providers places a strong
						emphasis on fostering patient-provider relationships
						based on trust and mutual understanding, taking the time
						to get to know you and your unique health story. We aim
						to be your long-term health care advocates, partnering
						with you to manage your health issues and navigate
						complex medical decisions. By using diagnostic tests
						appropriately and avoiding overdiagnosis or unnecessary
						prescriptions, we empower you to make positive changes
						in your health and your life with newfound knowledge,
						awareness, support, and guidance. We stay by your side
						every step of the way, making sure you understand how to
						continue your progress once you leave the office. Above
						all else, we treat you as a whole person, not just a
						collection of symptoms. We take all aspects of your
						health and lifestyle into consideration, and care for
						you in a holistic manner that helps you restore and
						maintain optimal health.
					</div>
				)}

				<div
					onClick={() => {
						clearAll();
						setOpen7(!open7);
					}}
					className="openingfaqs mbottom10 pointer opening-container"
				>
					<div>Are there diagnostic services at OKB Hope Health?</div>
					{open7 ? (
						<div className="minus-icon">-</div>
					) : (
						<div className="plus-icon">+</div>
					)}
				</div>

				{open7 && (
					<div
						className={`openingfaqs mbottom10 expanded2 ${
							open7 ? "open" : "closed"
						}`}
					>
						OKB Hope Health offers on-site laboratory services on an
						appointment and walk-in basis. Our medical staff will
						put you at ease, draw your blood and collect other
						specimens in an anxiety-free environment, and get you on
						your way quickly. After we receive and review your
						results, we will promptly call you with our
						interpretation and email you a copy of the report. Our
						health care provider will order the specific tests you
						need based on your age, your medications, your overall
						health status and the amount of time since your last set
						of blood tests.
					</div>
				)}
			</div>
		</div>
	);
}
