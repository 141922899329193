import React, { useEffect, useRef, useState } from 'react';
import { GoogleMap, useJsApiLoader, Marker, InfoWindow } from '@react-google-maps/api';
import { parsePath, useNavigate } from 'react-router-dom';
import { Wrapper } from "@googlemaps/react-wrapper";
import { CommunitiesData } from "../../Data/OurImpactData/CommunitiesData";
import "./Map.css"

const containerStyle = {
    width: '100%',
    height: '100%',
};

// const getCustomMarkerIcon = (price) => {
//     const svg = `<svg viewBox="0 0 150 80" xmlns="http://www.w3.org/2000/svg" width="50" height="30">
//     <defs>
//       <filter id="shadow" x="-20%" y="-20%" width="150%" height="140%">
//         <feDropShadow dx="5" dy="5" stdDeviation="2" flood-color="rgba(0, 0, 0, 0.3)" />
//       </filter>
//     </defs>
//       <rect width="150px" height="80px" rx="38" ry="38" fill="white" stroke="white" />
//       <text font-family="'Circular', -apple-system, 'BlinkMacSystemFont', 'Roboto', 'Helvetica Neue', sans-serif" x="49%" y="65%" font-size="35" font-weight="bold" text-anchor="middle" fill="black">${price}</text>
//     </svg>`;
//     return `data:image/svg+xml,${encodeURIComponent(svg)}`;
// };

const getCustomMarkerIcon = (population) => {
    const svg = `
    <svg viewBox="0 0 30 45" xmlns="http://www.w3.org/2000/svg" width="25" height="26">
        <defs>
            <filter id="shadow" x="-20%" y="-20%" width="150%" height="140%">
                <feDropShadow dx="3" dy="3" stdDeviation="1" flood-color="rgba(0, 0, 0, 0.3)" />
            </filter>
        </defs>
        <path d="M15 0 C 23 0, 30 7, 30 15 C 30 25, 15 45, 15 45 C 15 45, 0 25, 0 15 C 0 7, 7 0, 15 0" fill="#ff6d6d" stroke="white" stroke-width="1"/>
        <text font-family="'Circular', -apple-system, 'BlinkMacSystemFont', 'Roboto', 'Helvetica Neue', sans-serif" x="50%" y="16" font-size="9" font-weight="bold" text-anchor="middle" fill="white"></text>
    </svg>`;
    return `data:image/svg+xml,${encodeURIComponent(svg)}`;
};

const Maps = ({ spots = [], center, zoom, spot = null }) => {
    const navigate = useNavigate();
    const [selectedSpot, setSelectedSpot] = useState(null); // To manage the currently selected spot
    const [infoWindow, setInfoWindow] = useState(null); // To hold the reference to the InfoWindow
    const mapRef = useRef(); // Reference to the map

    // Cleanup InfoWindow on unmount
    useEffect(() => {
        return () => {
            if (infoWindow) {
                infoWindow.close();
            }
        };
    }, [infoWindow]);

    const handleMarkerClick = (spot) => {
      // navigate(`/communities/${spot.id}`);
      navigate(`#`);

        if (infoWindow) {
            infoWindow.close();
        }
        setSelectedSpot(spot); // Set the clicked marker as the selected one
    };

    const handleMarkerMouseOver = (spot, marker) => {
        if (infoWindow) {
            infoWindow.close();
        }
        setSelectedSpot(spot); // Set the hovered marker as the selected one

        const content = spot.visible
        ? `<a href="/communities/${spot.id}" style="text-decoration: none; color: blue;">${spot.name}</a>`
        : `<div>${spot.name}</div>`;

        const newInfoWindow = new window.google.maps.InfoWindow({
            content: content,
            position: { lat: parseFloat(spot.lat), lng: parseFloat(spot.lng) },
        });

        newInfoWindow.open(mapRef.current, marker);
        setInfoWindow(newInfoWindow);
    };

    const handleMarkerMouseOut = () => {
        // if (infoWindow) {
        //     infoWindow.close();
        // }
        // setSelectedSpot(null); // Clear the selected spot when mouse leaves
    };

    const createMarker = (spot) => {
        if (!spot) return null;

        return (
            <Marker
                key={spot.id}
                position={{ lat: parseFloat(spot.lat), lng: parseFloat(spot.lng) }}
                icon={getCustomMarkerIcon(spot.population)}
                onClick={() => handleMarkerClick(spot)}
                onMouseOver={(e) => handleMarkerMouseOver(spot, e.target)}
                onMouseOut={handleMarkerMouseOut}
            />
        );
    };

    const markers = spot ? [createMarker(spot)] : spots.map(createMarker);

    return (
        <Wrapper apiKey={process.env.REACT_APP_MAPS_API_KEY} libraries={["places"]}>
            <GoogleMap
                mapContainerStyle={containerStyle}
                center={center || { lat: 6.5244, lng: -1.5258 }}
                zoom={zoom}
                onLoad={(map) => mapRef.current = map}
            >
                {markers}
            </GoogleMap>
        </Wrapper>
    );
};

export default React.memo(Maps);
