//imports
import { NavLink } from "react-router-dom";
import "./NotFoundPage.css";
// import errorIcon from "../../Assets/Icons/red-warning-1.png";
import errorIcon from "../../../Assets/NotFound/not-found-4.png";

//main
export default function NotFoundPage() {
	//return
	return (
		<div className="large-screen">
			<div className="home-header-outer">
				<div className="not-found-header"></div>
			</div>

			<div className="not-found-container">
				<div className="not-found-body">
					<NavLink className="button" to="/">
						Return to Home
					</NavLink>

					<NavLink
						className="blue-button mleft15"
						to="mailto:info@okbfoundation.org"
					>
						Contact Us
					</NavLink>
				</div>
			</div>
		</div>
	);
}
