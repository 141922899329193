//imports
import { NavLink, useParams } from "react-router-dom";
import EventsData from "../../../Data/EventsData/EventsData";
import "./SummerInternship.css";

//main
export default function SummerInternshipEvent() {
  // find event data
  const { eventId } = useParams();
	let eventData;
	if (EventsData.upcoming) {
		eventData = EventsData.upcoming.find((event) => event.id === eventId);
	};
	if (!eventData && EventsData.past) {
		eventData = EventsData.past.find((event) => event.id === eventId);
	};

  // destructure event data
	const {
		id,
		title,
		details,
		description,
		location,
		dates,
		applyLink,
		videoLink,
	} = eventData;

	const fullTitle = title + " " + details;

	//return
	return (
		<div className="large-screen">
			<div className="home-header-outer">
				<div className="case-competitions-header">
					<div className="impact-title shrink-long-title">
						{title}
					</div>
				</div>
			</div>

			<div className="our-impact-body">
				<div className="what-body-section" id="case-comp-intro">
					<div
						className={`what-section-left margins-auto`}
						id={`${!videoLink && "event-section-full"}`}
					>
						<h2 className="what-section-left-header">
							{fullTitle}
						</h2>

						<div
							className={`what-section-text`}
							id={`${!videoLink && "event-section-full"}`}
						>
							{description &&
								description.length > 0 &&
								description.map((message, index) => (
									<div
										className="see-how-we-do event-text"
										key={index}
									>
										{message}
									</div>
								))}

							<div
								className={`learn-more-container mleft25vw ${
									!videoLink && "text-center"
								}`}
								id={`${!videoLink && "event-section-full"}`}
							>
								<NavLink
									className="learn-more-button"
									to={applyLink}
									target="_blank"
								>
									Apply
								</NavLink>
							</div>
						</div>
					</div>

					{videoLink && (
						<div
							className="what-section-right"
							id="case-comp-section-right"
						>
							<iframe
								className="event-video summer-internship-video"
								id="case-comp-vid"
								src={videoLink}
								title="YouTube video player"
								allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
								allowFullScreen
							></iframe>
						</div>
					)}
				</div>

				<div className="event-info-header-container">
					<div className="event-info-header">General Info</div>
				</div>

				<div className="margin-bottom-prizes">
					<div className="event-info-text">
						<div className="event-text-left">
							Registration Opens
						</div>
						<div className="event-text-right">
							<div>Wednesday, 16th August</div>
							<div>10:00am EST</div>
						</div>
					</div>
					<div className="event-info-text">
						<div className="event-text-left">
							Registration Closes
						</div>
						<div className="event-text-right">
							<div>Wednesday, 30th August</div>
							<div>11:59pm EST</div>
						</div>
					</div>

					<div className="event-info-text">
						<div className="event-text-left">Teams Announced</div>
						<div className="event-text-right">
							<div>Tuesday, 5th September</div>
							<div>10:00am EST</div>
						</div>
					</div>

					<div className="event-info-text">
						<div className="event-text-left">Case Released</div>
						<div className="event-text-right">
							<div>Friday, 8th September</div>
							<div>10:00am EST</div>
						</div>
					</div>

					<div className="event-info-header-container">
						<div className="event-info-header">Info Sessions</div>
					</div>

					<div className="event-info-text">
						<div className="event-text-left">Info Session 1</div>
						<div className="event-text-right">
							<div>Wednesday, 23rd August</div>
							<div>6:00pm - 7:00pm EST</div>
						</div>
					</div>
					<div className="event-info-text">
						<div className="event-text-left">Info Session 2</div>
						<div className="event-text-right">
							<div>Saturday, 26th August</div>
							<div>10:00am - 11:00am EST</div>
						</div>
					</div>
					<div className="event-info-text">
						<div className="event-text-left">Info Session 3</div>
						<div className="event-text-right">
							<div>Monday, 28th August</div>
							<div>4:00pm - 5:00pm EST</div>
						</div>
					</div>

					<div className="event-info-header-container">
						<div className="event-info-header">Due Dates</div>
					</div>

					<div className="event-info-text">
						<div className="event-text-left">
							Executive Summary Due
						</div>
						<div className="event-text-right">
							<div>Monday, 18th September</div>
							<div>11:59pm EST</div>
						</div>
					</div>

					<div className="event-info-text">
						<div className="event-text-left">
							Final Presentations Due
						</div>
						<div className="event-text-right">
							<div>Thursday, 21st September</div>
							<div>11:59pm EST</div>
						</div>
					</div>

					<div className="event-info-text">
						<div className="event-text-left">
							Live Presentations
						</div>
						<div className="event-text-right">
							<div>Saturday, 23rd September</div>
							<div>10:00am EST</div>
						</div>
					</div>

					<div className="event-info-text">
						<div className="event-text-left">Awards Ceremony</div>
						<div className="event-text-right">
							<div>Saturday, 30th September</div>
							<div>11:00am EST</div>
						</div>
					</div>
				</div>

				<div className="break" />
			</div>
		</div>
	);
}
