export const ServicesData = {
	labServices: {
		title: "Lab Services",
		subheader: "Select lab test(s)",
		error: "Please select at least one lab test",
		calendly: "https://calendly.com/okb-hope-health/lab-tests",
		options: {
			Hematology: [
				["Full Blood Count", 40],
				["ESR", 40],
				["Hemoglobin (Hb)", 20],
				["Sickling Test", 30],
				["Thyroid Function Test", 180],
				["Blood Grouping", 20],
			],
			Microbiology: [
				["H-Pylori", 50],
				["Typhi Dot (Widal Test)", 25],
				["Stool RE", 30],
				["Gonorrhoea", 80],
				["Chlamydia", 80],
				["Urine Culture", 100],
				["Urine RE", 25],
			],
			"Immunology and Serology": [
				["Hepatitis B", 20],
				["Hepatitis B Profile", 120],
				["Hepatitis C", 20],
				["VDRL - Syphilis", 20],
				["Retro Screen", 25],
				["RDT for Malaria", 15],
				["Serum Pregnancy Test", 30],
				["Urine Pregnancy Test", 15],
			],
			Biochemistry: [
				["HbA1c", 100],
				["Fasting Blood Sugar", 10],
				["Beta HCG", 130],
				["Blood Film Comment", 160],
				["Glycated Hemoglobin", 80],
				["Liver Function Test", 80],
				["Renal Function Test", 100],
				["Lipid Profile", 80],
				["INR", 100],
				["Uric Acid", 80],
				["Hb Electrophoresis", 50],
				["D-Dimer", 200],
				["Leutinizing Hormone", 80],
				["Progesterone", 80],
				["Prolactin", 80],
				["Prostate Specific Antigen", 80],
			],
		},
	},
	mobileVan: {
		title: "Mobile Van",
		subheader: "Select service(s)",
		error: "Please select one service",
		calendly: "https://calendly.com/okb-hope-health/mobile-van",
		options: {
			Hematology: [
				["Hemoglobin (Hb)", 20],
				["ESR", 40],
				["Sickling Test", 30],
				["Blood Grouping", 20],
			],
			"Immunology and Serology": [
				["Hepatitis B", 20],
				["Hepatitis B Profile", 120],
				["Hepatitis C", 20],
				["VDRL - Syphilis", 20],
				["Retro Screen", 25],
				["RDT for Malaria", 15],
				["Urine Pregnancy Test", 15],
			],
			Microbiology: [
				["H-Pylori", 50],
				["Typhi Dot (Widal Test)", 25],
				["Stool RE", 30],
				["Urine RE", 25],
			],
			Biochemistry: [
				["HbA1c", 100],
				["Hb Electrophoresis", 50],
				["Fasting Blood Sugar", 10],
				["Blood Film Comment", 160],
			],
		},
	},
	telehealth: {
		title: "Telehealth",
		subheader: "Select service(s)",
		error: "Please select at least one service",
		calendly: "https://calendly.com/okb-hope-health/telehealth",
		options: {
			Services: [
				["General consultation", 20],
				["Mental Health Consultation", 50],
				["Eye Service Consultation", 45],
				["Weight Loss and Nutrition Consultation", 50],
			],
		},
	},
};
