// imports
import { NavLink } from "react-router-dom";
import "./Volunteers.css";
import { VolunteersData } from "../../Data/WorkWithUsData/VolunteersData";
import arrow from "../../Assets/Icons/arrow-1.png";

//main
export default function Volunteers() {
  // show latest roles first
  const formattedData = [...VolunteersData].reverse();

  return (
    <div className={`volunteers-container`}>
      <div className={`volunteers-header`}>
        Volunteer in Ghana
        <NavLink className="cancel-appt-button" to={`/work-with-us`}>
          <img src={arrow} alt="go to previous page" className="arrow-icon" />
        </NavLink>
      </div>

      {formattedData &&
        formattedData.length > 0 &&
        formattedData.map((data) => (
          <div className="work-location" key={data.id}>
            <div className="volunteers-left">
              <h4 className="volunteer-title">{data.title}</h4>

              <div className="volunteer-text-container">
                <div className="volunteer-text">{data.text}</div>
              </div>
            </div>

            <div className="volunteers-right">
              <div className="work-address">{data.location1}</div>
              <div className="work-address-remote">{data.location2}</div>
              <NavLink
                className="button red-hover white"
                to={`/volunteers/${data.id}`}
              >
                Learn More
              </NavLink>
            </div>
          </div>
        ))}

      <div className="volunteer-footer">
        <div className="volunteer-extra-header">
          Looking for Internship Programs?
        </div>
        <NavLink to="/internships" className="blue-button">
          View Internship Programs
        </NavLink>
      </div>
    </div>
  );
}
