import { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { CommunitiesListData } from "../../Data/OurImpactData/CommunitiesData";
import "./communities.css";

const Communities = () => {
	// console.log("ourdata: ", CommunitiesListData)
	const navigate = useNavigate();

	const sortedCommunities = [...CommunitiesListData];

	return (
		<div className="communities-list-wrapper">
			<div className="community-list-header">Find a Community</div>
			<div className="community-list">
				{sortedCommunities.map((community) => (
					<div className="community-item" key={community.id}>
						{community.visible ? (
							<Link
								className="community-link community-link-visible"
								to={`/communities/${community.id}`}
							>
								{community.name}
							</Link>
						) : (
							<div className="community-link">
								{community.name}
							</div>
						)}
					</div>
				))}
			</div>
		</div>
	);
};

export default Communities;
