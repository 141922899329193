//who we are page

//imports
import "./WhatWeDoPage.css";

import stories5 from "../../Assets/WhatWeDo/what-12.png";
import stories6 from "../../Assets/WhatWeDo/what-6.png";
import stories7 from "../../Assets/WhatWeDo/what-14.png";
import stories8 from "../../Assets/WhatWeDo/what-8.png";
import stories9 from "../../Assets/WhatWeDo/what-13.png";
import stories10 from "../../Assets/WhatWeDo/what-10.png";
import stories11 from "../../Assets/WhatWeDo/what-11.png";
import stories12 from "../../Assets/WhatWeDo/what-15.png";
import { NavLink } from "react-router-dom";
import ReactGA from "react-ga";

//main
export default function WhatWeDoPage() {
  // Track page view when the component mounts
  ReactGA.pageview(window.location.pathname + window.location.search);

  //return
  return (
    <div className="large-screen">
      <div className="home-header-outer">
        <div className="what-header">
          <div className="what-title-header">What We Do</div>
        </div>
      </div>

      <div className="what-stories-container">
        <div className="what-stories">
          <img
            src={stories10}
            className="home-stories-image"
            alt="home stories"
          />
          <div className="what-stories-title">PROVIDE CLINICAL CARE</div>
          <div className="what-stories-text">
            These services invlude whole health assessment and medicine for
            life-threatening conditions such as hypertension and diabetes.
          </div>
          <div className="button what-button hidden">LEARN MORE</div>
        </div>

        <div className="what-stories">
          <img
            src={stories12}
            className="home-stories-image"
            alt="home stories"
          />
          <div className="what-stories-title">CREATE LIFE-SAVING SOLUTIONS</div>
          <div className="what-stories-text">
            Underserved communities in Ghana receive malaria prevention, potable
            water sources, and essential hospital supplies and treatments.
          </div>
          <div className="button hidden">LEARN MORE</div>
        </div>

        <div className="what-stories">
          <img
            src={stories11}
            className="home-stories-image"
            alt="home stories"
          />

          <div className="what-stories-title">TEACH HEALTH EMPOWERMENT</div>

          <div className="what-stories-text">
            Our know your health program is geared towards educating and
            empowering local community members to be advocates in their
            respective communities.
          </div>
          <div className="button hidden">LEARN MORE</div>
        </div>
      </div>

      <div className="what-body">
        <div className="what-body-section">
          <div className="what-section-left mleft70">
            <h2 className="what-section-left-header">
              Improving Healthcare Accessibility
            </h2>

            <div className="what-section-text">
              We aim to improve healthcare accessibility with our Hope Health
              Vans. Our Hope Health vans are mini hospitals that travel to rural
              communities to provide health education and life-saving
              treatments.
            </div>
            <div className="blue-button what-learn-more hidden">Learn More</div>
          </div>

          <div className="what-section-right">
            <img
              className="what-stories-image"
              src={stories5}
              alt="what we do"
            />
          </div>
        </div>

        <div className="what-body-section-reverse">
          <div className="what-section-left">
            <h2 className="what-section-left-header">
              Research and Development
            </h2>

            <div className="what-section-text">
              One of the pillars of OKB Hope Foundation is improving the lives
              of the population we serve through research. We work with
              different healthcare institutions in Ghana to conduct cutting-edge
              research.
            </div>
            <div className="blue-button what-learn-more hidden">Learn More</div>
          </div>

          <div className="what-section-right">
            <img
              className="what-stories-image"
              src={stories6}
              alt="what we do"
            />
          </div>
        </div>

        <div className="what-body-section">
          <div className="what-section-left mleft70">
            <h2 className="what-section-left-header">
              Improving Mental Accessibility
            </h2>

            <div className="what-section-text">
              Through our Wohohiame initiative, we aim to improve mental health
              education and resources to individuals experiencing mental health
              challenges. We provide mental health workshops, panel discussions,
              and one-on-one counseling for different institutions and student
              groups.
            </div>
            <div className="blue-button what-learn-more hidden">
              <NavLink to="https://www.wohohiame.com/" className="white-button">
                LEARN MORE
              </NavLink>
            </div>
          </div>

          <div className="what-section-right">
            <img
              className="what-stories-image"
              src={stories7}
              alt="what we do"
            />
          </div>
        </div>

        <div className="what-body-section-reverse">
          <div className="what-section-left">
            <h2 className="what-section-left-header">
              We Offer Internship Programs
            </h2>

            <div className="what-section-text">
              We offer comprehevsive internship and service learning
              opportunities for students, allowing them to gain valuable
              hands-on experience and contribute to our mission of delivering
              transformative healthcare services to remote parts of Ghana.
            </div>
            <div className="blue-button what-learn-more hidden">
              <NavLink to="/internships" className="white-button">
                LEARN MORE
              </NavLink>
            </div>
          </div>

          <div className="what-section-right">
            <img
              className="what-stories-image"
              src={stories8}
              alt="what we do"
            />
          </div>
        </div>

        <div className="what-body-section">
          <div className="what-section-left mleft70">
            <h2 className="what-section-left-header">Case Competitions</h2>

            <div className="what-section-text">
              Our Global healthcare Case Competition aims to enhance
              undergraduate and graduate education and most importantly prepare
              students to contribute to the international sphere. In a tight,
              deadline-driven period, students leverage skills such as
              qualitative and quantitative analysis, critical and strategic
              thinking, problem solving, collaboration, agility to solve most
              pressing healthcare issues.
            </div>
            <div className="blue-button what-learn-more hidden">Learn More</div>
          </div>

          <div className="what-section-right">
            <img
              className="what-stories-image tall-image"
              src={stories9}
              alt="what we do"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
