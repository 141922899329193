// imports
import { useState } from "react";
import "../Footer.css";

// main - mailchimp API
export default function MailChimp() {
	// states for values
	const [email, setEmail] = useState("");
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");

	// return
	return (
		<>
			<link
				href="//cdn-images.mailchimp.com/embedcode/classic-10_7_dtp.css"
				rel="stylesheet"
				type="text/css"
			></link>
			<div id="mc_embed_signup">
				<form
					action="https://okbfoundation.us7.list-manage.com/subscribe/post?u=36f60f19a4e8d05ddaf6619b3&amp;id=83a0c3fbda"
					method="post"
					id="mc-embedded-subscribe-form"
					name="mc-embedded-subscribe-form"
					className="validate"
					target="_blank"
					noValidate
				>
					<div id="mc_embed_signup_scroll">
						<div className="mc-field-group">
							<label htmlFor="mce-EMAIL">Email Address</label>
							<input
								type="email"
								value={email}
								name="EMAIL"
								className="required email mailchimp-input"
								id="mce-EMAIL"
								onChange={(e) => setEmail(e.target.value)}
								required
							/>
						</div>
						<div className="mc-field-group">
							<label htmlFor="mce-FNAME">First Name</label>
							<input
								type="text"
								value={firstName}
								name="FNAME"
								className="mailchimp-input"
								id="mce-FNAME"
								onChange={(e) => setFirstName(e.target.value)}
								required
							/>
						</div>
						<div className="mc-field-group">
							<label htmlFor="mce-LNAME">Last Name</label>
							<input
								type="text"
								value={lastName}
								name="LNAME"
								className="mailchimp-input"
								id="mce-LNAME"
								onChange={(e) => setLastName(e.target.value)}
								required
							/>
						</div>
						<div id="mce-responses" className="clear foot">
							<div
								className="response"
								id="mce-error-response"
								style={{ display: "none" }}
							></div>
							<div
								className="response"
								id="mce-success-response"
								style={{ display: "none" }}
							></div>
						</div>
						<div
							style={{ position: "absolute", left: "-5000px" }}
							aria-hidden="true"
						>
							<input
								type="text"
								name="b_36f60f19a4e8d05ddaf6619b3_83a0c3fbda"
								tabIndex="-1"
								defaultValue=""
							/>
						</div>
						<div className="optionalParent">
							<center>
								<div>
									<input
										type="submit"
										defaultValue="Sign Up"
										name="subscribe"
										id="mc-embedded-subscribe"
										className="button"
									/>
									<p className="brandingLogo">
										<a
											href="http://eepurl.com/hP6U19"
											title="Mailchimp - email marketing made easy and fun"
										>
											<img src="https://eep.io/mc-cdn-images/template_images/branding_logo_text_dark_dtp.svg" alt="branding logo" />
										</a>
									</p>
								</div>
							</center>
						</div>
					</div>
				</form>
			</div>
		</>
	);
}
