//imports
import { useEffect } from "react";
import $ from "jquery";

//main
export default function FlipcauseVolunteer({ src }) {
  useEffect(() => {
    $("#fc-direct-embed-loader-out").css("display", "block");
    setTimeout(hideLoader, 1000);

    function hideLoader() {
      $("#fc-direct-embed-loader-out").css("display", "none");
    }
  }, []);

  return (
    <div>
      <div
        id="fc-direct-embed-loader-out"
        style={{ display: "block" }}
        dangerouslySetInnerHTML={{
          __html: `
				<div id="fc-embed-loader" className="fc-direct-embed-loader" style="top: 257.5px; display: block;"></div>
			  `,
        }}
      ></div>
      <div id="fc-embed-main-box" className="fc-embed-main-box">
        <iframe
          id="fc-embed-frame"
          style={{
            minHeight: "2250px",
            height: "2250px",
            width: "95%",
            border: "0",
            marginTop: "50px",
          }}
          src={`https://www.flipcause.com/hosted_widget/hostedWidgetHome/${src}`}
          scrolling="no"
          title="Flipcause Volunteer Form"
        ></iframe>
      </div>
    </div>
  );
}
