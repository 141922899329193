// imports
import "../Internships.css";
import { NavLink } from "react-router-dom";
import arrow from "../../../Assets/Icons/arrow-1.png";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import FlipcauseInternship from "../FlipcauseInternship";
import { InternshipsData as allInternshipsData } from "../../../Data/WorkWithUsData/InternshipsData";
import ScrollToTop from "../../../common/ScrollToTop/scrollToTop";

//main
export default function InternshipDetail() {
  const navigate = useNavigate();
  const [showForm, setShowForm] = useState(false);

  // open form with click or close on cancel
  const handleClick = () => {
    showForm ? setShowForm(false) : setShowForm(true);
  };
  // close form with click
  const handleClose = () => {
    setShowForm(false);
  };

  // find internship data
  const internshipId = parseInt(window.location.pathname.split("/").pop());

  const internshipData = allInternshipsData.find(
    (data) => data.id === internshipId
  );

  const {
    roleOverview,
    keyResponsibilities,
    title,
    text,
    location1,
    location2,
    qualifications,
    finalNote,
    src,
  } = internshipData;

  const formattedKeyResponsibilities = keyResponsibilities
    ? Object.keys(keyResponsibilities)
    : null;

  if (!internshipData) {
    navigate("/not-found");
  }

  useEffect(() => {
    // scroll to top when state changes
    window.scrollTo(0, 50);
  }, [showForm]);

  return (
    <div className="volunteers-container white-back">
      <ScrollToTop />
      <div className={`volunteers-header`}>
        <div>Internship Programs</div>
        {!showForm ? (
          <NavLink className="cancel-appt-button" to={`/internships`}>
            <img src={arrow} alt="go to previous page" className="arrow-icon" />
          </NavLink>
        ) : (
          <NavLink
            className="cancel-appt-button"
            to={`#`}
            onClick={handleClose}
          >
            <img src={arrow} alt="go to previous page" className="arrow-icon" />
          </NavLink>
        )}
      </div>

      {showForm && <FlipcauseInternship src={src ? src : null} />}

      {!showForm && internshipData && (
        <>
          {/* header */}
          <div className="volunteer-center volunteers-section">
            <h4 className="volunteer-header">{title}</h4>
          </div>

          {/* overview */}
          {roleOverview ? (
            <div className="volunteer-center volunteers-section">
              <h4 className="volunteer-title">{"Role Overview"}</h4>

              <div className="volunteer-detail-text">{roleOverview}</div>
            </div>
          ) : text ? (
            <div className="volunteer-center volunteers-section">
              <h4 className="volunteer-title">{"Role Overview"}</h4>

              <div className="volunteer-detail-text">{text}</div>
            </div>
          ) : null}

          {/* key responsibilities */}
          {formattedKeyResponsibilities &&
            formattedKeyResponsibilities.length > 0 && (
              <div className="volunteer-center volunteers-section">
                <h4 className="volunteer-title">{"Key Responsibilities"}</h4>

                {formattedKeyResponsibilities.map(
                  (responsibilityName, index) => (
                    <div key={index} className="volunteer-detail-text">
                      {!(Number(responsibilityName) > 0) ? (
                        <>
                          <div>{responsibilityName}</div>
                          <ul>
                            {keyResponsibilities[responsibilityName].map(
                              (description, index2) => (
                                <li className="qualifications-spacing" key={index2}>{description}</li>
                              )
                            )}
                          </ul>
                        </>
                      ) : (
                        keyResponsibilities[responsibilityName].map(
                          (description, index2) => (
                            <li className="qualifications-spacing" key={index2}>{description}</li>
                          )
                        )
                      )}
                    </div>
                  )
                )}
              </div>
            )}

          {/* qualifications */}
          {qualifications && qualifications.length > 0 && (
            <div className="volunteer-center volunteers-section">
              <h4 className="volunteer-title">{"Qualifications"}</h4>

              <div className="volunteer-detail-text">
                {qualifications.map((qualification, index) => (
                  <li className="qualifications-spacing" key={index}>
                    {qualification}
                  </li>
                ))}
              </div>
            </div>
          )}

          {/* details */}
          {location1 && (
            <div className="volunteer-center volunteers-section">
              <h4 className="volunteer-title">{"Details"}</h4>

              <div className="volunteer-detail-text">{`Location: ${
                location1 ? location1 : null
              } - ${location2 ? location2 : null}`}</div>
            </div>
          )}

          {/* final note */}
          {finalNote && (
            <div className="volunteer-center volunteers-section">
              {finalNote}
            </div>
          )}
        </>
      )}

      <div className="volunteer-footer">
        {showForm ? (
          <div className="blue-button" onClick={handleClick}>
            CANCEL
          </div>
        ) : (
          <div className="button" onClick={handleClick}>
            APPLY
          </div>
        )}
      </div>
    </div>
  );
}
