// imports
import "./Member.css";
import { OurTeamData } from "../../../Data/WhoWeAreData/OurTeamData";
import linkedinIcon from "../../../Assets/Icons/linkedin-faded.svg";
import { NavLink, useParams, useLocation } from "react-router-dom";
import { useEffect, useRef } from "react";
import arrow from "../../../Assets/Icons/arrow-1.png";

// main
export default function Member() {
  // find member
  const params = useParams();
  const id = params.id;
  let member;
  if (params.id.startsWith("a")) {
    member = OurTeamData.administrative.find((el) => el.id === id);
  } else if (params.id.startsWith("m")) {
    member = OurTeamData.medical.find((el) => el.id === id);
  } else if (params.id.startsWith("d")) {
    member = OurTeamData.directors.find((el) => el.id === id);
  } else {
    member = OurTeamData.volunteers.find((el) => el.id === id);
  }

  // ---------------------

  // point to id
  const location = useLocation();
  const elementRef = useRef(null);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);

    if (elementRef.current && id) {
      elementRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [location]);

  // ---------------------

  // return
  return (
    <>
      <NavLink className="what-learn-more cancel-appt-button" to="/who-we-are">
        <img src={arrow} alt="go to previous page" className="arrow-icon" />
      </NavLink>

      <div className="team-header" ref={elementRef} id="m1"></div>

      <div className="booking-body-section-three member-info-mobile">
        <div className="donate-person-container">
          <img
            src={member.image}
            alt="healthcare volunteers"
            className="donate-person-image"
          />
          <div className="donate-person-info">
            <div className="donate-person-name">{member.name}</div>
            <div className="donate-person-position">{member.position}</div>
          </div>

          {member.linkedin && (
            <div className="who-team-link">
              <NavLink to={member.linkedin} target="_blank">
                <img
                  className="team-linkedin-icon"
                  src={linkedinIcon}
                  alt="linkedin"
                />
              </NavLink>
            </div>
          )}
        </div>

        <div className="member-desc-container">
          {member.description.map((el, i) => (
            <div className="member-desc" key={i}>
              {el}
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
