// imports
import { useParams } from "react-router-dom";
import EventsData from "../../../Data/EventsData/EventsData";
import CaseCompetitions from "../CaseCompetitions";
import DefaultEventsDetailPage from "./DefaultEventsDetailPage";
import SummerInternshipEvent from "../SummerInternship";
import HealthOutreachEvent from "../HealthOutreach";
import "../EventsPage.css";

// componentMap
const componentMap = {
	caseCompetitions: CaseCompetitions,
	summerInternshipEvent: SummerInternshipEvent,
	healthOutreachEvent: HealthOutreachEvent,
};

// main
export default function EventsDetailPage() {
	const { eventId } = useParams();

	// find event data
	let eventData;
	if (EventsData.upcoming) {
		eventData = EventsData.upcoming.find((event) => event.id === eventId);
	};
	if (!eventData && EventsData.past) {
		eventData = EventsData.past.find((event) => event.id === eventId);
	};

	// return component associated with event
	if (eventData && eventData["componentLink"]) {
		const Component = componentMap[eventData["componentLink"]];
		return <Component />;
	} else {
		return <DefaultEventsDetailPage />;
	}
}

// TODO: do a check to see the date of the event, if active then show page, else point to not found /not-found
