//imports
import "./HomePage.css";
import { NavLink } from "react-router-dom";
import pic1 from "../../Assets/Home/pic-1.jpg";
import pic2 from "../../Assets/Home/pic-2.jpg";
import pic5 from "../../Assets//Home/pic-5.jpg";
import pic6 from "../../Assets/Home/home-12.png";
import ReactGA from "react-ga";
import ImageCarousel from "./ImageCarousel";
// import Timer from "../Timer";

//main
export default function HomePage() {
  // Track page view when the component mounts
  ReactGA.pageview(window.location.pathname + window.location.search);

  //return
  return (
    <div className="home-container">
      {/* Timer OFF */}
      {/* <div className="inline">{Timer()}</div> */}

      <ImageCarousel />

      <div className="home-who-what">
        <div className="home-who">
          <h2 className="home-who-header">Who We Are</h2>
          <div className="home-who-text">
            We're a 501(3) Tax-Exempt Nonprofit transforming healthcare in Ghana through collaborative
            education, cutting-edge research, and patient-centered care. Our
            goal is to ensure that people have access to healthcare, what we
            believe in a fundamental right.
          </div>
          <NavLink to="/who-we-are">
            <div className="button home-who-button">Learn More</div>
          </NavLink>
        </div>

        <div className="home-what">
          <h2 className="home-what-header">What We Do</h2>
          <div className="home-what-text">
            We provide transformative healthcare services and life-saving
            solutions to the underserved Ghanaians. We are improving the quality
            of lives of underserved Ghanaians by delivering innovative
            healthcare solutions and providing access to essential medical
            services.
          </div>
          <NavLink to="/what-we-do">
            <div className="button home-who-button">Learn More</div>
          </NavLink>
        </div>
      </div>

      <div className="home-stories">
        <div className="home-stories-header home-spacing">
          <h2 className="home-stories-header-title">
            Be a part of the change.
            <div className="make-a-diff">You can make a difference.</div>
          </h2>

          <div className="home-buttons-ct">
            <NavLink to="/donate" className="white-button">
              <div className="blue-button">Donate</div>
            </NavLink>
            <NavLink to="/work-with-us" className="white-button">
              <div className="button mleft15">Volunteer</div>
            </NavLink>
          </div>
        </div>

        <div className="home-stories-container">
          <div className="home-stories-left">
            <img src={pic1} className="home-stories-image" alt="home stories" />

            <div className="home-stories-title">HOPE HEALTH VANS</div>

            <div className="home-stories-text">
              OKB reduces healthcare problems in rural and underserved
              communities by bringing medical care to the doorsteps of local
              communities in Ghana. Our mobile medical van is equipped with
              multiple point-of-care diagnostics and medical supplied and
              seasoned healthcare providers that visit underserved and remote
              areas. The van ensure that individuals in these communities have
              access to essential healthcare services.
            </div>
          </div>

          <div className="home-stories-mid">
            <img src={pic6} className="home-stories-image" alt="home stories" />
            <div className="home-stories-title">HOPE HEALTH INITIATIVE</div>
            <div className="home-stories-text">
              We address the shortage of healthcare providers in rural and
              underserved communities by training community health volunteers to
              serve as health advocates in their communities. Volunteers are
              educated, trained, and equipped to provide health education and
              basic healthcare services to collect data on members that they
              serve. Community health volunteers offer sustainability within
              remote local communities.
            </div>
          </div>

          <div className="home-stories-mid">
            <img src={pic2} className="home-stories-image" alt="home stories" />

            <div className="home-stories-title">HOPE HEALTH HUB</div>

            <div className="home-stories-text">
              The initiative builds virtual care centers and facilities in rural
              and underserved communities. The centers are equipped with basic
              health equipment and technology, staffed by our trained health
              advocates. This approach allows individuals in rural and
              underserved communities to consult with healthcare providers
              located in urban areas and cities. These centers provide
              healthcare to those without access to technology.
            </div>
          </div>

          <div className="home-stories-right">
            <img src={pic5} className="home-stories-image" alt="home stories" />

            <div className="home-stories-title">WOHOHIAME WELLNESS</div>

            <div className="home-stories-text">
              "Wohohiame" which means I care for you in the Twi Ghanaian
              language addresses mental health challenges in Ghana. The program
              provides individuals struggling with mental health challenges with
              information that helps them cope with mental health challenges.
              Content on this platform is available in multiple languages in
              audio formats. The initiative has provided mental education and
              resources to over 800 high school students.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
