// imports
import "./App.css";
import { useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import ReactGA from "react-ga";
import ScrollToTop from "./common/ScrollToTop/scrollToTop";

// pages + components = alphabetical order
import Booking from "./pages/Booking";
import CaseCompetitionsJudge from "./pages/EventsPage/CaseCompetitions/CaseCompetitionsJudge";
import Community from "./common/Communities/Community";
import Donate from "./pages/Donate";
import DonateEmailForm from "./pages/Donate/DonateEmailForm/DonateEmailForm";
import DonateHopeHealthHub from "./pages/Donate/DonateHopeHealthHub";
import DonateHopeHealthInitiatve from "./pages/Donate/DonateHopeHealthInitiative";
import DonateHopeHealthVans from "./pages/Donate/DonateHopeHealthVans";
import DonateWohohiameWellness from "./pages/Donate/DonateWohohiameWellness";
import EventsDetailPage from "./pages/EventsPage/EventsDetailPage";
import EventsPage from "./pages/EventsPage";
import Footer from "./common/Footer";
import GroupHealthCheck from "./pages/Booking/GroupHealthCheck/GroupHealthCheck";
import HomePage from "./pages/HomePage";
import InternshipDetail from "./pages/Internships/InternshipDetail";
import Internships from "./pages/Internships";
import LabServices from "./pages/Booking/LabServices/LabServices";
import Member from "./pages/WhoWeArePage/Member";
import MobileVan from "./pages/Booking/MobileVan/MobileVan";
import NavBar from "./common/NavBar";
import NewsPage from "./pages/NewsPage";
import NewsStory from "./pages/NewsPage/NewsStory";
import NotFoundPage from "./common/Errors/NotFoundPage";
import OurImpactPage from "./pages/OurImpactPage";
import Telehealth from "./pages/Booking/Telehealth/Telehealth";
import VolunteerDetail from "./pages/Volunteers/ VolunteerDetail";
import Volunteers from "./pages/Volunteers";
import WhatWeDoPage from "./pages/WhatWeDoPage";
import WhoWeArePage from "./pages/WhoWeArePage";
import WohohiamePage from "./pages/WohohiamePage";
import WorkWithUsPage from "./pages/WorkWithUsPage";

// experimental
import Store from "./pages/Store";

// Initialize Google Analytics
ReactGA.initialize("UA-215120552-1");

//main
export default function App() {
	// google analytics tracking
	useEffect(() => {
		ReactGA.pageview(window.location.pathname + window.location.search);
	}, []);

	return (
		<>
			<ScrollToTop>
				<NavBar />
				<Routes>
					<Route
						exact
						path="/who-we-are"
						element={<WhoWeArePage />}
					></Route>
					<Route
						exact
						path="/what-we-do"
						element={<WhatWeDoPage />}
					></Route>

					<Route
						exact
						path="/our-impact"
						element={<OurImpactPage />}
					></Route>

					<Route
						exact
						path="/work-with-us"
						element={<WorkWithUsPage />}
					></Route>

					<Route
						exact
						path="/donate-form"
						element={<DonateEmailForm />}
					></Route>

					<Route
						exact
						path="/lab-services"
						element={<LabServices />}
					></Route>

					<Route
						exact
						path="/telehealth"
						element={<Telehealth />}
					></Route>

					<Route
						exact
						path="/mobile-van"
						element={<MobileVan />}
					></Route>

					<Route
						exact
						path="/volunteers"
						element={<Volunteers />}
					></Route>
					<Route
						path="/volunteers/:id"
						element={<VolunteerDetail />}
					></Route>

					<Route
						exact
						path="/internships"
						element={<Internships />}
					></Route>
					<Route
						path="/internships/:id"
						element={<InternshipDetail />}
					></Route>

					<Route
						exact
						path="/group-health-check"
						element={<GroupHealthCheck />}
					></Route>

					<Route exact path="/store" element={<Store />}></Route>

					<Route
						exact
						path="/wohohiame"
						element={<WohohiamePage />}
					></Route>

					<Route
						path="/events/:eventId"
						element={<EventsDetailPage />}
					></Route>

					<Route
						exact
						path="/events"
						element={<EventsPage />}
					></Route>

					<Route
						exact
						path="/communities/:communityId"
						element={<Community />}
					></Route>

					<Route exact path="/news" element={<NewsPage />}></Route>

					<Route exact path="/booking" element={<Booking />}></Route>

					<Route exact path="/donate" element={<Donate />}></Route>
					<Route
						exact
						path="/donate-hope-health-hub"
						element={<DonateHopeHealthHub />}
					></Route>
					<Route
						exact
						path="/donate-hope-health-initiative"
						element={<DonateHopeHealthInitiatve />}
					></Route>
					<Route
						exact
						path="/donate-wohohiame-wellness"
						element={<DonateWohohiameWellness />}
					></Route>
					<Route
						exact
						path="/donate-hope-health-vans"
						element={<DonateHopeHealthVans />}
					></Route>

					<Route exact path="/" element={<HomePage />}></Route>

					<Route path={"/mbr/:id"} element={<Member />}></Route>

					<Route path={"/news/:id"} element={<NewsStory />}></Route>

					<Route
						path={"/judge/:id"}
						element={<CaseCompetitionsJudge />}
					></Route>

					<Route path="/not-found" element={<NotFoundPage />}></Route>

					<Route
						path="*"
						element={<Navigate to="/not-found" />}
					></Route>
				</Routes>
				<Footer />
			</ScrollToTop>
		</>
	);
}
