//imports
import { NavLink, useParams } from "react-router-dom";
import EventsData from "../../../Data/EventsData/EventsData";
import "../EventsPage.css";

//main
export default function DefaultEventsDetailPage() {
	// find event data
	const { eventId } = useParams();
	let eventData;
	if (EventsData.upcoming) {
		eventData = EventsData.upcoming.find((event) => event.id === eventId);
	}
	if (!eventData && EventsData.past) {
		eventData = EventsData.past.find((event) => event.id === eventId);
	}

	// destructure event data
	const {
		id,
		title,
		details,
		description,
		location,
		dates,
		applyLink,
		componentLink,
		videoLink,
		eventStartDate,
		eventEndDate,
		eventStartTime,
		eventEndTime,
	} = eventData;

	const fullTitle = title + " " + details;

	const currentDate = new Date();

	//return
	return (
		<div className="large-screen">
			<div className="home-header-outer">
				<div className="case-competitions-header">
					<div className="impact-title shrink-long-title">
						{title}
					</div>
				</div>
			</div>

			<div className="our-impact-body">
				<div className="what-body-section" id="case-comp-intro">
					<div
						className={`what-section-left margins-auto`}
						id={`${!videoLink && "event-section-full"}`}
					>
						<h2 className="what-section-left-header">
							{fullTitle}
						</h2>

						<div
							className={`what-section-text`}
							id={`${!videoLink && "event-section-full"}`}
						>
							{description &&
								description.length > 0 &&
								description.map((message, index) => (
									<div
										className="see-how-we-do event-text"
										key={index}
									>
										{message}
									</div>
								))}

							{applyLink && (
								<div
									className={`learn-more-container mleft25vw ${
										!videoLink && "text-center"
									}`}
									id={`${!videoLink && "event-section-full"}`}
								>
									<NavLink
										className="learn-more-button"
										to={applyLink}
										target="_blank"
									>
										Apply
									</NavLink>
								</div>
							)}
						</div>
					</div>

					{videoLink && (
						<div
							className="what-section-right"
							id="case-comp-section-right"
						>
							<iframe
								className="event-video all-events-video"
								id="case-comp-vid"
								src={videoLink}
								title="YouTube video player"
								allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
								allowFullScreen
							></iframe>
						</div>
					)}
				</div>

				{eventStartDate <= currentDate && (
					<>
						<div className="event-info-header-container">
							<div className="event-info-header">
								General Info
							</div>
						</div>

						<div className="margin-bottom-prizes">
							<div className="event-info-text">
								<div className="event-text-left">
									Registration Opens
								</div>
								<div className="event-text-right">
									<div>Wednesday, 16th August</div>
									<div>10:00am EST</div>
								</div>
							</div>
							<div className="event-info-text">
								<div className="event-text-left">
									Registration Closes
								</div>
								<div className="event-text-right">
									<div>Wednesday, 30th August</div>
									<div>11:59pm EST</div>
								</div>
							</div>

							<div className="event-info-text">
								<div className="event-text-left">
									Teams Announced
								</div>
								<div className="event-text-right">
									<div>Tuesday, 5th September</div>
									<div>10:00am EST</div>
								</div>
							</div>

							<div className="event-info-text">
								<div className="event-text-left">
									Case Released
								</div>
								<div className="event-text-right">
									<div>Friday, 8th September</div>
									<div>10:00am ET</div>
								</div>
							</div>
						</div>
					</>
				)}
				<div className="break" />
			</div>
		</div>
	);
}
