// imports
import "./OurTeam.css";
import { OurTeamData } from "../../../Data/WhoWeAreData/OurTeamData";
import { useState } from "react";
import OurTeamSection from "./OurTeamSection";

// main
export default function OurTeam() {
  // data
  const [showAdmins, setShowAdmins] = useState(true);
  const [showMedicals, setShowMedicals] = useState(false);
  const [showDirectors, setShowDirectors] = useState(false);
  const [showVolunteers, setShowVolunteers] = useState(false);
  const [selectedSection, setSelectedSection] = useState("admin");

  // onclicks
  const onClickAdmin = () => {
    setShowDirectors(false);
    setShowMedicals(false);
    setShowVolunteers(false);
    setShowAdmins(true);
    setSelectedSection("admin");
  };
  const onClickMedical = () => {
    setShowDirectors(false);
    setShowAdmins(false);
    setShowVolunteers(false);
    setShowMedicals(true);
    setSelectedSection("medical");
  };

  const onClickDirectors = () => {
    setShowAdmins(false);
    setShowMedicals(false);
    setShowVolunteers(false);
    setShowDirectors(true);
    setSelectedSection("director");
  };

  const onClickVolunteers = () => {
    setShowAdmins(false);
    setShowMedicals(false);
    setShowDirectors(false);
    setShowVolunteers(true);
    setSelectedSection("volunteer");
  };

  const sectionMapping = {
    admin: OurTeamData.administrative,
    medical: OurTeamData.medical,
    director: OurTeamData.directors,
    volunteer: OurTeamData.volunteers,
  };

  // return
  return (
    <div className="our-team-container">
      <div className="team-titles large-screen-only">
        <div
          className={`team-header ${showAdmins && "team-header-active"}`}
          id="team-main"
          onClick={onClickAdmin}
        >
          Administrative
        </div>
        <div
          className={`team-header ${showMedicals && "team-header-active"}`}
          id="team-main"
          onClick={onClickMedical}
        >
          Medical
        </div>
        <div
          className={`team-header ${showDirectors && "team-header-active"}`}
          id="team-main"
          onClick={onClickDirectors}
        >
          Board of Directors
        </div>
        <div
          className={`team-header ${showVolunteers && "team-header-active"}`}
          id="team-main"
          onClick={onClickVolunteers}
        >
          Volunteers
        </div>
      </div>

      {/* mobile links */}
      <div class="accordion small-screen-only" id="our-leadership-accordion">
        <div class="accordion-item">
          <h2 class="accordion-header">
            <button
              class="accordion-button our-leadership-title"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne"
            >
              Our Leadership Team
            </button>
          </h2>
          <div
            id="collapseOne"
            class="accordion-collapse collapse"
            data-bs-parent="#our-leadership-accordion"
          >
            <div
              className={`team-header ${showAdmins && "team-header-active"}`}
              id="team-main"
              onClick={onClickAdmin}
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
            >
              Administrative
            </div>
            <div
              className={`team-header ${showMedicals && "team-header-active"}`}
              id="team-main"
              onClick={onClickMedical}
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
            >
              Medical
            </div>
            <div
              className={`team-header ${showDirectors && "team-header-active"}`}
              id="team-main"
              onClick={onClickDirectors}
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
            >
              Board of Directors
            </div>
            <div
              className={`team-header ${
                showVolunteers && "team-header-active"
              }`}
              id="team-main"
              onClick={onClickVolunteers}
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
            >
              Volunteers
            </div>
          </div>
        </div>
      </div>

      {OurTeamData.administrative.length > 0 && (
        <OurTeamSection selectedSectionData={sectionMapping[selectedSection]} />
      )}
    </div>
  );
}
