// event images
// imports
import { useEffect, useState } from "react";
import "./EventImages.css";
import leftArrow from "../../Assets/Icons/left-arrow.png";
import rightArrow from "../../Assets/Icons/right-arrow.png";

// main
export default function EventImages({ images, title }) {
	let numberOfImagesDisplayed = 5;
	// get screen size
	const [screenSize, setScreenSize] = useState(window.innerWidth);
	useEffect(() => {
		// Update the state with the current screen size
		setScreenSize(window.innerWidth);
	}, []);
	if (screenSize <= 480) {
		numberOfImagesDisplayed = 3;
	} else {
		numberOfImagesDisplayed = 5;
	}

	const [selectedImage, setSelectedImage] = useState(0);
	const [selectedImagePage, setSelectedImagePage] = useState(0);
	const maxNumberOfPages = Math.floor(images.length / numberOfImagesDisplayed);

	const imagesIndex = (index) => selectedImagePage * numberOfImagesDisplayed + index;

	const handlePageBack = () => {
		if (selectedImagePage == 0) {
			return
		} else {
			setSelectedImagePage(selectedImagePage - 1);
		}
	}

	const handlePageForward = () => {
		if (selectedImagePage == maxNumberOfPages) {
			return
		} else {
			setSelectedImagePage(selectedImagePage + 1);
		}
	}

	const selectedImages = images.slice((selectedImagePage * numberOfImagesDisplayed), (selectedImagePage + 1) * numberOfImagesDisplayed);

	return (
		<div className="event-images-container">
			<div className="event-image-main">
				<img
					src={images && images[selectedImage]}
					alt="event"
					className="event-image-main"
					type="button"
					data-bs-toggle="modal"
					data-bs-target="#exampleModal"
				/>
			</div>

			<div
				className="modal fade event-image-modal"
				id="exampleModal"
				tabIndex="-1"
				aria-labelledby="eventImagesModalLabel"
				aria-hidden="true"
			>
				<div className="modal-dialog">
					<div className="modal-content">
						<div className="modal-header">
							<h1
								className="modal-title fs-5"
								id="eventImagesModalLabel"
							>
								{`${title}`}
							</h1>
							<button
								type="button"
								className="btn-close"
								data-bs-dismiss="modal"
								aria-label="Close"
							></button>
						</div>
						<div className="modal-body">
							<img
								src={images && images[selectedImage]}
								alt="event"
								className="event-image-main-modal"
								type="button"
								data-bs-toggle="modal"
								data-bs-target="#exampleModal"
							/>
						</div>
					</div>
				</div>
			</div>

			<div className="event-images-row">
				<img className="page-move-icon" src={leftArrow} onClick={handlePageBack} />
				{images &&
					images.length > 0 &&
					selectedImages.map((image, index) => (
						<img
							key={`${selectedImagePage}-${index}`}
							src={image}
							alt="event"
							className={`event-image ${
								imagesIndex(index) === selectedImage &&
								"event-image-selected"
							} pointer`}
							onClick={() => setSelectedImage(imagesIndex(index))}
						/>
					))}
				<img className="page-move-icon" src={rightArrow} onClick={handlePageForward} />
			</div>
		</div>
	);
}
