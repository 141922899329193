// utils.js


// get aws paths for event images

export const getEventImagesPath = (eventFolder, eventCommunity, imageCount) => {

	const basePath = `https://okb-images-s3-bucket.s3.amazonaws.com/Event-Images/`
	const fullPath = basePath + `${eventFolder}/${eventCommunity}/`

	const eventImages = [];
	for (let i = 1; i <= imageCount; i++) {
		const path = fullPath + `${i}.jpg`;
		eventImages.push(path);
	}
	return eventImages;
};


// keep adding a wellness club event to the list of events
// will occur every friday
export const addWellnessClubEvent = (commonData) => {
	const eventFolder = "HealthOutreachImages";

	// get the date for upcoming friday
	const today = new Date();
	const day = today.getDay();
	const daysUntilFriday = 5 - day;
	const nextFriday = new Date(today);
	nextFriday.setDate(today.getDate() + daysUntilFriday);
	const nextFridayString = nextFriday.toLocaleDateString("en-US");

	const event = {
		...commonData,
		title: "Wohohiame Wellness Club",
		description: [
			"The Wohohiame Wellness Club is dedicated to promoting holistic health and well-being among students, with a special focus on mental health. Created to provide ongoing education and resources, the club empowers youth to take charge of their physical, mental, and emotional well-being through interactive discussions, workshops, and peer support. By fostering a safe and nurturing environment, Wohohiame Wellness Club equips students with the knowledge and tools to navigate life's challenges, prioritize their health, and build stronger, healthier communities.",
		],
		eventStartTime: "4pm",
		eventEndTime: "5pm",
		id: "ho7",
		details: "",
		location: "Asanteman Senior High School, Kumasi, Ghana",
		applyLink: "",
		eventStartDate: nextFridayString,
		eventEndDate: nextFridayString,
		images: getEventImagesPath(eventFolder, "Wellness+Club", 6),
		eventImage: getEventImagesPath(eventFolder, "Wellness+Club", 1),
		visible: true,
	};
	return event;
};


// title: "Health Outreach",
// applyLink: "",
// componentLink: "healthOutreachEvent",
// eventImage: healthOutreachImage,
// description: [
// 	"The Hope Health Initiative is dedicated to delivering personalized consultation, health education, diagnostics, and medications to rural and underserved communities. Our mission is to ensure that individuals in these areas receive timely and quality healthcare. By bringing essential health services directly to those in need, we aim to bridge the gap in healthcare access and improve the overall well-being of these communities.",
// ],
