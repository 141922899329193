// other event
// src/components/EventsPage.js
import React, { useState } from "react";
import EventsCard from "./EventsCard/index.js";
import EventsData from "../../../Data/EventsData/EventsData.js";
import "../EventsPage.css";

export default function EventsCalendar() {
	const [loadEventsData, setLoadEventsData] = useState(EventsData.upcoming);
	const [active, setActive] = useState("upcoming");

	const onClickUpcoming = () => {
		setActive("upcoming");
		setLoadEventsData(EventsData.upcoming);
	};
	const onClickPast = () => {
		setActive("past");
		setLoadEventsData(EventsData.past);
	};

	return (
		<div className="events-page">
			<h1 className="events-page-header">
				<div
					onClick={onClickUpcoming}
					className={`${active == "upcoming" && "active"} hover`}
				>
					Upcoming
				</div>
				<div
					onClick={onClickPast}
					className={`mleft20 ${active == "past" && "active"} hover`}
				>
					Past
				</div>
			</h1>

			<div className="event-list">
				{loadEventsData.length > 0 ? (
					loadEventsData.map((event) => (
						<EventsCard key={event.id} event={event} />
					))
				) : (
					<div className="no-events">{`No ${active} events found.`}</div>
				)}
			</div>
		</div>
	);
}
