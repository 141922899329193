// imports
import externalLinkIconPath from "../../Assets/Icons/external-link-1.png";

// external link icon
export const externalLinkIcon = {
  imageMainCss: "wohohiame-link-icon",
  imageMobileCss: "wohohiame-link-icon-mobile",
  imagePath: externalLinkIconPath,
};

//main
/**
 * @description NavBarData is an array of objects that contains the links hidden/shown for the main Navbar, mobile Navbar, and Footer.
 * mobileNavbarCss = to show on mobile navbar, null >> not shown
 * footerCss = to show on footer, null >> not shown
 * isVisible = to hide/show links on ALL navbars
 */
export const NavBarData = [
  {
    id: 1,
    title: "Home",
    externalLink: null,
    path: "/",
    navbarCss: null,
    footerCss: "footer-link",
    mobileNavbarCss: "empty-link offcanvas-link",
    isVisible: true,
  },
  {
    id: 2,
    title: "Who We Are",
    externalLink: null,
    path: "/who-we-are",
    navbarCss: "empty-link",
    footerCss: "footer-link",
    mobileNavbarCss: "empty-link offcanvas-link",
    isVisible: true,
  },
  {
    id: 3,
    title: "What We Do",
    externalLink: null,
    path: "/what-we-do",
    navbarCss: "empty-link",
    footerCss: "footer-link",
    mobileNavbarCss: "empty-link offcanvas-link",
    isVisible: true,
  },
  {
    id: 4,
    title: "Our Impact",
    externalLink: null,
    path: "/our-impact",
    navbarCss: "empty-link",
    footerCss: "footer-link",
    mobileNavbarCss: "empty-link offcanvas-link",
    isVisible: true,
  },
  {
    id: 5,
    title: "Work With Us",
    externalLink: null,
    path: "/work-with-us",
    navbarCss: "empty-link",
    footerCss: "footer-link",
    mobileNavbarCss: "empty-link offcanvas-link",
    isVisible: true,
  },
  {
    id: 6,
    title: "News",
    externalLink: null,
    path: "/news",
    navbarCss: "empty-link",
    footerCss: "footer-link",
    mobileNavbarCss: "empty-link offcanvas-link",
    isVisible: true,
  },
  {
    id: 7,
    title: "Wohohiame",
    externalLink: externalLinkIcon,
    path: "https://www.wohohiame.org/",
    navbarCss: "empty-link",
    footerCss: "footer-link",
    mobileNavbarCss: "empty-link offcanvas-link",
    isVisible: true,
  },
  {
    id: 8,
    title: "Events",
    externalLink: null,
    path: "/events",
    footerCss: "footer-link",
    navbarCss: null,
    mobileNavbarCss: "empty-link offcanvas-link",
    isVisible: true,
  },
  {
    id: 9,
    title: "Donate",
    externalLink: null,
    path: "/donate",
    footerCss: "footer-link",
    navbarCss: null,
    mobileNavbarCss: "empty-link offcanvas-link",
    isVisible: true,
  },
  {
    id: 10,
    title: "Volunteer in Ghana",
    externalLink: null,
    path: "/volunteers",
    footerCss: "footer-link",
    navbarCss: null,
    mobileNavbarCss: null,
    isVisible: true,
  },
  {
    id: 11,
    title: "Internship Programs",
    externalLink: null,
    path: "/internships",
    footerCss: "footer-link",
    navbarCss: null,
    mobileNavbarCss: null,
    isVisible: true,
  },
  {
    id: 12,
    title: "Store",
    externalLink: null,
    path: "/store",
    footerCss: "footer-link",
    navbarCss: "empty-link",
    mobileNavbarCss: null,
    isVisible: false,
  },
];

// for externalLink links
// target="_blank"
