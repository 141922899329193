//who we are page

//imports
import "./WohohiamePage.css";

//main
export default function WohohiamePage() {
	//return
	return (
		<div>
			<div className="home-header-outer">
				<div className="home-header">
					<div className="home-title">Wohohiame</div>

					<div className="home-main-buttons">
						<div className="home-donation button">Donate</div>
						<div className="home-join">Join Our Team</div>
					</div>
				</div>
			</div>

			<div></div>
		</div>
	);
}
