/**
 * @typedef InternshipsData
 * @type {Array<Object>}
 * @memberOf WorkWithUsData
 * @namespace InternshipsData
 * @description Data for Internship Programs
 * @property id
 * @property title
 * @property text
 * @property location1
 * @property location2
 * @property applyLink - app.js link
 * @property src - flipcause
 * @property roleOverview - Array<string>
 * @property keyResponsibilities - Object with key = int or string, value = Array<string>
 * @property qualifications - Array<string>
 * @property finalNote - Array<string>
 */
export const InternshipsData = [
  {
    id: 1,
    title: "Experience Ghana Internship Program",
    text: "Student or Healthcare Professional",
    location1: "Ghana",
    location2: "Africa",
    src: "MTE3Njc3",
    roleOverview: [
      "This program provides students with the opportunity to gain real-world experience on healthcare delivery in rural and underserved communities.",
    ],
    keyResponsibilities: {
      1: [
        "Participate in medical outreaches in various rural and underserved communities.",
      ],
      2: [
        "Develop educational materials for the communities and the inidividuals we serve.",
      ],
      3: [
        "Participate in our mental wellness initiatives across various high schools.",
      ],
      4: [
        "Shadow healthcare providers to learn how healthcare is delivered in hospitals.",
      ],
      5: [
        "Witness healthcare delivery in low-resource settings (i.e., rural and underserved communities) and major hospitals.",
      ],
      6: ["Gain practical experience in organizing medical screenings."],
      7: ["Understand Ghana's healthcare structure."],
      8: ["Immerse themselves in the rich culture."],
    },
    qualifications: [
      "Program is self-funded by participants and students. (Most students secured funding from their respective universities.)",
      "Funding covers housing, meals, in-country transportation, and miscellaneous expenses.",
    ],
    finalNote: [
      "Your dedication to making a difference through health outreaches in rural and underserved communities is instrumental in advancing our organization's impact. Thank you for joining our team!",
    ],
  },
];
