// imports
import { HealthOutreachData } from "./HealthOutreach/HealthOutreachData";
import { SummerInternshipData } from "./SummerIntership/SummerInternshipData";

//main
/**
 * eventDate: "MM/DD/YYYY"
 * eventStartTime: "HH:MM" + ("am" OR "pm")
 */
const MainEventsData = [
	// mock data - data contained in other folders by section in this directory
	{
		id: "1",
		title: "Fundraising",
		details: "",
		location: "",
		applyLink: "",
		componentLink: "",
		eventStartDate: "07/21/2024",
		eventEndDate: "07/21/2024",
		eventStartTime: "10:30am",
		eventEndTime: "10:30am",
		description: [""],
		visible: false,
	},
	{
		id: "2",
		title: "Case Competitions",
		details: "",
		location: "",
		applyLink: "",
		componentLink: "caseCompetitions",
		eventStartDate: "05/15/2024",
		eventEndDate: "05/15/2024",
		eventStartTime: "10:30am",
		eventEndTime: "10:30am",
		description: [""],
		visible: false,
	},
	{
		id: "3",
		title: "Mental Health Outreach",
		details: "",
		location: "",
		applyLink: "",
		videoLink:
			"https://www.youtube.com/embed/vmSSvV_ahG8?si=JcmeFrEPoMA6RhY0",
		componentLink: "",
		eventStartDate: "10/01/2024",
		eventEndDate: "10/01/2024",
		eventStartTime: "10:30am",
		eventEndTime: "10:30am",
		description: [""],
		visible: false,
	},
];

// filter and sort data - upcoming events
const sortUpcomingEventsData = (eventsData) => {
	// show events that have passed up to 1 week ago
	let currentDate = new Date();
	currentDate.setDate(currentDate.getDate() - 1);
	const upcomingEvents = eventsData.filter((event) => {
		const eventDateTime = new Date(event.eventStartDate);
		// Check if event date is in the future or within 1 week ago
		return (
			eventDateTime > currentDate ||
			(eventDateTime.toDateString() === currentDate.toDateString() &&
				eventDateTime > currentDate)
		);
	});

	// sort events chronologically
	const sortedEventsData = upcomingEvents.sort((a, b) => {
		// Parse event dates
		const dateA = new Date(a.eventStartDate);
		const dateB = new Date(b.eventStartDate);

		// Compare event dates
		const dateComparison = dateA - dateB;

		// If event dates are the same, compare event times
		if (dateComparison === 0) {
			// Parse event times
			const timeA = new Date("1970-01-01T" + a.eventStartTime);
			const timeB = new Date("1970-01-01T" + b.eventStartTime);

			// chronological order
			return timeA - timeB;
		}

		return dateComparison;
	});

	return sortedEventsData;
};

// filter and sort data - past events
const sortPastEventsData = (eventsData) => {
	// show events that have passed up to 1 week ago
	let currentDate = new Date();
	currentDate.setDate(currentDate.getDate());
	const pastEvents = eventsData.filter((event) => {
		const eventDateTime = new Date(event.eventStartDate);
		// Check if event date is in the future or within 1 week ago
		return (
			eventDateTime <= currentDate ||
			(eventDateTime.toDateString() === currentDate.toDateString() &&
				eventDateTime <= currentDate)
		);
	});

	// sort events chronologically
	const sortedEventsData = pastEvents.sort((a, b) => {
		// Parse event dates
		const dateA = new Date(a.eventStartDate);
		const dateB = new Date(b.eventStartDate);

		// reverse chronological order
		const dateComparison = dateB - dateA;

		// If event dates are the same, compare event times
		if (dateComparison === 0) {
			// Parse event times
			const timeA = new Date("1970-01-01T" + a.eventStartTime);
			const timeB = new Date("1970-01-01T" + b.eventStartTime);

			// Compare event times
			return timeA - timeB;
		}

		return dateComparison;
	});

	return sortedEventsData;
};

// add events from all data files to 1 list
const addAllEvents = () => {
	if (SummerInternshipData.length > 0) {
		const datalength = SummerInternshipData.length;

		for (let i = 0; i < datalength; i++) {
			const event = SummerInternshipData[i];
			MainEventsData.push(event);
		}
	}
	if (HealthOutreachData.length > 0) {
		const datalength = HealthOutreachData.length;

		for (let j = 0; j < datalength; j++) {
			const event = HealthOutreachData[j];
			MainEventsData.push(event);
		}
	}

	const visibleEvents = MainEventsData.filter(
		(event) => event.title && event.visible
	);

	// return past and upcoming events
	const sortedPastEventsData = sortPastEventsData(visibleEvents);
	const sortedUpcomingEventsData = sortUpcomingEventsData(visibleEvents);

	return {
		past: sortedPastEventsData,
		upcoming: sortedUpcomingEventsData,
	};
	// return sortedPastEventsData;
};

const EventsData = addAllEvents();
export default EventsData;
