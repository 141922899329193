//imports
import { useState } from "react";
import { NavLink } from "react-router-dom";
import { NavBarData } from "../../Data/NavBarData/NavBarData";
import logo from "../../Assets/Home/original-okb-logo.png";
import menu from "../../Assets/Icons/menu-1.png";
import "./NavBar.css";

//main
export default function NavBar() {
	// eslint-disable-next-line no-unused-vars
	const [isOpen, setIsOpen] = useState(false);
	const closeNavbar = () => {
		"#navbarModal".offcanvas("hide"); // Use Bootstrap's offcanvas('hide') method to close the menu
	};

	// eslint-disable-next-line no-unused-vars
	let isMobile = false;
	// optional: external link icon
	const externalLink = (data, isMobile) => {
		if (data.externalLink !== null) {
			const { imagePath, imageMainCss, imageMobileCss } =
				data.externalLink;
			return (
				<img
					src={imagePath}
					alt={`External Link to ${data.title}`}
					className={isMobile ? imageMobileCss : imageMainCss}
				/>
			);
		} else {
			return null;
		}
	};

	//return
	return (
		<>
			<div className="okb-title">OKB HOPE FOUNDATION</div>

			<div className="sticky">
				<div className="nav">
					<NavLink className="logo-container" to="/">
						<img
							src={logo}
							alt="OKB Hope Foundation logo"
							className="nav-logo"
						/>
					</NavLink>

					<div className="nav-links-container">
						<li className={`nav-item dropdown close-menu`}>
							<a
								className="nav-link"
								aria-expanded="false"
								href="#navbarModal"
								role="button"
								aria-controls="navbarModal"
								data-bs-toggle="offcanvas"
								// data-bs-toggle="dropdown"
								data-bs-target="#navbarModal"
							>
								<img
									className="hamburger-menu-icon"
									src={menu}
									alt="Menu Options"
								/>
							</a>
						</li>

						{/* mobile navbar */}
						<div
							className={`offcanvas offcanvas-end ${
								isOpen ? "show" : ""
							}`}
							tabIndex="-1"
							id="navbarModal"
							aria-labelledby="offcanvasLabel"
						>
							<div className="offcanvas-body">
								{NavBarData &&
									NavBarData.map(
										(data, index) =>
											data.isVisible &&
											data.mobileNavbarCss && (
												<NavLink
													onClick={
														!data.externalLink
															? closeNavbar
															: null
													}
													className={
														data.mobileNavbarCss
													}
													to={data.path}
													key={index}
													target={
														data.externalLink
															? "_blank"
															: null
													}
												>
													{data.title}
													{externalLink(
														data,
														(isMobile = true)
													)}
												</NavLink>
											)
									)}
							</div>
						</div>

						{/* main navbar */}
						<div className={`close-nav`}>
							{NavBarData &&
								NavBarData.map(
									(data, index) =>
										data.isVisible &&
										data.navbarCss && (
											<NavLink
												className={data.navbarCss}
												to={data.path}
												key={index}
												target={
													data.externalLink
														? "_blank"
														: null
												}
											>
												{data.title}
												{externalLink(
													data,
													(isMobile = false)
												)}
											</NavLink>
										)
								)}
						</div>
					</div>

					<div className="nav-buttons-container">
						{/* <NavLink

							to="booking"
							className="nav-booking-button mright10 empty-link-white"
						>
							Booking
						</NavLink> */}

						<NavLink
							to="/events"
							className="nav-booking-button mright10 empty-link-white"
						>
							Events
						</NavLink>

						<NavLink
							to="donate"
							className="empty-link-white nav-donate-button"
						>
							Donate
						</NavLink>
					</div>
				</div>
			</div>
		</>
	);
}
