// imports
import "../Donate.css";
import FlipcauseHopeHealthInitiatives from "./FlipcauseHopeHealthInitiatives";
import ReactGA from "react-ga";

// main
export default function DonateHopeHealthInitiatve() {
  // Track page view when the component mounts
  ReactGA.pageview(window.location.pathname + window.location.search);

  // return
  return (
    <div className="large-screen">
      <div className="home-header-outer">
        <div className="donate-header">
          <div className="donate-header-text">
            Make a difference for{" "}
            <p className="mleft20 mtop5">Hope Health Initiative</p>
          </div>
        </div>
      </div>
      <FlipcauseHopeHealthInitiatives />
      <div className="donate-title">
        <div className="donate-title-left">How Your Donation Helps</div>

        <div className="donate-title-right">
          <div className="donate-text">
            Brings medical supplies for health screenings.
          </div>

          <div className="donate-text">Empowers patients throughout Ghana.</div>

          <div className="donate-text">
            Initiates projects that better the lives of communities.
          </div>

          <div className="donate-text">
            Provides health education to the people of Ghana.
          </div>
        </div>
      </div>
    </div>
  );
}
