//imports
import "../Booking.css";

//main
export default function SelectedOptions({ selectedOptions, total, service }) {
	return (
		<div className="selected-labs">
			<div className="selected-header-container">
				<div className="selected-labs-header">**Selected Services</div>

				<div className="totalPrice">{`*Total = GH₵ ${total.toFixed(2)}`}</div>
			</div>

			{selectedOptions.length > 0 &&
				selectedOptions.map((test, i) => (
					<div className="selected-container" key={i}>
						<div className="selectedTestName">{`• ${test}`}</div>
					</div>
				))}
			<div className="prices-est">*Prices are estimates</div>
			<div className="okb-notes-container">
				**Our lab tests are performed in our clinic located in Kumasi:
				Tech Junction Behind Juliponia Pharmacy
			</div>
			<div className="okb-notes-container">
				*** Need Help? If you are confused about booking the service you
				need, please call us at:
				<span className="phone-number">+233 530 772 603</span>
			</div>
		</div>
	);
}
