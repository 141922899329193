//imports
import { NavLink } from "react-router-dom";
import "./Footer.css";
import instagram from "../../Assets/Icons/insta.svg";
import facebook from "../../Assets/Icons/fb.svg";
import twitter from "../../Assets/Icons/twitter.svg";
import linkedin from "../../Assets/Icons/linkedin.svg";
import mail from "../../Assets/Icons/mail.svg";
import youtube from "../../Assets/Icons/youtube-3.png";
import MailChimp from "./MailChimp";
import { NavBarData } from "../../Data/NavBarData/NavBarData";

//main
export default function Footer() {
  const email = "oseib@okbfoundation.org";

  // eslint-disable-next-line no-unused-vars
  let isMobile = false;
  // optional: external link icon
  const externalLink = (data, isMobile) => {
    if (data.externalLink !== null) {
      const { imagePath, imageMainCss, imageMobileCss } = data.externalLink;
      return (
        <img
          src={imagePath}
          alt={`External Link to ${data.title}`}
          className={isMobile ? imageMobileCss : imageMainCss}
        />
      );
    } else {
      return null;
    }
  };

  //return
  return (
    <div className="footer-background">
      <div className="footer-container">
        <div className="footer-header">
          <div className="footer-left">
            <div className="social-links">
              <NavLink
                to="https://www.instagram.com/okbhopefoundation/"
                target="_blank"
                className="footer-social-link"
              >
                <img src={instagram} alt="instagram" />
              </NavLink>

              <NavLink
                to="https://www.facebook.com/OKBFOUNDATION"
                target="_blank"
                className="footer-social-link"
              >
                <img src={facebook} alt="facebook" />
              </NavLink>

              <NavLink
                to="https://twitter.com/OKBFoundation"
                target="_blank"
                className="footer-social-link"
              >
                <img src={twitter} alt="twitter" />
              </NavLink>

              <NavLink
                to="https://www.youtube.com/@okbhopefoundation1757"
                target="_blank"
                className="footer-social-link"
              >
                <img className="youtube-icon" src={youtube} alt="youtube" />
              </NavLink>

              <NavLink
                to="https://www.linkedin.com/company/okb-hope-foundation/"
                target="_blank"
                className="footer-social-link"
              >
                <img src={linkedin} alt="linkedin" />
              </NavLink>

              <div to="#" target="_blank" className="footer-social-link">
                <a href={`mailto:${email}`}>
                  <img src={mail} alt="mail" />
                </a>
              </div>
            </div>

            <div className="footer-join-us">
              Join us in our effort to increase access to healthcare across
              Ghana.
            </div>

            <MailChimp />

            <div className="footer-signup">
              Sign up for our newsletter to learn more about our upcoming
              projects and how you can get involved!
            </div>
          </div>

          <div className="footer-mid">
            <h3 className="footer-header-inner">Get Involved</h3>

            {NavBarData &&
              NavBarData.map(
                (data, index) =>
                  data.isVisible &&
                  data.footerCss && (
                    <NavLink
                      className={data.footerCss}
                      to={data.path}
                      key={index}
                      target={data.externalLink ? "_blank" : null}
                    >
                      {data.title}
                      {externalLink(data, (isMobile = false))}
                    </NavLink>
                  )
              )}
          </div>

          <div className="footer-right">
            <div className="footer-contacts">
              <h3 className="footer-contact-header">Contact</h3>
              <div className="footer-contact">9 South Grosser Place,</div>
              <div className="footer-contact">Somerset, NJ 08873</div>

              <a
                className="footer-contact"
                href="mailto:info@okbfoundation.org"
              >
                Email: info@okbfoundation.org
              </a>

              <div className="footer-contact">{`Phone: (732)-581-1906`}</div>
            </div>

            <h3 className="footer-header-inner">Partnerships</h3>

            <div className="footer-text">
              David M. Einhorn Center for Community Engagement
            </div>
            <div className="footer-text">
              Institute of African Development, Cornell University
            </div>
            <div className="footer-text">Komfo Anokye Teaching Hospital</div>
          </div>
        </div>

        <div className="footer-end">
          <div className="footer-end-part-one">
            Copyright © OKB Hope Foundation
          </div>

          <div className="footer-end-part-two">All Rights Reserved</div>

          <div className="footer-end-part-two">
            OKB is a 501(3) Tax-Exempt Nonprofit EIN: 85-2646352
          </div>
        </div>
      </div>
    </div>
  );
}
