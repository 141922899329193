//import)
import { useState } from "react";
import { NavLink } from "react-router-dom";
import "../Donate.css";
import arrow from "../../../Assets/Icons/arrow-1.png";
import emailjs from "emailjs-com";
import emails from "./sendEmail";

//main
export default function DonateEmailForm() {
	// states
	const [name, setName] = useState("");
	const [organization, setOrganization] = useState("");
	const [phone, setPhone] = useState("");
	const [email, setEmail] = useState("");
	const [date, setDate] = useState("");
	const [request, setRequest] = useState("");
	const [showSuccess, setShowSuccess] = useState(0);
	const [loaded, setLoaded] = useState(true);

	const sendEmail = (e) => {
		e.preventDefault();
		setLoaded(false);

		emailjs
			.sendForm(
				emails("service"),
				emails("template"),
				e.target,
				emails("public")
			)
			.then(
				(result) => {
					console.log("Successfully submitted form!");
					setLoaded(true);
					setShowSuccess(1);
					// window.location.reload(); //reload page after submit
				},
				(error) => {
					console.log(error.text);
					setLoaded(true);
					setShowSuccess(2);
				}
			);
	};

	// return
	return (
		<>
			<div className="labs-container">
				{/* {success ? (
					<Alert type="success" message="Your request has been submitted successfully!" dismissible />
				) : (
					errors.length > 0 && errors.map(error => (
						<Alert type="danger" message={error} dismissible />
					))
				)} */}

				<h2 className="booking-patients-header">Donate to OKB Foundation</h2>

				<NavLink
					className="what-learn-more cancel-appt-button"
					to="/donate"
				>
					<img
						src={arrow}
						alt="go to previous page"
						className="arrow-icon"
					/>
				</NavLink>

				<div className="booking-hint">
					The Donation Request Form below is for donors to submit a request to donate items other than money.
				</div>
				<div className="booking-hint">
					Please fill out all fields to help us process your donation request.
				</div>

				<div className="group-health-form">
					{showSuccess === 1 ? (
						<>
							<div className="alert alert-success" role="alert">
								Donation Request Successfully Submitted!
							</div>
						</>
					) : showSuccess === 2 ? (
						<>
							<div className="alert alert-success" role="alert">
              Donation Request Successfully Submitted!
							</div>
						</>
					) : (
						<form onSubmit={sendEmail}>
							<div className="group-form-div">
								<label className="group-form-label" for="name">
									Name *
								</label>
								<input
									type="text"
									id="name"
									name="name"
									onChange={(el) => setName(el)}
									required
								/>
							</div>

							<div className="group-form-div">
								<label
									className="group-form-label"
									for="organization"
								>
									Organization
								</label>
								<input
									type="text"
									id="organization"
									name="organization"
									onChange={(el) => setOrganization(el)}
								/>
							</div>

							<div className="group-form-div">
								<label className="group-form-label" for="phone">
									Phone Number *
								</label>
								<input
									type="tel"
									id="phone"
									name="phone"
									required
									onChange={(el) => setPhone(el)}
								/>
							</div>

							<div className="group-form-div">
								<label className="group-form-label" for="email">
									Email *
								</label>
								<input
									type="email"
									id="email"
									name="email"
									required
									onChange={(el) => setEmail(el)}
								/>
							</div>

							<div className="group-form-div">
								<label
									className="group-form-label"
									for="request"
								>
									Request *
								</label>
								<textarea
									id="request"
									name="request"
									rows="4"
									cols="50"
									onChange={(el) => setRequest(el)}
									required
								></textarea>
							</div>

							<div className="labs-buttons">
								{loaded ? (
									<button
										className="button group-form-button"
										type="submit"
									>
										Submit
									</button>
								) : (
									<button className="button group-form-button">
										Loading
									</button>
								)}
							</div>
						</form>
					)}
				</div>
			</div>
		</>
	);
}
