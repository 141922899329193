// imports
import "./OurTeam.css";
import linkedinIcon from "../../../Assets/Icons/linkedin-faded.svg";
import { NavLink } from "react-router-dom";
import link from "../../../Assets/Icons/info-2.png";

// main
export default function OurTeamSection({ selectedSectionData }) {
  return (
    <>
      <div className="booking-body-section-three">
        {selectedSectionData &&
          selectedSectionData.length > 0 &&
          selectedSectionData.slice(0, 4).map((la, j) => (
            <div className="donate-person-container" key={j}>
              <img
                src={la.image}
                alt="healthcare volunteers"
                className="donate-person-image"
              />
              <div className="donate-person-info">
                <div className="donate-person-name">{la.name}</div>
                <div className="donate-person-position">{la.position}</div>
              </div>
              <div className="who-team-link">
                {la.linkedin && (
                  <NavLink to={la.linkedin} target="_blank">
                    <img
                      className="team-linkedin-icon"
                      src={linkedinIcon}
                      alt="linkedin"
                    />
                  </NavLink>
                )}

                {la.description && la.description.length > 0 && (
                  <NavLink to={`/mbr/${la.id}`}>
                    <img
                      className="team-info-icon"
                      src={link}
                      alt="Description for Team Member"
                    />
                  </NavLink>
                )}
              </div>
            </div>
          ))}
      </div>

      <div className="booking-body-section-three">
        {selectedSectionData &&
          selectedSectionData.length > 4 &&
          selectedSectionData.slice(4, 8).map((la, j) => (
            <div className="donate-person-container" key={j}>
              <img
                src={la.image}
                alt="healthcare volunteers"
                className="donate-person-image"
              />
              <div className="donate-person-info">
                <div className="donate-person-name">{la.name}</div>
                <div className="donate-person-position">{la.position}</div>
              </div>
              <div className="who-team-link">
                {la.linkedin && (
                  <NavLink to={la.linkedin} target="_blank">
                    <img
                      className="team-linkedin-icon"
                      src={linkedinIcon}
                      alt="linkedin"
                    />
                  </NavLink>
                )}

                {la.description && la.description.length > 0 && (
                  <NavLink to={`/mbr/${la.id}`}>
                    <img
                      className="team-info-icon"
                      src={link}
                      alt="Description for Team Member"
                    />
                  </NavLink>
                )}
              </div>
            </div>
          ))}
      </div>

      <div className="booking-body-section-three">
        {selectedSectionData &&
          selectedSectionData.length > 8 &&
          selectedSectionData.slice(8, 12).map((la, j) => (
            <div className="donate-person-container" key={j}>
              <img
                src={la.image}
                alt="healthcare volunteers"
                className="donate-person-image"
              />
              <div className="donate-person-info">
                <div className="donate-person-name">{la.name}</div>
                <div className="donate-person-position">{la.position}</div>
              </div>
              <div className="who-team-link">
                {la.linkedin && (
                  <NavLink to={la.linkedin} target="_blank">
                    <img
                      className="team-linkedin-icon"
                      src={linkedinIcon}
                      alt="linkedin"
                    />
                  </NavLink>
                )}

                {la.description && la.description.length > 0 && (
                  <NavLink to={`/mbr/${la.id}`}>
                    <img
                      className="team-info-icon"
                      src={link}
                      alt="Description for Team Member"
                    />
                  </NavLink>
                )}
              </div>
            </div>
          ))}
      </div>
    </>
  );
}
