// EventsCard
import "../../EventsPage.css";
import share from "../../../../Assets/Icons/external-link-1.png";
import { NavLink } from "react-router-dom";

const tempImage =
	"https://media.istockphoto.com/id/1357365823/vector/default-image-icon-vector-missing-picture-page-for-website-design-or-mobile-app-no-photo.jpg?s=612x612&w=0&k=20&c=PM_optEhHBTZkuJQLlCjLz-v3zzxp-1mpNQZsdjrbns=";

export default function EventsCard({ event }) {
	const {
		id,
		title,
		details,
		description,
		location,
		dates,
		applyLink,
		componentLink,
		eventStartDate,
		eventStartTime,
		eventImage,
	} = event;

	// format date
	let date = new Date(eventStartDate).toString().split(" ");
	let weekday = date[0];
	let month = date[1];
	let day = date[2];
	let year = date[3];
	let time = eventStartTime.split(":").splice(0, 2).join(":");
	let ampm = time.slice(time.length - 2);
	time = time.slice(0, time.length - 2);
	// format time
	if (time.length < 3) {
		if (ampm === "pm") {
			time += ":00 PM GMT";
		} else {
			time += ":00 AM GMT";
		}
	} else if (ampm === "pm") {
		time += " PM GMT";
	} else {
		time += " AM GMT";
	}

	return (
		<NavLink to={`/events/${id}`}>
			<div className="event-card">
				<div className="detail-left">
					<img
						src={eventImage ? eventImage : tempImage}
						className="no-groups-img-read"
						alt="temporary"
					/>
				</div>

				<div className="detail-right">
					<div className="about-section brown-text-small">{`${weekday}, ${month} ${day}, ${year} ${time}`}</div>
					<h3 className="detail-right-name">
						{title + " " + details}
					</h3>

					{location && location !== "" ? (
						<div className="about-section">{location}</div>
					) : (
						<div className="online-only text-align small-index-font">
							{`Online Only`}
						</div>
					)}
				</div>
			</div>
		</NavLink>
	);
}
