// events > event details > case competitions - custom page

//imports
import { NavLink } from "react-router-dom";
import "./CaseCompetitions.css";
import OurJudges from "./OurJudges";

//main
export default function CaseCompetitionsEvent() {
	//return
	return (
		<div className="large-screen">
			<div className="home-header-outer">
				<div className="case-competitions-header">
					<div className="impact-title shrink-long-title">Case Competitions</div>
				</div>
			</div>

			<div className="our-impact-body">
				<div className="what-body-section" id="case-comp-intro">
					<div className="what-section-left margins-auto">
						<h2 className="what-section-left-header">
							International Case Competition with Cornell
							University
						</h2>

						<div className="what-section-text">
							<div className="see-how-we-do event-text">
								The Cornell Healthcare Student Association,
								Cornell Institute of African Development, and
								OKB Hope Foundation are giving innovative
								students an opportunity to put their classroom
								skills into practice. Students from different
								fields of study, universities, and countries can
								bring their unique perspectives together, using
								bright minds to design our future.
							</div>

							<div className="see-how-we-do event-text">
								If you're an individual who's driven by the need
								to make a positive impact in the lives of others
								while developing your own skill set, then this
								is the competition for you. With prestigious
								judges and prominent cash prizes, there's
								something for everyone to take away!
							</div>

							<div className="see-how-we-do event-text">
								Winning teams will work with us to implement
								their solutions in rural Ghanaian communities!
							</div>

							<div className="see-how-we-do event-text">
								This case competition is being sponsored by
								Institute of African Development at Cornell
								University.
							</div>
						</div>
						<div className="learn-more-container mleft25vw">
							<NavLink
								className="learn-more-button"
								to="https://docs.google.com/forms/d/e/1FAIpQLSdsqF97VFQRX0OJaExvuFmRhLoz71Jhup4T3QZ9Duhe_5dEJw/viewform"
								target="_blank"
							>
								Apply
							</NavLink>
						</div>
					</div>

					<div
						className="what-section-right"
						id="case-comp-section-right"
					>
						<iframe
							className="event-video"
							id="case-comp-vid"
							src="https://www.youtube.com/embed/hs6cfr6uFDY"
							title="YouTube video player"
							allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
							allowFullScreen
						></iframe>
					</div>
				</div>

				<div className="event-info-header-container">
					<div className="event-info-header">General Info</div>
				</div>

				<div className="margin-bottom-prizes">
					<div className="event-info-text">
						<div className="event-text-left">
							Registration Opens
						</div>
						<div className="event-text-right">
							<div>Wednesday, 16th August</div>
							<div>10:00am EST</div>
						</div>
					</div>
					<div className="event-info-text">
						<div className="event-text-left">
							Registration Closes
						</div>
						<div className="event-text-right">
							<div>Wednesday, 30th August</div>
							<div>11:59pm EST</div>
						</div>
					</div>

					<div className="event-info-text">
						<div className="event-text-left">Teams Announced</div>
						<div className="event-text-right">
							<div>Tuesday, 5th September</div>
							<div>10:00am EST</div>
						</div>
					</div>

					<div className="event-info-text">
						<div className="event-text-left">Case Released</div>
						<div className="event-text-right">
							<div>Friday, 8th September</div>
							<div>10:00am EST</div>
						</div>
					</div>

					<div className="event-info-header-container">
						<div className="event-info-header">Info Sessions</div>
					</div>

					<div className="event-info-text">
						<div className="event-text-left">Info Session 1</div>
						<div className="event-text-right">
							<div>Wednesday, 23rd August</div>
							<div>6:00pm - 7:00pm EST</div>
						</div>
					</div>
					<div className="event-info-text">
						<div className="event-text-left">Info Session 2</div>
						<div className="event-text-right">
							<div>Saturday, 26th August</div>
							<div>10:00am - 11:00am EST</div>
						</div>
					</div>
					<div className="event-info-text">
						<div className="event-text-left">Info Session 3</div>
						<div className="event-text-right">
							<div>Monday, 28th August</div>
							<div>4:00pm - 5:00pm EST</div>
						</div>
					</div>

					<div className="event-info-header-container">
						<div className="event-info-header">Due Dates</div>
					</div>

					<div className="event-info-text">
						<div className="event-text-left">
							Executive Summary Due
						</div>
						<div className="event-text-right">
							<div>Monday, 18th September</div>
							<div>11:59pm EST</div>
						</div>
					</div>

					<div className="event-info-text">
						<div className="event-text-left">
							Final Presentations Due
						</div>
						<div className="event-text-right">
							<div>Thursday, 21st September</div>
							<div>11:59pm EST</div>
						</div>
					</div>

					<div className="event-info-text">
						<div className="event-text-left">
							Live Presentations
						</div>
						<div className="event-text-right">
							<div>Saturday, 23rd September</div>
							<div>10:00am EST</div>
						</div>
					</div>

					<div className="event-info-text">
						<div className="event-text-left">
							Final Round and Awards Ceremony
						</div>
						<div className="event-text-right">
							<div>Saturday, 30th September</div>
							<div>10:00am EST</div>
						</div>
					</div>
				</div>

				<div className="cc-prizes" id="our-judges">
					<div className="team-header-title nopointer">Prizes</div>
				</div>

				<div className="impact-section-two" id="margin-top-none-mobile">
					<div className="impact-two-left">
						<div className="sdg-container">
							<div className="sdg-current-container" id="sdg-1">
								<div className="sdg-current-value">{`$2000`}</div>
								<div className="people-helped">
									1st Place Prize
								</div>
							</div>

							<div className="sdg-current-container" id="sdg-2">
								<div className="sdg-current-value">{`$1500`}</div>
								<div className="people-helped">
									2nd Place Prize
								</div>
							</div>

							<div className="sdg-current-container" id="sdg-3">
								<div className="sdg-current-value">{`$1000`}</div>
								<div className="people-helped">
									3rd Place Prize
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="home-stories-header" id="our-judges">
					<div className="team-header-title nopointer">
						Meet Our Judges
					</div>
				</div>
				<OurJudges />
				<div className="break" />
			</div>
		</div>
	);
}
