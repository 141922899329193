// imports
import "./Store.css";

// main
export default function Store() {
	const storeData = [
		{
			name: "COFFEE MUG",
			price: "$2",
			image: "",
		},
		{
			name: "TOTE BAG",
			price: "$10",
			image: "",
		},
		{
			name: "BASEBALL CAP",
			price: "$10",
			image: "",
		},
		{
			name: "T-SHIRT",
			price: "$25",
			image: "",
		},
	];

	// return
	return (
		<div>
			<div className="home-header-outer">
				<div className="home-header">
					<div className="home-title">Collection</div>
				</div>
			</div>

			<div className="store-body">
				<h4 className="store-header">
					SUPPORT OKB HOPE FOUNDATION WITH YOUR PURCHASE
				</h4>

				<div className="store-item-container">
					{storeData.map((el, i) => (
						<div className="store-item-info" key={i}>
							<img
								src={el.image}
								alt="store item"
								className="store-item-image"
							/>

							<div className="store-item-text">
								<div className="store-item-name">{el.name}</div>
								<div className="store-item-price">
									{el.price}
								</div>
								<div className="store-purchase-button">
									PURCHASE
								</div>
							</div>
						</div>
					))}
				</div>
			</div>
		</div>
	);
}
