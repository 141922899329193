// imports
import "./OurJudges.css";
import { OurJudgesData } from "../../../../Data/EventsData/CaseCompetitions/OurJudgesData";
import linkedinIcon from "../../../../Assets/Icons/linkedin-faded.svg";
import { NavLink } from "react-router-dom";
import link from "../../../../Assets/Icons/info-2.png";

// main
export default function OurJudges() {
  // return
  return (
    <>
      <div className="booking-body-section-three">
        {OurJudgesData.judges.length > 0 &&
          OurJudgesData.judges.slice(0, 4).map((el, i) => (
            <div className="donate-person-container" key={i}>
              <img
                src={el.image}
                alt="healthcare volunteers"
                className="donate-person-image"
              />
              <div className="donate-person-info">
                <div className="donate-person-name">{el.name}</div>
                <div className="donate-person-position">{el.position}</div>
              </div>
              <div className="who-team-link">
                {el.linkedin && (
                  <NavLink to={el.linkedin} target="_blank">
                    <img
                      className="team-linkedin-icon"
                      src={linkedinIcon}
                      alt="linkedin"
                    />
                  </NavLink>
                )}

                <NavLink to={`/judge/${el.id}`}>
                  <img
                    className="team-info-icon"
                    src={link}
                    alt="Description for Team Member"
                  />
                </NavLink>
              </div>
            </div>
          ))}
      </div>

      <div className="booking-body-section-three">
        {OurJudgesData.judges.length > 4 &&
          OurJudgesData.judges.slice(4, 8).map((la, j) => (
            <div className="donate-person-container" key={j}>
              <img
                src={la.image}
                alt="healthcare volunteers"
                className="donate-person-image"
              />
              <div className="donate-person-info">
                <div className="donate-person-name">{la.name}</div>
                <div className="donate-person-position">{la.position}</div>
              </div>
              <div className="who-team-link">
                {la.linkedin && (
                  <NavLink to={la.linkedin} target="_blank">
                    <img
                      className="team-linkedin-icon"
                      src={linkedinIcon}
                      alt="linkedin"
                    />
                  </NavLink>
                )}

                <NavLink to={`/judge/${la.id}`}>
                  <img
                    className="team-info-icon"
                    src={link}
                    alt="Description for Team Member"
                  />
                </NavLink>
              </div>
            </div>
          ))}
      </div>

      <div className="booking-body-section-three">
        {OurJudgesData.judges.length > 8 &&
          OurJudgesData.judges.slice(8, 12).map((la, j) => (
            <div className="donate-person-container" key={j}>
              <img
                src={la.image}
                alt="healthcare volunteers"
                className="donate-person-image"
              />
              <div className="donate-person-info">
                {la.shortName ? (
                  <div className="donate-person-name">{la.shortName}</div>
                ) : (
                  <div className="donate-person-name">{la.name}</div>
                )}
                <div className="donate-person-position">{la.position}</div>
              </div>

              <div className="who-team-link">
                {la.linkedin && (
                  <NavLink to={la.linkedin} target="_blank">
                    <img
                      className="team-linkedin-icon"
                      src={linkedinIcon}
                      alt="linkedin"
                    />
                  </NavLink>
                )}

                <NavLink to={`/judge/${la.id}`}>
                  <img
                    className="team-info-icon"
                    src={link}
                    alt="Description for Team Member"
                  />
                </NavLink>
              </div>
            </div>
          ))}
      </div>
    </>
  );
}
