import pic1 from "../../../Assets/Home/okb-main-1.jpg";
import pic2 from "../../../Assets/Home/pic-1.jpg";
import pic3 from "../../../Assets/Home/pic-2.jpg";
import pic4 from "../../../Assets/Home/pic-3.png";
import { NavLink } from "react-router-dom";
import "./ImageCarousel.css";

const ImageCarousel = () => {
	return (
		<div
			id="carouselExampleAutoplaying"
			className="carousel slide home-header-outer"
			data-bs-ride="carousel"
		>
			<div className="carousel-inner">
				<div className="carousel-item active image-container">
					<img
						src={pic1}
						className="d-block w-100 home-header"
						alt="OKB Hope Foundation 1"
					/>
					<div className="overlay" />

					<div className="carousel-caption d-block">
						<div className="home-title">
							Transformative Healthcare
							<p>and Life Improvement</p>
						</div>

						<div className="home-main-buttons">
							<NavLink className="white-button" to="/donate">
								<div className="home-donation button mright10">
									DONATE
								</div>
							</NavLink>
							<NavLink to="/work-with-us">
								<div className="home-join">JOIN THE TEAM</div>
							</NavLink>
						</div>
					</div>
				</div>

				<div className="carousel-item image-container">
					<img
						src={pic4}
						className="d-block w-100 home-header"
						alt="OKB Hope Foundation 1"
					/>
					<div className="overlay" />

					<div className="carousel-caption d-block">
						<div className="home-title">
							Explore Our Latest
							<p>News and Blog</p>
						</div>

						{/* <div className="no-buttons-margin" /> */}

						<div className="home-main-buttons">
							<NavLink className="white-button" to="/news">
								<div className="home-donation button mright10">
									Read More
								</div>
							</NavLink>
						</div>
					</div>
				</div>

				<div className="carousel-item image-container">
					<img
						src={pic2}
						className="d-block w-100 home-header"
						alt="OKB Hope Foundation 1"
					/>
					<div className="overlay" />

					<div className="carousel-caption d-block">
						<div className="home-title">
							Empower Change<p>and Volunteer Today</p>
						</div>

						{/* <div className="no-buttons-margin" /> */}

						<div className="home-main-buttons">
							<NavLink className="white-button" to="/volunteers">
								<div className="home-donation button mright10">
									VOLUNTEER
								</div>
							</NavLink>
							<NavLink to="/internships">
								<div className="home-join">INTERNSHIPS</div>
							</NavLink>
						</div>
					</div>
				</div>
			</div>



			<button
				className="carousel-control-prev"
				type="button"
				data-bs-target="#carouselExampleAutoplaying"
				data-bs-slide="prev"
			>
				<span
					className="carousel-control-prev-icon"
					aria-hidden="true"
				></span>
				<span className="visually-hidden">Previous</span>
			</button>

			<button
				className="carousel-control-next"
				type="button"
				data-bs-target="#carouselExampleAutoplaying"
				data-bs-slide="next"
			>
				<span
					className="carousel-control-next-icon"
					aria-hidden="true"
				></span>
				<span className="visually-hidden">Next</span>
			</button>
		</div>
	);
};

export default ImageCarousel;
