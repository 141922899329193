//import
import { useState } from "react";
import Calendly from "../Calendly";
import { ServicesData } from "../ServicesData";
import { NavLink } from "react-router-dom";
import "../Booking.css";
import arrow from "../../../Assets/Icons/arrow-1.png";
import SelectedOptions from "../SelectedOptions";
import closeIcon from "../../../Assets/Icons/close.png";

//main
export default function LabServices() {
	// show calendly
	const [showCalendly, setShowCalendly] = useState(false);

	// selected options
	const [selectedOptions, setSelectedOptions] = useState([]);

	// service
	const service = ServicesData["labServices"];

	// labs
	const labs = Object.keys(service.options);

	// handle selections
	const handleSelection = (test) => {
		let selectedTest = test.target.innerText;
		if (!selectedOptions.includes(selectedTest)) {
			setSelectedOptions([...selectedOptions, test.target.innerText]);
		}
		if (selectedOptions.includes(selectedTest)) {
			selectedOptions.splice(selectedOptions.indexOf(selectedTest), 1);
			setSelectedOptions([...selectedOptions]);
		}
	};

	// prices
	const allLabs = Object.values(service.options);
	let total = 0;
	selectedOptions.forEach((curr) => {
		allLabs.forEach((el) => {
			let selectedPrice = el.find((la) => la[0] === curr);
			if (selectedPrice) {
				total += selectedPrice[1];
			}
		});
	});

	// ------------------ search ------------------

	const [searchTerm, setSearchTerm] = useState("");
	const handleChange = (e) => {
		setSearchTerm(e.target.value);
	};

	// -------------

	// return
	return (
		<>
			<div className="labs-container">
				<h2 className="booking-patients-header">Lab Services</h2>

				<div className="booking-hint">
					<span className="bold">Hint:</span> If you are unsure about
					which services you need, check your prescription or contact
					your doctor.
				</div>

				{!showCalendly && (
					<div className="booking-search">
						<form className="search-form">
							<input
								type="text"
								placeholder="Search..."
								value={searchTerm}
								onChange={handleChange}
								className="search-input"
							/>
							{/* <button type="submit">Search</button> */}
							<div
								onClick={() => setSearchTerm("")}
								className="cancel-search"
							>
								<img
									className="cancel-icon"
									src={closeIcon}
									alt="Cancel search"
								/>
							</div>
						</form>
					</div>
				)}

				{!showCalendly && (
					<NavLink
						className="what-learn-more cancel-appt-button"
						to="/booking"
					>
						<img
							src={arrow}
							alt="go to previous page"
							className="arrow-icon"
						/>
					</NavLink>
				)}

				{!showCalendly && (
					<>
						<div className="lab-container">
							{labs.slice(0, 2).map((lab, i) => (
								<div className="lab-section-container" key={i}>
									<h3 className="lab-header">{lab}</h3>

									<div className="tests-container">
										{service.options[lab].map(
											([test, price], j) => (
												<div
													className={`test-container ${
														selectedOptions.includes(
															test
														)
															? "selected"
															: ""
													} ${
														test
															.toLowerCase()
															.includes(
																searchTerm.toLowerCase()
															)
															? ""
															: "not-found"
													}`}
													key={j}
												>
													<div
														className="test-name"
														onClick={
															handleSelection
														}
													>
														{`${test}`}
													</div>

													<div className="test-price">
														{`GH₵ ${price.toFixed(
															2
														)}`}
													</div>
												</div>
											)
										)}
									</div>
								</div>
							))}
						</div>

						<div className="lab-container">
							{labs.slice(2, 4).map((lab, i) => (
								<div
									className="lab-section-container second-set"
									key={i}
								>
									<h3 className="lab-header">{lab}</h3>

									<div className="tests-container">
										{service.options[lab].map(
											([test, price], j) => (
												<div
													className={`test-container ${
														selectedOptions.includes(
															test
														)
															? "selected"
															: ""
													} ${
														test
															.toLowerCase()
															.includes(
																searchTerm.toLowerCase()
															)
															? ""
															: "not-found"
													}`}
													key={j}
												>
													<div
														className="test-name"
														onClick={
															handleSelection
														}
													>
														{`${test}`}
													</div>

													<div className="test-price">
														{`GH₵ ${price.toFixed(
															2
														)}`}
													</div>
												</div>
											)
										)}
									</div>
								</div>
							))}
						</div>
					</>
				)}

				<SelectedOptions
					selectedOptions={selectedOptions}
					total={total}
					service={service}
				/>

				{!showCalendly && (
					<div className="labs-buttons">
						<div
							className="button what-learn-more"
							onClick={() => setShowCalendly(!showCalendly)}
						>
							NEXT
						</div>

						<div className="blue-button what-learn-more mleft15">
							<NavLink className="white-button" to="/booking">
								CANCEL
							</NavLink>
						</div>
					</div>
				)}
			</div>

			{showCalendly && (
				<>
					<div className="calendly-header-container">
						<div
							className="what-learn-more cancel-appt-button"
							onClick={() => setShowCalendly(false)}
						>
							<img
								src={arrow}
								alt="go to previous page"
								className="arrow-icon"
							/>
						</div>

						<h2 className="calendly-header">Find an Appointment</h2>
					</div>

					<Calendly
						serviceName={"labServices"}
						buttonName={"BOOK LAB SERVICES"}
						selectedOptions={selectedOptions}
					/>
				</>
			)}
		</>
	);
}
