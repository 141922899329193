//who we are page

//imports
import "./WhoWeArePage.css";
import OurTeam from "./OurTeam";
import { NavLink } from "react-router-dom";
import stories7 from "../../Assets/WhoWeAre/who-we-are-11.png";
import stories8 from "../../Assets/WhoWeAre/who-we-are-12.png";
import stories9 from "../../Assets/WhoWeAre/who-we-are-13.png";
import { RecognitionData } from "../../Data/WhoWeAreData/RecognitionData";
import ReactGA from "react-ga";

//main
export default function WhoPage() {
	// Track page view when the component mounts
	ReactGA.pageview(window.location.pathname + window.location.search);

	//return
	return (
		<div className="large-screen">
			<div className="home-header-outer">
				<div className="who-header">
					<div className="who-title">Who We Are</div>
				</div>
			</div>

			<div className="who-stories-container">
				<div className="who-stories">
					<div className="video-container">
						<iframe
							className="who-stories-video"
							src="https://www.youtube.com/embed/rna_9-68Ny8"
							title="YouTube video player"
							allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
							allowFullScreen
						></iframe>
					</div>
					<div className="who-stories-title">
						A FOUNDATION OF EMPATHY
					</div>
					<div className="who-stories-text">
						We were founded on the principle of providing quality
						and affordable healthcare for all, regardless of
						socio-economic background.
					</div>
				</div>

				<div className="who-stories">
					<img
						src={stories9}
						className="who-stories-image"
						alt="who we are stories"
					/>

					<div className="who-stories-title">
						OUR VISION FOR GHANA
					</div>

					<div className="who-stories-text">
						To provide equitable, high quality healthcare,
						accessible to all Ghanaians, promoting overall
						well-being and prosperity to the nation.
					</div>
				</div>

				<div className="who-stories">
					<img
						src={stories7}
						className="who-stories-image"
						alt="who we are stories"
					/>

					<div className="who-stories-title">
						AN AMBITIOUS APPROACH
					</div>

					<div className="who-stories-text">
						We are transforming healthcare options in Ghana through
						education, research, and patient-centered care.
					</div>
				</div>

				<div className="who-stories">
					<img
						src={stories8}
						className="who-stories-image"
						alt="who we are stories"
					/>

					<div className="who-stories-title">A STRONG TEAM</div>

					<div className="who-stories-text">
						Our staff, students, volunteers, administrators, board
						members, and everyone else that impacts our
						organization, thank you!
					</div>
				</div>
			</div>

			<div className="home-stories-header large-screen-only">
				<div className="team-header-title mleft15 nopointer">
					Our Leadership Team
				</div>
			</div>

			<OurTeam />

			{/* remove recognition section below */}
			{/* <div className="who-recog">
				<div className="who-recog-title">Recognition</div>

				<div className="recognition-body">
					<div className="recog-body-left">
						<div className="recog-body-date-left">
							{RecognitionData[1].date}
						</div>
						<h4 className="recog-title">
							{RecognitionData[1].title}
						</h4>
						<div className="recog-text">
							<div className="click-here-who">
								{RecognitionData[1].text}
							</div>
							{RecognitionData[1].link && (
								<div className="learn-more-container">
									<NavLink
										className="learn-more-button"
										to={RecognitionData[1].link}
										target="_blank"
									>
										LEARN MORE
									</NavLink>
								</div>
							)}
						</div>
					</div>

					<div className="recog-body-mid">
						<div className="recog-body-date-mid">
							{RecognitionData[2].date}
						</div>
						<h4 className="recog-title">
							{RecognitionData[2].title}
						</h4>
						<div className="recog-text">
							<div className="click-here-who">
								{RecognitionData[2].text}
							</div>
							{RecognitionData[2].link && (
								<div className="learn-more-container">
									<NavLink
										className="learn-more-button"
										to={RecognitionData[2].link}
										target="_blank"
									>
										LEARN MORE
									</NavLink>
								</div>
							)}
						</div>
					</div>

					<div className="recog-body-right">
						<div className="recog-body-date-right">
							{RecognitionData[3].date}
						</div>
						<h4 className="recog-title">
							{RecognitionData[3].title}
						</h4>
						<div className="recog-text">
							<div className="click-here-who">
								{RecognitionData[3].text}
							</div>
							{RecognitionData[3].link && (
								<div className="learn-more-container">
									<NavLink
										className="learn-more-button"
										to={RecognitionData[3].link}
										target="_blank"
									>
										LEARN MORE
									</NavLink>
								</div>
							)}
						</div>
					</div>
				</div>
			</div> */}
		</div>
	);
}
