//who we are page

//imports
import "./WorkWithUsPage.css";
import { useState } from "react";
import { NavLink } from "react-router-dom";
import stories3 from "../../Assets/WorkWithUs/workwithus-3.png";
import stories4 from "../../Assets/WorkWithUs/workwithus-4.png";
import stories5 from "../../Assets/WorkWithUs/workwithus-5.png";
import stories6 from "../../Assets/WorkWithUs/workwithus-6.png";
import stories7 from "../../Assets/WorkWithUs/workwithus-7.png";
import stories8 from "../../Assets/WorkWithUs/workwithus-8.png";
import stories9 from "../../Assets/WorkWithUs/workwithus-9.png";
import stories10 from "../../Assets/WorkWithUs/workwithus-10.png";
import stories11 from "../../Assets/WorkWithUs/workwithus-11.png";
import ReactGA from "react-ga";

//main
export default function WorkWithUsPage() {
  // Track page view when the component mounts
  ReactGA.pageview(window.location.pathname + window.location.search);

  // states
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [open4, setOpen4] = useState(false);
  const [open5, setOpen5] = useState(false);
  const [open6, setOpen6] = useState(false);
  const [open7, setOpen7] = useState(false);
  const [open8, setOpen8] = useState(false);
  const [open9, setOpen9] = useState(false);
  const [open10, setOpen10] = useState(false);
  const [open11, setOpen11] = useState(false);
  const [open12, setOpen12] = useState(false);

  // clear state
  function clearAll() {
    if (open1) {
      setOpen1(false);
    }
    if (open2) {
      setOpen2(false);
    }
    if (open3) {
      setOpen3(false);
    }
    if (open4) {
      setOpen4(false);
    }
    if (open5) {
      setOpen5(false);
    }
    if (open6) {
      setOpen6(false);
    }
    if (open7) {
      setOpen7(false);
    }
    if (open8) {
      setOpen8(false);
    }
    if (open9) {
      setOpen9(false);
    }
    if (open10) {
      setOpen10(false);
    }
    if (open11) {
      setOpen11(false);
    }
    if (open12) {
      setOpen12(false);
    }
  }

  //return
  return (
    <div className="large-screen">
      <div className="home-header-outer">
        <div className="work-header">
          <div className="work-title">Join Our Team</div>
        </div>
      </div>

      <div className="work-body-section">
        {/* <NavLink
					to="https://www.youtube.com/watch?v=O3xIVkZDjK0"
					target="_blank"
					className="work-section-right"
				>
					<img
						className="work-section-image"
						src={stories2}
						alt="image of what we do"
					/>
				</NavLink> */}
        <div className="impact-message-image-navlink">
          <iframe
            className="impact-message-video"
            src="https://www.youtube.com/embed/O3xIVkZDjK0"
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>

        <div className="work-section-left">
          <h2 className="what-section-left-header">Volunteer in Ghana</h2>

          <div className="what-section-text">
            Get the opportunity to shadow physicians, immerse yourself in
            Ghanaian culture, and learn about health education and screening
            outreaches.
          </div>
          <NavLink to="/internships" className="white-button">
            <div className="button what-learn-more">Learn More</div>
          </NavLink>
        </div>
      </div>

      <div className="what-stories-container">
        <div className="work-stories">
          <img
            src={stories3}
            className="home-stories-image"
            alt="work with us stories"
          />
          <div className="work-stories-title">Educate About Health</div>
          <div className="work-stories-text">
            We believe that "knowledge is power" therefore we ensure that every
            project that we embark on has en educational component to it.
          </div>
        </div>

        <div className="work-stories">
          <img
            src={stories4}
            className="home-stories-image"
            alt="work with us stories"
          />
          <div className="work-stories-title">Screen to Save Lives</div>
          <div className="work-stories-text">
            These services include whole health assessment and medicine for
            life-threatening conditions such as hypertension and diabetes.
          </div>
        </div>

        <div className="work-stories">
          <img
            src={stories5}
            className="home-stories-image"
            alt="work with us stories"
          />
          <div className="work-stories-title">Experience Ghana</div>
          <div className="work-stories-text">
            Along with health services, we bring people to explore the vibrant
            culture of Ghana.
          </div>
        </div>
      </div>

      <div className="apply-button-container">
        <NavLink to="/volunteers" className="apply-button blue-hover">
          Apply to be a Volunteer
        </NavLink>

        <NavLink to="/internships" className="apply-button red mleft15-work">
          Apply for an Internship
        </NavLink>
      </div>

      <div className="volunteers-all">
        <h2 className="volunteer-title">Volunteer Testimonials</h2>

        <div className="volunteers">
          <div className="volunteer-container">
            <div className="volunteer-image-container">
              <img
                src={stories6}
                className="volunteer-image"
                alt="work with us stories"
              />
            </div>
            <div className="volunteer-name">Abigail</div>
            <div className="volunteer-text-1">
              Student at Cornell University
            </div>
            <div className="volunteer-text-2">
              "Shadowing doctors and participating in the health screenings gave
              me immeasurable hands on experience."
            </div>
          </div>

          <div className="volunteer-container">
            <div className="volunteer-image-container">
              <img
                src={stories7}
                className="volunteer-image"
                alt="work with us stories"
              />
            </div>
            <div className="volunteer-name">Chelsea</div>
            <div className="volunteer-text-1">
              Student at Cornell University
            </div>
            <div className="volunteer-text-2">
              "The trip to Ghana gave me the opportunity to be immersed in their
              culture, gain new medical knowledge, and volunteer to help
              low-income communities."
            </div>
          </div>

          <div className="volunteer-container">
            <div className="volunteer-image-container">
              <img
                src={stories8}
                className="volunteer-image"
                alt="work with us stories"
              />
            </div>
            <div className="volunteer-name">Daniel</div>
            <div className="volunteer-text-1">
              Student at Cornell University
            </div>

            <div className="volunteer-text-2">
              "My experience with the OKB Hope Foundation left me with pride,
              fond memories, and a marked change in perspective."
            </div>
          </div>
        </div>
      </div>

      <div className="apply-volunteer">
        <div className="apply-volunteer-container">
          <img
            src={stories9}
            className="apply-volunteer-image"
            alt="work with us stories"
          />

          <div className="apply-volunteer-text">
            Health Care Services in The Ayigya and Ejisu Communities
          </div>
        </div>

        <div className="apply-volunteer-container">
          <img
            src={stories10}
            className="apply-volunteer-image"
            alt="work with us stories"
          />

          <div className="apply-volunteer-text">
            Culture Immersion with Students From Cornell University
          </div>
        </div>

        <div className="apply-volunteer-container">
          <img
            src={stories11}
            className="apply-volunteer-image"
            alt="work with us stories"
          />

          <div className="apply-volunteer-text">
            Students at Komfo Anokye Teaching Hospital
          </div>
        </div>
      </div>

      <div className="apply-button-container gray-back">
        <NavLink to="/volunteers" className="apply-button blue-hover">
          Apply to be a Volunteer
        </NavLink>
      </div>

      <div className="volunteer-faqs">
        <h2 className="work-volunteer-title">Volunteer FAQs</h2>

        <a
          className="openingfaqs mbottom10 pointer opening-container"
          data-bs-toggle="collapse"
          href="#open1"
          aria-expanded="false"
          onClick={() => {
            clearAll();
            setOpen1(!open1);
          }}
        >
          <div>How much does the volunteer program cost?</div>
          {open1 ? (
            <div className="minus-icon">-</div>
          ) : (
            <div className="plus-icon">+</div>
          )}
        </a>

        {open1 && (
          <div
            className={`openingfaqs mbottom10 expanded2 ${
              open1 ? "open" : "closed"
            }`}
          >
            The cost of both the winter program and the summer program is $2,500
            (non-refundable) and $5000 (non-refundable) respectively. The cost
            includes food, housing accommodations, a tourist attraction, and
            in-country transportation (airfare is separate).
          </div>
        )}

        <div
          className="openingfaqs mbottom10 pointer opening-container"
          onClick={() => {
            clearAll();
            setOpen2(!open2);
          }}
        >
          <div>When is the volunteer program taking place?</div>
          {open2 ? (
            <div className="minus-icon">-</div>
          ) : (
            <div className="plus-icon">+</div>
          )}
        </div>

        {open2 && (
          <div
            className={`openingfaqs mbottom10 expanded2 ${
              open2 ? "open" : "closed"
            }`}
          >
            This program is offered every winter and summer break. It is
            recommended that students arrive on the day the program begins (or
            even a day earlier). If students would like to arrive two or more
            days earlier than the scheduled program start date, they will be
            responsible for their own expenses until the program starts.
          </div>
        )}

        <div
          className="openingfaqs mbottom10 pointer opening-container"
          onClick={() => {
            clearAll();
            setOpen3(!open3);
          }}
        >
          <div>Do I need a visa to travel to Ghana and how do I get one?</div>
          {open3 ? (
            <div className="minus-icon">-</div>
          ) : (
            <div className="plus-icon">+</div>
          )}
        </div>

        {open3 && (
          <div
            className={`openingfaqs mbottom10 expanded2 ${
              open3 ? "open" : "closed"
            }`}
          >
            Yes, you will need to get a visa (at your own expense). You can
            apply through the Ghana Embassy. The application requires a Letter
            of Support which OKB Hope Foundation will provide. ​Visit
            https://ghanaembassydc.org/ for more information on how to get a
            visa.
          </div>
        )}

        <div
          className="openingfaqs mbottom10 pointer opening-container"
          onClick={() => {
            clearAll();
            setOpen4(!open4);
          }}
        >
          <div>
            What airport should I fly into and how do I get from the airport to
            the Guest House?
          </div>
          {open4 ? (
            <div className="minus-icon">-</div>
          ) : (
            <div className="plus-icon">+</div>
          )}
        </div>

        {open4 && (
          <div
            className={`openingfaqs mbottom10 expanded2 ${
              open4 ? "open" : "closed"
            }`}
          >
            Students should fly into Kotoka International Airport. A personnel
            from OKB Hope Foundation will pick students up from the airport.
          </div>
        )}

        <div
          className="openingfaqs mbottom10 pointer opening-container"
          onClick={() => {
            clearAll();
            setOpen5(!open5);
          }}
        >
          <div>Can my parents come?</div>
          {open5 ? (
            <div className="minus-icon">-</div>
          ) : (
            <div className="plus-icon">+</div>
          )}
        </div>

        {open5 && (
          <div
            className={`openingfaqs mbottom10 expanded2 ${
              open5 ? "open" : "closed"
            }`}
          >
            No, this experience is for students only.
          </div>
        )}

        <div
          className="openingfaqs mbottom10 pointer opening-container"
          onClick={() => {
            clearAll();
            setOpen6(!open6);
          }}
        >
          <div>Where will I be staying during the program?</div>
          {open6 ? (
            <div className="minus-icon">-</div>
          ) : (
            <div className="plus-icon">+</div>
          )}
        </div>

        {open6 && (
          <div
            className={`openingfaqs mbottom10 expanded2 ${
              open6 ? "open" : "closed"
            }`}
          >
            Students will stay in Guest Houses (similar to a hotel) in both
            Kumasi and Accra. Students will have a single-room which comes with
            a bed and bathroom. Linens and towels will be provided and
            cleaned/changed daily.
          </div>
        )}

        <div
          className="openingfaqs mbottom10 pointer opening-container"
          onClick={() => {
            clearAll();
            setOpen7(!open7);
          }}
        >
          <div>What/where will I be eating during the program?</div>
          {open7 ? (
            <div className="minus-icon">-</div>
          ) : (
            <div className="plus-icon">+</div>
          )}
        </div>

        {open7 && (
          <div
            className={`openingfaqs mbottom10 expanded2 ${
              open7 ? "open" : "closed"
            }`}
          >
            We will have a chef in the guest house who will cook all meals for
            us. To ensure that all students’ dietary needs are met by the chef,
            students are required to indicate their dietary restrictions when
            applying to the program. Additionally, we will occasionally eat out
            at restaurants as a group. These meals are all covered in the
            program cost.
          </div>
        )}

        <div
          className="openingfaqs mbottom10 pointer opening-container"
          onClick={() => {
            clearAll();
            setOpen8(!open8);
          }}
        >
          <div>What should I pack?</div>
          {open8 ? (
            <div className="minus-icon">-</div>
          ) : (
            <div className="plus-icon">+</div>
          )}
        </div>

        {open8 && (
          <div
            className={`openingfaqs mbottom10 expanded2 ${
              open8 ? "open" : "closed"
            }`}
          >
            It will be pretty hot and humid, so students should pack summer
            clothes. However, students should be culturally sensitive when
            choosing the clothes that they pack (no short shorts or dresses).
            Students should also bring comfortable shoes, as we will be doing a
            lot of walking and standing. Packing a water bottle, bug repellent,
            swim suit, and a notebook is also recommended.
          </div>
        )}

        <div
          className="openingfaqs mbottom10 pointer opening-container"
          onClick={() => {
            clearAll();
            setOpen9(!open9);
          }}
        >
          <div>
            How much money should I bring? What is the currency exchange
            process?
          </div>
          {open9 ? (
            <div className="minus-icon">-</div>
          ) : (
            <div className="plus-icon">+</div>
          )}
        </div>

        {open9 && (
          <div
            className={`openingfaqs mbottom10 expanded2 ${
              open9 ? "open" : "closed"
            }`}
          >
            On average, students bring $300-500 for personal spending money.
            Students can exchange their currency in the airport, however OKB
            Hope Foundation can do this process for you at a much cheaper rate.
          </div>
        )}

        <div
          className="openingfaqs mbottom10 pointer opening-container"
          onClick={() => {
            clearAll();
            setOpen10(!open10);
          }}
        >
          <div>What precautions do I need to take in case of an emergency?</div>
          {open10 ? (
            <div className="minus-icon">-</div>
          ) : (
            <div className="plus-icon">+</div>
          )}
        </div>

        {open10 && (
          <div
            className={`openingfaqs mbottom10 expanded2 ${
              open10 ? "open" : "closed"
            }`}
          >
            We encourage all students to get travelers insurance in case of an
            emergency. Additionally, students are required to register with
            their school’s international travel registry.
          </div>
        )}

        <div
          className="openingfaqs mbottom10 pointer opening-container"
          onClick={() => {
            clearAll();
            setOpen11(!open11);
          }}
        >
          <div>What vaccinations do I need to get?</div>
          {open11 ? (
            <div className="minus-icon">-</div>
          ) : (
            <div className="plus-icon">+</div>
          )}
        </div>

        {open11 && (
          <div
            className={`openingfaqs mbottom10 expanded2 ${
              open11 ? "open" : "closed"
            }`}
          >
            All students are required to get the Yellow Fever and Malaria
            vaccines before coming to Ghana.
          </div>
        )}

        <div
          className="openingfaqs mbottom10 pointer opening-container"
          onClick={() => {
            clearAll();
            setOpen12(!open12);
          }}
        >
          <div>Am I just going to be shadowing doctors the whole time?</div>
          {open12 ? (
            <div className="minus-icon">-</div>
          ) : (
            <div className="plus-icon">+</div>
          )}
        </div>

        {open12 && (
          <div
            className={`openingfaqs mbottom10 expanded2 ${
              open12 ? "open" : "closed"
            }`}
          >
            No, our program allows students to not only shadow doctors, but also
            immerse themselves into the Ghanaian culture on a day-to-day basis.
            Review the itinerary to get an idea of a typical week of the
            program.
          </div>
        )}
      </div>
    </div>
  );
}
