// imports
import "./Donate.css";
import Flipcause from "./Flipcause";
import { NavLink } from "react-router-dom";
import ReactGA from "react-ga";

// main
export default function Donate() {
  // Track page view when the component mounts
  ReactGA.pageview(window.location.pathname + window.location.search);

  // return
  return (
    <div className="large-screen">
      <div className="home-header-outer">
        <div className="donate-header">
          <div className="donate-header-text">Make a difference</div>
        </div>
      </div>

      <div className="booking-body-section section-one mobile-bottom-padding">
        <div className="booking-section-left">
          <h2 className="booking-patients-header">HOPE HEALTH VANS</h2>
          <div className="booking-section-text">
            OKB reduces healthcare problems in rural and underserved communities
            by bringing medical care to the doorsteps of local communities in
            Ghana. Our mobile medical van is equipped with multiple
            point-of-care diagnostics and medical supplied and seasoned
            healthcare providers that visit underserved and remote areas. The
            van ensure that individuals in these communities have access to
            essential healthcare services.
          </div>
          <NavLink className="white-button" to="/donate-hope-health-vans">
            <div className="button what-learn-more">DONATE TO VANS</div>
          </NavLink>
        </div>

        <div className="booking-section-right">
          <h2 className="booking-patients-header">HOPE HEALTH INTIATIVE</h2>
          <div className="booking-section-text">
            We address the shortage of healthcare providers in rural and
            underserved communities by training community health volunteers to
            serve as health advocates in their communities. Volunteers are
            educated, trained, and equipped to provide health education and
            basic healthcare services to collect data on members that they
            serve. Community health volunteers offer sustainability within
            remote local communities.
          </div>
          <NavLink className="white-button" to="/donate-hope-health-initiative">
            <div className="button what-learn-more">DONATE TO INITIATIVE</div>
          </NavLink>
        </div>
      </div>

      <div className="booking-body-section section-one">
        <div className="booking-section-left">
          <h2 className="booking-patients-header">HOPE HEALTH HUB</h2>
          <div className="booking-section-text">
            The initiative builds virtual care centers and facilities in rural
            and underserved communities. The centers are equipped with basic
            health equipment and technology, staffed by our trained health
            advocates. This approach allows individuals in rural and underserved
            communities to consult with healthcare providers located in urban
            areas and cities. These centers provide healthcare to those without
            access to technology.
          </div>
          <NavLink className="white-button" to="/donate-hope-health-hub">
            <div className="button what-learn-more">DONATE TO HUB</div>
          </NavLink>
        </div>

        <div className="booking-section-right">
          <h2 className="booking-patients-header">WOHOHIAME WELLNESS</h2>
          <div className="booking-section-text">
            "Wohohiame" which means I care for you in the Twi Ghanaian language
            addresses mental health challenges in Ghana. The program provides
            individuals struggling with mental health challenges with
            information that helps them cope with mental health challenges.
            Content on this platform is available in multiple languages in audio
            formats. The initiative has provided mental education and resources
            to over 800 high school students.
          </div>
          <NavLink className="white-button" to="/donate-wohohiame-wellness">
            <div className="button what-learn-more">DONATE TO WOHOHIAME</div>
          </NavLink>
        </div>
      </div>

      <div className="donate-main-title">Donate to OKB Hope Foundation</div>

      <Flipcause />

      <NavLink to="/donate-form">
        <div className="blue-button" id="donate-other-button">
          Have something else to donate?
        </div>
      </NavLink>

      <div className="donate-title">
        <div className="donate-title-left">How Your Donation Helps</div>

        <div className="donate-title-right">
          <div className="donate-text">
            Brings medical supplies for health screenings.
          </div>

          <div className="donate-text">Empowers patients throughout Ghana.</div>

          <div className="donate-text">
            Initiates projects that better the lives of communities.
          </div>

          <div className="donate-text">
            Provides health education to the people of Ghana.
          </div>
        </div>
      </div>
    </div>
  );
}
