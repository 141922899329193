// imports
import { useState } from "react";
import { InlineWidget, PopupButton } from "react-calendly";
import "./Booking.css";
import { ServicesData } from "./ServicesData";

// main - OPEN calendly inline widget
export default function Calendly({ serviceName, buttonName, selectedOptions }) {
	// get service
	let service = ServicesData[serviceName];

	// return
	return (
		<>
			<InlineWidget
				iframeTitle="Calendly Scheduling Page"
				pageSettings={{
					backgroundColor: "ffffff",
					hideEventTypeDetails: true,
					hideGdprBanner: true,
					hideLandingPageDetails: true,
					primaryColor: "00a2ff",
					textColor: "4d5055",
				}}
				prefill={{
					customAnswers: {
						a2: selectedOptions.join(", "),
					},
				}}
				styles={{
					height: "1000px",
					width: "100%",
				}}
				url={service.calendly}
				utm={{
					utmCampaign: "Spring Sale 2019",
					utmContent: "Shoe and Shirts",
					utmMedium: "Ad",
					utmSource: "Facebook",
					utmTerm: "Spring",
				}}
			/>
		</>
	);
}

// main - POPUP WIDGET ONLY
// export default function Calendly({ serviceName, buttonName }) {
// 	// get serviceName
// 	let service = ServicesData[serviceName];

// 	// return
// 	return (
// 		<>
// 			<PopupButton
// 				className="booking-button button what-learn-more"
// 				iframeTitle="Calendly Scheduling Page"
// 				pageSettings={{
// 					backgroundColor: "ffffff",
// 					hideEventTypeDetails: false,
// 					hideGdprBanner: true,
// 					hideLandingPageDetails: false,
// 					primaryColor: "00a2ff",
// 					textColor: "4d5055",
// 				}}
// 				prefill={{
// 					customAnswers: {
// 						a1: "a1",
// 						a10: "a10",
// 						a2: "a2",
// 						a3: "a3",
// 						a4: "a4",
// 						a5: "a5",
// 						a6: "a6",
// 						a7: "a7",
// 						a8: "a8",
// 						a9: "a9",
// 					},
// 					date: new Date("2023-05-28T21:22:46.639Z"),
// 					email: "test@test.com",
// 					firstName: "Jon",
// 					guests: ["janedoe@example.com", "johndoe@example.com"],
// 					lastName: "Snow",
// 					name: "Jon Snow",
// 				}}
// 				rootElement={document.getElementById("root")}
// 				styles={{}}
// 				text={buttonName}
// 				url={service.calendly}
// 				utm={{
// 					utmCampaign: "Spring Sale 2019",
// 					utmContent: "Shoe and Shirts",
// 					utmMedium: "Ad",
// 					utmSource: "Facebook",
// 					utmTerm: "Spring",
// 				}}
// 			/>
// 		</>
// 	);
// }
