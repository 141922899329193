// imports
import mars1 from "../../../Assets/WhoWeAre/mars-10-2.png";
import chri2 from "../../../Assets/WhoWeAre/chri-11-2.png";
import pams3 from "../../../Assets/WhoWeAre/pams-15-2.png";
import will10 from "../../../Assets/Events/CaseCompetitions/williameffah.png";
import glor11 from "../../../Assets/Events/CaseCompetitions/glorianimo.png";
import paul12 from "../../../Assets/Events/CaseCompetitions/paulejoseph.png";
import leon13 from "../../../Assets/Events/CaseCompetitions/leonardmasala.png";
import ange14 from "../../../Assets/Events/CaseCompetitions/angelaadj.png";
import frid15 from "../../../Assets/Events/CaseCompetitions/fridahmubichi.png";
import jose16 from "../../../Assets/Events/CaseCompetitions/josephtasse.png";
import michraspuzzi from "../../../Assets/Events/CaseCompetitions/michaelrapuzzi.png";
import bernsyla from "../../../Assets/Events/CaseCompetitions/bernadettestyla.png";

/**
 * @description OurJudgesData is an object that contains the judges for the case competition.
*/
export const OurJudgesData = {
	judges: [
		{
			id: 1,
			name: "Marsha Sinanan-Vasishta",
			position: "CNO Mount Sinai Morningside",
			image: mars1,
			linkedin:
				"https://www.linkedin.com/in/marsha-sinanan-vasishta-msn-mba-rn-nea-bc-cpxp-56753315a/",
			description: [
				"​Marsha Sinanan-Vasishta, MBA, MSN, RN, NEA-BC, is the Interim Vice President, Nursing/Chief Nursing Officer for Mount Sinai Morningside. In this role, Ms. Sinanan-Vasishta is responsible for operations, fiscal management, and delivery of safe, timely, efficient, and equitable patient-centered care.",
				"An accomplished nurse leader, Ms. Sinanan-Vasishta is skilled at collaborating with, physicians, and clinical leaders within diverse settings and is an expert in driving employee engagement.",
				"Prior to working at Mount Sinai, Ms. Sinanan-Vasishta was a Director of Nursing at NewYork-Presbyterian/Allen Hospital where she provided oversight to the Emergency Department, Behavioral Health, the Dan and Jane Och Spine Hospital, and the Geriatric/Hospitalist Unit where she helped to lead and achieve several system wide quality, patient safety and patient experience goals.",
				"Ms. Sinanan-Vasishta holds a Master of Business Administration with a concentration in Health Care Management as well as a Masters of Science in Nursing from the University of Phoenix. She is board certified as a Nurse Executive, a Certified Patient Experience Professional since 2016, a member of the Transcultural Nursing Society and the International Honor Society for Nursing. Throughout her career, Ms. Sinanan-Vasishta has encouraged, supported and guided staff to excellent nursing practice, patient centered care through Magnet principles, continuous learning, and creative thinking.",
			],
		},
		{
			id: 2,
			name: "Dr. Chris Kwaku Oppong",
			position: "Specialist Emergency Physician",
			image: chri2,
			linkedin: "",
			description: [
				"A product of Kwame Nkrumah University of Science and Technology (KNUST) School of Medical Sciences and a Fellow of the Ghana College of Physicians and Surgeons.",
				"Dr. Chris is the head of the Emergency Medicine Directorate at the Komfo Anokye Teaching Hospital (KATH) . He is a Part time Lecturer at the College of Health Science (KNUST), and Instructor at the Ghana National Ambulance Training School which trains Emergency medical technicians.",
				"Dr. Chris serves as a faculty member of the Ghana College of Physicians and Surgeons Emergency Medicine Department. The faculty trains Emergency medicine specialists under the Ghana College of Physicians and Surgeons.",
				"​He is married and has 2 sons. He enjoys watching football, documentaries and doing research.",
			],
		},
		{
			id: 3,
			name: "Pam Silverstein",
			position: "Entrepreneur, Cornell University",
			image: pams3,
			linkedin: "",
			description: [
				"​Pam Silverstein graduated with an MBA from Cornell in the 70’s and have been an entrepreneur since. She has created many companies such as Ironics which was renamed Pathlight and was later sold to public company.",
				"​Pam have mentored both startups and established companies since 1976 with expertise in administration, finance, operations, and manufacturing. Pam is currently a Entrepreneur in Residence at Life Changing Lab at Cornell University.",
			],
		},
		{
			id: 4,
			name: "Dr. William Effah",
			position: "Adjunct Faculty, Washington University",
			image: will10,
			linkedin: "https://www.linkedin.com/in/weffah/",
			description: [
				"William (Bill) Effah is a public health physician-manager, academic, and implementation science researcher with 10+ years of experience spanning the healthcare ecosystem globally and in the US. Bill is passionate about implementation science, leveraging and deploying low-cost, high-yield innovative health technology tools to manage infectious and chronic diseases in low or under-resourced settings. He holds an MD, and an MPH with interests in Global Health and Epidemiology from the Brown School, Washington University in St Louis. He is a McDonnell scholar, a Kahn Family Fellow, and an inductee of the Delta Omega National Honorary Society in Public Health. In his time at the Brown School, he developed a suite of innovative educational tools for the City of St. Louis Department of Health and led a team of city experts to revise the city’s current Food Code.",
				"He has worked with the CDC in Malawi, in the Division of Global HIV AND TB. Helping to develop and maintain surveillance training programs and dashboards to track COVID-19 and improve HIV recency efforts in the country. Bill is the Senior Manager for International Research at the Institute of Informatics, Data Science and Biostatistics at Washington University School of Medicine. He helps run a coordinating center for a large NIH-funded research alliance looking to integrate Hypertension care into existing HIV care models in 6 African countries. He also teaches Epidemiology and Epidemiology methods courses at the Brown School. He loves music and plays the bass guitar",
			],
		},
		{
			id: 5,
			name: "Dr. Gloria Nimo",
			position: "Founder, Amazing Grace Children's Foundation",
			image: glor11,
			linkedin:
				"https://www.linkedin.com/in/dr-gloria-nimo-dnp-aprn-fnp-bc-b3729b100/",
			description: [
				"Dr. Gloria Nimo, DNP,, BSN, MSN,  ANP, FNP-BC, is a Nurse Practitioner at The New Jewish Home, New York City, (affiliated with The Mount Sinai Hospital) and until April 2016 she was a Nurse Practitioner in the Department of Emergency Medicine at The Mount Sinai Hospital, New York City. In addition, Dr. Gloria Nimo has nine years of experience in general pediatrics. She graduated cum laude from the College of New Rochelle, earned a graduate degree in nursing from Fairleigh Dickinson University, and obtained a postgraduate certificate in geriatric care from New York University and most recently, a  Doctor of Nursing degree from Dominican College of Blauvelt. Dr. Gloria Nimo also serves as an adjunct professor at Fairleigh Dickinson University of Nursing in New York.",
				"She was one of the first Nurses Practitioners  to work on a grant project funded by the U.S. Centers for Medicare and Medicaid Services (CMS) and was instrumental in spearheading many CMS programs. One exceptional effort was her development of templates for nurse practitioner documentation of a geriatric-specific assessment scale, GEDI WISE (Geriatric Emergency Department Innovations in Care Through Workforce, Informatics, and Structural Enhancements), which was incorporated in the Electronic Health Record (EPIC) for use in the first Geriatric Emergency Department in New York City.",
				"Dr. Gloria Nimo is a member of the American Association of Nurse Practitioners and served on the Nurse Practitioner Committee at Mount Sinai Hospital. She serves as Principal Investigator on the New York Academy of Medicine’s “Meaningful Uses of EHR and Clinical Work Flow in the Emergency Department” project.",
				"She is the founder and CEO of Amazing Grace Children’s Foundation a 501(c)(3) non-profit organization, incorporated in the State of New Jersey and based in Ghana, Dr. Nimo has led Medical Missions and volunteered her services in the Neonatal Intensive Care Unit (NICU) at Accra’s 2,000-bed Korle-bu Teaching Hospital, the third largest hospital in Africa. Her new organization seeks to provide health care to every child regardless of their ability to pay and to vastly improve the health of the mothers and children of Sub-Saharan Africa.",
			],
		},
		{
			id: 6,
			name: "Dr. Paule Joseph",
			position: "Science Researcher",
			image: paul12,
			linkedin: "https://www.linkedin.com/in/paulevjoseph/",
			description: [
				"Dr. Paule V. Joseph is a Venezuelan-American  2019 Lasker Scholar and National Institutes of Health (NIH) and Distinguished Scholar. She is Chief of the Section of Sensory Science and Metabolism (SenSMet) in the Division of Intramural Clinical and Biological Research (DICBR) at the National Institute on Alcohol Abuse and Alcoholism (NIAAA) with a dual appointment at the National Institute of Nursing Research (NINR). This role involves leading a team of scientists and researchers dedicated to understanding how the sensory system (smell and taste) connects with metabolism and influences health outcomes.  Before her tenure track position, she was an Assistant Clinical Investigator at NINR.  Dr. Joseph received an AAS in Nursing at Hostos Community College, a BSN from the College of New Rochelle, a Master of Science with a specialty as a Family Nurse Practitioner from Pace University, and an Executive master of business administration from Quantic School of Business. She completed her PhD from the University of Pennsylvania and conducted her PhD. work at the Monell Chemical Senses Center. She then completed a Clinical and Translational Postdoctoral Fellowship at the NINR supported by the Office of Workforce Diversity. Her program aims to understand neurological and molecular mechanisms underlying chemosensation (taste, smell & chemesthesis), motivational pathways of ingestive behaviors, and how they might differ with different diets,  individuals with obesity, and alcohol and substance use disorders.",
			],
		},
		{
			id: 7,
			name: "Dr. Leonard Masala",
			position: "Doctor, Hubert Kairuki Memorial University",
			image: leon13,
			linkedin: "",
			description: [
				"Dr. Leonard Malasa is a Medical Doctor and an Educator currently specializing in Paediatrics and Child Health. He is working as an Assistant Lecturer in the Department of Paediatrics and Child Health at Hubert Kairuki Memorial University in Tanzania. His work has included provision of healthcare, teaching medical students, community medical outreach services and research. He has several years of experience in health industry working across the levels of health facilities in Tanzania ranging from primary healthcare to national referral levels. Dr. Malasa founded Tanzania Better Health (www.tbh.or.tz), a non-profit organization based in Tanzania which aims to improve the health of Women, Children and Adolescents. Malasa’s goal is to improve the health of the community especially in Paediatrics population.",
			],
		},
		{
			id: 8,
			name: "Dr. Angela Appiah Adejetey",
			shortName: "Dr. Angela A. Adejetey",
			position: "Healthcare Consultant",
			image: ange14,
			linkedin: "",
			description: [
				"Dr. Angela A. Appiah, Ph.D., DNP, MPH, RN, COA, FAACM, is a Fulbright Specialist and executive administrator for healthcare systems, and she has 25 years of healthcare experience. Her expertise is in health systems and services management consulting, program development, accreditation,  undergraduate and graduate education, executive administrator for the oncology service line acute and ambulatory, heart failure services, disease prevention/detection, health promotion, navigation, palliative care, and supportive programs such as navigation. As a clinical operations and research leader, she focuses on program development, sustainment, implementing science/translational research, and global population health.",
				"Dr. Appiah is the founder and president of the Hudson Valley New York Black Nurses Association, Inc (Chartered chapter of NBNA.org).",
				"Angela is presently employed at Pace University since 2018, holding the title of Adjunct associate professor at the College of Health Professions-Health Science Program & Lienhard School of Nursing. Angela is also a consultant at St. Jude Children's Research Hospital (St. Jude Global), founder and CEO of Global Population Health Consulting, LLC. She has worked with diverse countries' ministries of health and programs globally to build capacity and develop and sustain health systems. ",
				"Dr. Appiah's past experiences include executive administrator, AVP, and director positions. She is a fellow of numerous organizations: Fellow of the American Academy of Case Management (FAACM), American Organization for Nursing Leadership (AONL), previously known as the American Organization for Nurse Executives (AONE) & AONL Foundation Nurse Director Fellow; AONE Aspiring Nurse Leaders fellow, Oncology Nursing Society (ONS) Leadership Development Institute.",
				"She has developed and participated in numerous mentoring and coaching programs for diverse organizations. She participates in multiple professional organizations and recently co-authored a chapter on Mentoring & Coaching in the book 21st Century Nursing Leadership, edited by Dr. Mary Gullatte. In addition, she has presented numerous times at conferences locally, nationally, and globally.",
				"Dr. Appiah seeks to reduce the burden of preventable disease and people development among people among populations of African descent in the United States and Africa through evidence-based quality improvement, community-engaged research, and implementation science.",
				"She serves on numerous boards globally. Examples include the Oncology Nursing Society (ONS) Leadership Development Committee, Nurses on Boards Coalition State Contacts Co-Chair. She also serves on diverse committees as the co-chair/chair. Angela has received multiple awards and recognitions and recently won awards such as an honoree of the NYONL nurse executive rising star award for 2022, AONL 2019 Young Professional Voices award for recognition and exhibition of exemplary leadership within her organization, community, and the nursing profession. Dr. Appiah was the 2019 National Black Nurses Association Administrative Nurse of the Year and the 40 under 40 awards. She also received the 2019 Oncology Nursing Society Foundation and the National Black Nurses Association doctoral scholarships.",
				"Angela enjoys and feels her purpose is to transform global health systems, especially those in low-resource areas globally, contributing to healthcare through improving disease prevention, health promotion, mentoring, coaching, and sponsoring potential leaders.",
			],
		},
		{
			id: 9,
			name: "Dr. Fridah Mubichi-Kut",
			position: "Professor, Cornell University",
			image: frid15,
			linkedin: "https://www.linkedin.com/in/fridah-mubichi/",
			description: [
				"Dr. Fridah Mubichi-Kut is a Professor of Practice in Applied Economics and Policy and Executive Director of the Student Multidisciplinary Applied Research Teams (SMART) Program at the Charles H. Dyson School of Applied Economics and Management and the SC Johnson College of Business at Cornell University. Fridah is a passionate social scientist and educator focused on promoting socioeconomic development in Sub-Saharan Africa and other emerging markets. Perplexed by the paradox of the hungry farmer, her research is focused on understanding the role development policies and social networks play in promoting access to innovations that promote socioeconomic growth and gender equity among rural populations. Her teaching is centered on the promotion of community-led development as well as the development of strategies that promote increased entrepreneurial opportunities for youth, women, and other resource-challenged groups.",
				"Trained first as an international business administrator and later social scientist, Fridah has taught and worked with various organizations in the USA, Kenya, Mozambique, and Namibia. Before joining Cornell, she was the Director of Measurement and Evaluation at a national (United States Department of Agriculture- USDA) agricultural commodity research program where she led the development of the organization’s first comprehensive monitoring evaluation and learning (MEL) system. Earlier, she worked as a social scientist within two USAID- Feed the Future innovation projects that were focused on the promotion of food security, gender empowerment, and economic growth. Fridah holds various degrees including a Ph.D. in Rural Sociology from the University of Missouri, MA. Sociology from Northeastern University, and MBA. International Marketing from Oklahoma City University.",
			],
		},
		{
			id: 10,
			name: "Joseph M. Tasse, FACHE",
			position: "Healthcare Consultant",
			image: jose16,
			linkedin: "https://www.linkedin.com/in/joseph-m-tasse-fache-52a21bb/",
			description: [
				"Joseph M. Tasse, FACHE specializes in hospital and nonprofit financial recovery and CEO assignments.  Most recently during the height of the pandemic, he served as the interim CEO of Southwest Solutions in Detroit, Michigan.  This is an integrated community services organization that provides housing, homelessness support, loans to entrepreneurs, Veterans’ services, workforce development, job training and mental health counseling. He led the organization to the successful attainment of a CCBHC Award by SAMHSA and an 18-month recovery from a significant operating loss to profitability along with restructuring the governance of its four 501.c.3 corporations.  Previous to that, he served in interim CEO roles for hospitals in Ohio and Colorado. He is also an executive consultant with Custom Learning Systems, Calgary, Canada in assisting healthcare care providers with improving patient and client satisfaction, employee engagement, HCAHPS scores, and LEAN process improvement.  He is currently serving as an Executive-in-Residence for the Executive Masters in Health Administration Program at Cornell University and had served in a two-year assignment as an Executive-in-Residence for the Sloan Program in Health Administration at Cornell.  He served as President of the Ambulatory Network for the Ascension St. John Providence Health System in southeast Michigan.  His previous roles included president of 535–bed Ascension St. John Macomb-Oakland Hospital that was formed with the merger of two hospitals in Warren and Madison Heights, Michigan – a merger that he led as the hospital president.  Prior to that, he served as Chief Administrative Officer of Beaumont Hospital, Dearborn, a 615-bed community teaching hospital located in Dearborn, Michigan, and prior to that, he was Sr. Associate Administrator of Clinical and Professional Services at the 615-bed University of Cincinnati Hospital.",
				"Mr. Tasse received his bachelor’s degree in Finance from John Carroll University and his Master’s Degree in Business Administration from The Johnson School at Cornell University where he simultaneously completed the requirements for a certificate in Hospital and Health Services Administration from the Sloan Program in Health Administration. He completed a management fellowship program at New York University on a grant from the Pew Charitable Trusts in conjunction with the National Association of Public Hospitals.  He is a Fellow in the American College of Healthcare Executives (ACHE), an international professional society of over 40,000 healthcare executives and is a past ACHE Regent for the state of Michigan, an elected position in which he served over one thousand ACHE members in Michigan and northern Ohio on the national Council of Regents.  His current involvement is in working with students and emerging healthcare leaders.",
				"He has been a leader in acute, emergency, ambulatory care and nonprofit operations for over 35 years.  He is past president of the Midwest Chapter of the American College of Healthcare Executives (MCACHE), the southeast Michigan and Northwest Ohio chapter of ACHE.  He assists non-profit community organizations by serving on their Boards.",
				"",
			],
		},
		{
			id: 11,
			name: "Michael Rapuzzi",
			position: "Entrepeneur, Software Engineer",
			image: michraspuzzi,
			linkedin: "",
			description: [
				`Michael Rapuzzi is a lifelong explorer, born alongside a twin sister in a New England suburb. His parents, both software engineers, ignited his fascination with technology and creativity. Michael embarked on a journey through architecture at Cornell but found his true calling as a generalist, driven by a passion for diverse projects.`,
				`At Harvard, Michael immersed himself in a wide range of fields, using "food systems" as a unifying lens for exploration. He played a key role in curriculum development and interdiscplinary problem-solving. Joining TKS, he initiated and expanded programs both in Boston and globally, burturing young talent.`,
				`Michael's journey is characterized by constant exploration and project-based pursuits, shaping his perspectiver and fueling his quest to uncover the possibilities of the world.`,
			],
		},
		{
			id: 12,
			name: "Dr. Bernadette Sylla",
			position: "Pediatric Hospitalist, Goryeb Chilren's Hospital",
			image: bernsyla,
			linkedin: "https://www.linkedin.com/in/bernadette-sylla-md-832711112/",
			description: [
				`Dr. Bernadette Sylla, originally from Guinea, West Africa, embarked on an extraoridinary journey that showcases the power of passion, perseverance, and purpose. After earning her medical degree from St. George's University, she completed her residency and Chief residency at Goryeb Children's Hoispital. During this time, her deep interest in pediatric global health led her to complete the global health tract, demonstrating her dedication to making a difference on a global scale.`,
				`Currently serving as a Pediatric Hospitalist at Goryeb Children's Hospital, Dr. Bernadette Sylla not only providesa acute care to young patients but also spearheads the non-profit organization, Thee Bee Project. This organization aims to assist disadvantaged women and children in Guinea, emphasizing her commitment to improving the lives of those in need. Driven by a passion for child education and welfare, she has become a vocal advocate for these causes. She has also lead and participated in many non-profit organizations aimed at uplifting the Guinean Community in the United States. With a career goal of becoming a global health practitioner, Dr. Bernadette Sylla is poised to make an even greater impact on the world, leaving a lasting legacy of compassion and dedication.`,
			],
		},
	],
};
