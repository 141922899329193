// imports
import healthOutreachImage from "../../../Assets/Events/Other/what-1.png";

const commonData = {
	title: "Summer Internship Program",
	location: "Ghana",
	applyLink: "/internships/1",
	componentLink: "summerInternshipEvent",
  videoLink: "https://www.youtube.com/embed/O3xIVkZDjK0?si=2OydPv71_8DKO6B-",
	eventTime: "10:30am",
	description: [
		"Explore Ghana's beauty while making a difference through health outreaches in rural and underserved communities.",
		"Participate in medical outreaches in various rural and underserved communities.",
		"Develop educational materials for the communities and the individuals we serve.",
		"Participate in our mental wellness initiatives across various high schools.",
		"Shadow healthcare providers to learn how healthcare is delivered in hospitals.",
	],
};

//main
/**
 * eventDate: "MM/DD/YYYY"
 * eventTime: "HH:MM" + ("am" OR "pm")
 */
export const SummerInternshipData = [
	{
		...commonData,
		id: "si1",
		title: "Summer Internship Program",
		details: "",
		dates: "",
		eventDate: "06/18/2024",
		eventTime: "10:30am",
    visible: false,
	},
	{
    ...commonData,
    id: "si2",
		title: "Summer Internship Program",
		details: "",
		location: "Ghana",
		dates: "",
		eventDate: "08/01/2024",
    visible: false,
	},
];
